import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faMicrophone, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Hotkeys from 'react-hot-keys'
import { useTranslation } from 'react-i18next'

const Recorder = ({ start, stop }) => {
	const { t } = useTranslation()
	const startIconInstance = <FontAwesomeIcon icon={faMicrophone} />
	const stopIconInstance = <FontAwesomeIcon icon={faEllipsis} beat />
	const loadingIconInstance = <FontAwesomeIcon icon={faSpinner} className='fa-spin' />

	const [recordFunction, setRecordFunction] = useState(true)
	const [recordFunctionIcon, setRecordFunctionIcon] = useState(startIconInstance)
	const [variant, setVariant] = useState('primary')
	const [disabled, setDisabled] = useState(false)
	const [subText,setSubText] = useState(t('exercise.record.start_subtitle'))

	/**
     * Handles whether the recording is on or not, and record button appearance
     */
	const handleRecord = async () => {
		if (recordFunction) {
			await startRecording()
		} else {
			await stopRecording()
		}
	}
	const startRecording = async () => {
		await start()
		setRecordFunction(false)
		setRecordFunctionIcon(stopIconInstance)
		setVariant('danger')
		setSubText(t('exercise.record.stop_subtitle'))
	}

	const stopRecording = async () => {
		setVariant('secondary')
		setDisabled(true)
		setRecordFunction(true)
		setRecordFunctionIcon(loadingIconInstance)
		await stop()
	}

	const onKeyUp = () => {
		stopRecording()
	}
	const onKeyDown = () => {
		startRecording()
	}

	return (
		<>
			<Hotkeys keyName="space" onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<Button
					id="start-recording" className='exercise--record-button rounded-circle'
					variant={variant} disabled={disabled} onClick={handleRecord}
				>
					{recordFunctionIcon}
				</Button>
			</Hotkeys>
			<h5>{subText}</h5>
		</>
	)
}

export default Recorder
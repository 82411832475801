import { Modal, Button, Form, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useSelector, useDispatch } from 'react-redux'
import { setShowMessage, setForgotPassword } from 'reducers/userSlice'

import { CognitoUser } from 'amazon-cognito-identity-js'

const ResetPasswordModal = ({ username, handleClose, userPool }) => {
	const message = useSelector(state => state.user.status.message)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const closeModal = () => {
		handleClose()
	}

	const getVerificationCode = async event => {
		event.preventDefault()
		try {
			const user = new CognitoUser({
				Username: username,
				Pool: userPool,
			})
			await new Promise((resolve, reject) => {
				user.forgotPassword({
					onSuccess: (data) => {
						resolve(data)
					},
					onFailure: (err) => {
						reject(err)
					},
				})
			})
			dispatch(setShowMessage({
				showMessage: true,
				message: `${t('reset_password.status_messages.success_send_reset_code')}`,
				variant: 'success'
			}))
		} catch (err) {
			console.error(err)
			dispatch(setShowMessage({
				showMessage: true,
				message: `${t('reset_password.status_messages.error_send_reset_code', { errorMessage: err.message })}`,
				variant: 'danger'
			}))
		}
	}

	const resetPassword = async event => {
		event.preventDefault()
		try {
			const user = new CognitoUser({
				Username: username,
				Pool: userPool,
			})
			const verificationCode = event.target.verificationCode.value
			const newPassword = event.target.newPassword.value
			await new Promise((resolve, reject) => {
				user.confirmPassword(verificationCode, newPassword, {
					onSuccess() {
						resolve(true)
					},
					onFailure(err) {
						reject(err)
					},
				})
			})
			dispatch(setShowMessage({
				showMessage: true,
				message: `${t('reset_password.status_messages.success_pw_reset')}`,
				variant: 'success'
			}))
			setTimeout(() => {
				handleClose()
			}, 1000)
			dispatch(setForgotPassword(false))
		} catch (err) {
			console.error(err)
			dispatch(setShowMessage({
				showMessage: true,
				message:`${t('reset_password.status_messages.error_pw_reset', { errorMessage: err.message })}`,
				variant: 'danger'
			}))
		}
	}

	return (
		<Modal show={true} onHide={closeModal} data-cy='reset-passoword-modal'>
			<Modal.Header>
				<Modal.Title>{t('reset_password.modal_title', {username})}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{message.showMessage ? <Alert variant={message.variant}>{message.message}</Alert> : <></> }
				<p>{t('reset_password.modal_info')}</p>
				<Form name='resetPw' onSubmit={resetPassword}>
					<Form.Group className="mb-3" controlId='verificationCode'>
						<Form.Text>{t('reset_password.verification')}</Form.Text>
						<Form.Control
							autoComplete='off'
							type='text'
							name='verificationCode'
							placeholder={t('reset_password.code_placeholder')}
							required />
					</Form.Group>
					<Form.Group className="mb-3" controlId='newPassword'>
						<Form.Text>{t('reset_password.new_password')}</Form.Text>
						<Form.Control
							autoComplete='off'
							type='password'
							name='newPassword'
							placeholder={t('reset_password.password_placeholder')}
							required />
					</Form.Group>
					<Button data-cy='reset_pw_btn' name='resetPassword' type='submit'>
						{t('reset_password.reset_button')}
					</Button>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button data-cy='send_reset_link_btn' variant='outline-primary' onClick={getVerificationCode}>
					{t('reset_password.send_code_button')}
				</Button>
				<Button data-cy='cancel_reset_pw_btn' variant='outline-dark' onClick={closeModal}>
					{t('reset_password.cancel')}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ResetPasswordModal
import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col } from 'react-bootstrap'
import ExerciseImage from 'components/ExerciseImage'
import { EXERCISE_PROGRESS_STATUS_NEXT } from 'utils/config'
import { getQuestionLengthClass } from 'utils/helpers'

interface Props {
    questionText: string,
}

const ExerciseTextPrompt = ({ questionText }: Props) => {
	const hasImage = useAppSelector(state => state.currentExercise.exercise.settings.hasImage)
	const status = useAppSelector(state => state.currentExercise.stats.status)

	return (
		<Row className={'justify-content-center flex-grow-1'}>
			<Col
				xs={12} sm={status === EXERCISE_PROGRESS_STATUS_NEXT || !hasImage ? 12 : 5}
				className={`align-self-center exercise--div-question
                        exercise--question-text-${getQuestionLengthClass(questionText)}`}
				data-cy='exercise_read_text_prompt'
			>
				{questionText}
			</Col>
			{hasImage
				? <Col className="align-self-center" xs={12} sm={status === EXERCISE_PROGRESS_STATUS_NEXT ? 12 : 7}>
					<ExerciseImage />
				</Col>
				:<></>
			}
		</Row>
	)
}

export default ExerciseTextPrompt
import { Modal, Button, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useSelector, useDispatch } from 'react-redux'
import { setShowMessage, setUserConfirmed } from 'reducers/userSlice'
import VerifyAccount from 'components/VerifyAccount'

const VerifyAccountModal = ({ username, handleClose, userPool }) => {
	const message = useSelector(state => state.user.status.message)

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const closeModal = () => {
		handleClose()
	}

	const handleVerifySuccess = msg => {
		dispatch(setShowMessage({
			showMessage: true,
			message: msg,
			variant: 'success'
		}))
		dispatch(setUserConfirmed(true))
		setTimeout(() => {
			handleClose()
		}, 1000)
	}

	const handleVerifyError = msg => {
		dispatch(setShowMessage({
			showMessage: true,
			message: msg,
			variant: 'danger'
		}))
	}

	return (
		<Modal show={true} onHide={closeModal}>
			<Modal.Header>
				<Modal.Title>{t('verify_account.title', { username: username })}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{message.showMessage ? <Alert variant={message.variant}>{message.message}</Alert> : <></> }
				<VerifyAccount
					username={username}
					setSuccessMessage={handleVerifySuccess}
					setErrorMessage={handleVerifyError}
					userPool={userPool}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='outline-dark' onClick={closeModal}>
					{t('verify_account.cancel')}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default VerifyAccountModal
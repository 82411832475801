import { Button } from 'react-bootstrap'
import Hotkeys from 'react-hot-keys'
import {useState, useEffect } from 'react'
import React from 'react'

interface Props {
	message: string;
	handleConfirmation: (type: boolean) => void;
  }

const Confirmation = ({ message, handleConfirmation }:Props) => {
	const [title, setTitle] = useState<string>('')
	const [textMessage, setTextMessage] = useState<string>('')

	// Maybe not the cleanest logic , but could suffice for the first MVP version 
	useEffect(() => {
		if (message === 'Could not find results'){
			setTitle('Jag hörde inte, försök igen.')
		}
		else {
			setTitle('Menade du:')
			setTextMessage(message)
		}

	}, [])

	/**
   * Handles confirmation of a sent answer
   * If AI received audio answer: 
      * Click yes / press space to send answer 
      * Click no / press any key (except space) to retry
   * If AI did not hear: 
      * Force retry (Click or any key)
   */

	const confirmationBox = () => {

		if (message === 'Could not find results'){
			return (
				<div className='d-flex flex-column align-items-center confirmation mt-3'>
					<div className='chatbot--card-text'>{title}</div>
					<strong className='m-2'>"{textMessage}"</strong>

					<div className='d-flex justify-content-center align-items-center w-100'>
						<Hotkeys
							keyName="*"
							onKeyDown={(key,event) =>
							{
								if (event.key !== 'Tab' && event.key !== 'Enter') {
									handleConfirmation(false)
								}
							}
							}
						>
							<Button
								variant='danger'
								size='lg'
								className='confirmation-button m-2'
								onClick={() => handleConfirmation(false)}
							>
                      Försök igen
							</Button>
						</Hotkeys>
					</div>
				</div>
			)
		}
		else {
			return (
				<div className='d-flex flex-column align-items-center confirmation mt-3'>
					<div className='chatbot--card-text'>{title}</div>
					<strong className='m-2'>"{textMessage}"</strong>

					<div className='d-flex justify-content-center align-items-center w-100'>
						<Hotkeys
							keyName="space"
							onKeyDown={() => handleConfirmation(true)}
						>
							<Button
								variant='success'
								size='lg'
								className='confirmation-button m-2'
								onClick={() => handleConfirmation(true)}
							>
                      Ja, skicka svar
							</Button>
						</Hotkeys>
						<Hotkeys
							keyName="*"
							onKeyDown={(key,event) =>
							{
								if (event.key !== ' ' && event.key !== 'Tab' && event.key !== 'Enter') {
									handleConfirmation(false)
								}
							}
							}
						>
							<Button
								variant='danger'
								size='lg'
								className='confirmation-button m-2'
								onClick={() => handleConfirmation(false)}
							>
                      Försök igen
							</Button>
						</Hotkeys>
					</div>
				</div>
			)
		}
	}

	return (
		confirmationBox()
	)
}

export default Confirmation



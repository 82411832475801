import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'

interface Props {
    setShowChangeResetModal(show: boolean): void
}

const ChangeResetDateModal = ({setShowChangeResetModal }: Props) => {

	const { t } = useTranslation()

	const handleProceed = () => {
		setShowChangeResetModal(false)
		// TODO: add functionality to set the resetDate in the modal!
	}

	return (
		<>
			<Modal data-cy='reminder_check_resetdate_modal' show={true}>
				<Modal.Header>
					<Modal.Title>{t('edit_patient.exercises.change_resetdate_modal.title')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>{t('edit_patient.exercises.change_resetdate_modal.body')}</p>
				</Modal.Body>

				<Modal.Footer>
					<Button data-cy='continue_resetdate_modal' variant='primary'
						onClick={handleProceed}
					>
						{t('edit_patient.exercises.change_resetdate_modal.button_continue')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ChangeResetDateModal
import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { AnswerColor } from 'types/QuestionTypes'
import { Answer } from 'types/AnswerTypes'
import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import { useTranslation } from 'react-i18next'
import { getAlternativeLengthClass } from 'utils/helpers'
import { EXERCISE_TYPE_LISTEN } from 'utils/config'


interface Props {
    alternatives: Answer[]
    answerColor: AnswerColor
    altOnClick: (answerObj: Answer, index: number) => void
	exerciseAnswerFormat: string
}

const AnswerFormatText = ({alternatives, answerColor, altOnClick, exerciseAnswerFormat }: Props) => {
	const { t } = useTranslation()

	return (
		<>
			{alternatives.map((answerObj: Answer, i: number) => {
				return (
					<Col className='text-center' xs={12} sm={6} md={6} lg={5} xl={3}
						key={`${answerObj.answer.text}_${i}`}
					>
						{/* Should buttons with text not show when listening exercise? */}
						<Row className='my-2 justify-content-center align-items-center'>
							<Col className={`flex-grow-1 ${exerciseAnswerFormat === EXERCISE_TYPE_LISTEN ? 'text-start' : 'text-center'}`}>
								<Button
									className={`comprehension--alt-base comprehension--alt-btn
										comprehension--alt-${answerColor[i]} text-break word-break text-start
										comprehension--alt-${getAlternativeLengthClass(answerObj.answer.text)}`}
									onClick={() => altOnClick(answerObj, i)}
									variant='outline-dark'
								>
									{exerciseAnswerFormat === EXERCISE_TYPE_LISTEN
										? <AudioPlayer
											buffer={answerObj.audio}
											large={false}
											text={false}
										/>
										:<></>
									}
									<span className='mx-2'>{answerObj.answer.text}</span>
								</Button>
							</Col>
						</Row>
					</Col>
				)
			})}
		</>
	)
}

export default AnswerFormatText
import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'
import Confidence from 'components/ExerciseFeedback/Confidence'
import PlayAnswer from 'components/PlayAnswer'
import WrittenAnswer from 'components/WrittenAnswer'
import FeedbackIncorrect from 'components/ExerciseFeedback/FeedbackIncorrect'
import FeedbackCorrect from 'components/ExerciseFeedback/FeedbackCorrect'
import { EXERCISE_ANSWER_STATUS_CORRECT, EXERCISE_TYPE_SPEAK } from 'utils/config'

const ExerciseFeedback = () => {
	const { t } = useTranslation()
	const format = useAppSelector(state => state.currentExercise.exercise.answerFormat)
	const template = useAppSelector(state => state.currentExercise.exercise.template)
	const answerStatus = useAppSelector(state => state.currentExercise.stats.answerStatus)
	const confidence = useAppSelector(state => state.currentExercise.stats.confidence)

	return (
		<>
			<p className='exercise--feedback-title-text fs-6 my-0'>
				{t('comprehension_exercise.answer_feedback.your_selection')}
			</p>
			<Row className='align-items-center'>
				<Col xs={8} sm={9}>
					{format === EXERCISE_TYPE_SPEAK ? <PlayAnswer /> : <WrittenAnswer />}
				</Col>
				<Col xs={4} sm={3}>
					{answerStatus === EXERCISE_ANSWER_STATUS_CORRECT
						? <Confidence confidence={confidence} />
						: <FeedbackIncorrect />
					}
				</Col>
			</Row>
		</>
	)
}

export default ExerciseFeedback
import React, { useState, useEffect, useCallback } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'stateHandling/hooks'
import { useDispatch } from 'react-redux'
import { Dropdown, Row, Col } from 'react-bootstrap'

import userService from 'services/users'
import { setPatients } from 'reducers/userSlice'
import { setCurrentPatient } from 'reducers/currentPatientSlice'
import { getPatientUserIconColor } from 'utils/helpers'

import Loading from 'components/Loading'
import { Dispatch } from 'types/Types'
import { AssignedPatientUser } from 'types/UserTypes'

const PatientPageSelect = () => {
	const user = useAppSelector(state => state.user)
	const patients = useAppSelector(state => state.user.data.patients) as AssignedPatientUser[]
	const currentPatient = useAppSelector(state => state.currentPatient)
	const [isOpen, setIsOpen] = useState(false)
	const [patientListLoading, setPatientListLoading] = useState(true)
	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()

	/**
     * Callback for fetching and setting the redux state for patient data
     * Only launch if patients are not already fetched
     */
	const fetchPatients = useCallback(async userId => {
		if (!patients[0].username) {
			const patients = await userService.getPatients(userId)
			dispatch(setPatients(patients))
			setPatientListLoading(false)
		} else {
			setPatientListLoading(false)
		}
	}, [currentPatient])

	/**
     * Call the callback for fetching and storing the patients data
     */
	useEffect(() => {
		try {
			fetchPatients(user.data.id)
		} catch (exception) {
			console.error(exception)
		}
	}, [fetchPatients])

	/**
	 * Function to handle changing the language of the exercise library
	 * @param {string} lang - the language that the user is changing to
	 */
	const handlePatientChange = (idx: number) => {
		const patient = patients[idx]
		dispatch(setCurrentPatient(patient))
		const id = patient.id
		navigate(`/patients/${id}`)
	}

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	const getPatientItem = (patient) => {
		return (
			<Row className='justify-content-start flex-nowrap'>
				<Col className='mx-2' xs={4} sm={2}>
					<div className='patients-list--letter-circle'
						style={{
							backgroundColor: getPatientUserIconColor(patient.username)
						}}
					>
						{patient.username ? patient.username.charAt(0).toUpperCase(): '0'}
					</div>
				</Col>
				<Col className="align-self-center">{patient.username}</Col>
			</Row>
		)
	}

	return (
		<>
			{ patientListLoading
				? <Loading />
				: <Dropdown show={isOpen} onToggle={toggleDropdown}>
					<Dropdown.Toggle className='exercise-library--secondary-button' variant='outline-secondary'
						data-cy='patient_page_patient_select'>
						<Row className='flex-nowrap'>
							<Col>
								{getPatientItem(currentPatient)}
							</Col>
							<Col className="align-self-center">
								<KeyboardArrowDownIcon />
							</Col>
						</Row>
					</Dropdown.Toggle>

					<Dropdown.Menu data-cy='patient_page_select_options'>
						{patients.map((patient, i) => {
							return (
								<Dropdown.Item key={i}
									onClick={() => handlePatientChange(i)}
								>
									{getPatientItem(patient)}
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>
			}
		</>
	)
}

export default PatientPageSelect
import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { logoutCognitoUser } from 'reducers/userSlice'
import { clearExerciseList } from 'reducers/exerciseListSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'
import { Button } from 'react-bootstrap'
import LogoutIcon from '@mui/icons-material/Logout'
import { userIsAdminOrSlp } from 'utils/helpers'

import { Dispatch } from 'types/Types'

const LogOutButton = () => {
	const user = useAppSelector(state => state.user)
	const navigate = useNavigate()
	const dispatch = useDispatch<Dispatch>()
	const { t } = useTranslation()

	const logOutOnClick = event => {
		event.preventDefault()
		dispatch(logoutCognitoUser(userIsAdminOrSlp(user.data)))
		dispatch(changePreviousPage('/'))
		dispatch(clearExerciseList())
		navigate('/login')
	}

	return (
		<Button id='logout' className="text-nowrap exercise-library--secondary-button" variant="outline-secondary" onClick={logOutOnClick}>
			<LogoutIcon className='mx-1'/>
			{t('edit_user.logout')}
		</Button>
	)
}

export default LogOutButton
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'

import exerciseService from 'services/exercises'
import userService from 'services/users'

import { changePreviousPage } from 'reducers/previousPageSlice'
import { useAppSelector } from 'stateHandling/hooks'
import { changeCreateLoading } from 'reducers/appStateSlice'
import { setExercises } from 'reducers/currentPatientSlice'
import { getPatientList, setPatientListLoading } from 'reducers/userSlice'
import ExerciseListViewToggle from 'components/ExerciseLibrarySort/ExerciseListViewToggle'
import AssignedExercisesWrapper from
	'components/PatientPage/AssignedExercises/AssignedExercisesWrapper/AssignedExercisesWrapper'
import RemoveAssignedConfirmModal from
	'components/PatientPage/AssignedExercises/RemoveAssignedConfirmModal/RemoveAssignedConfirmModal'
import ChangeResetDateModal from 'components/PatientPage/ChangeResetDateModal'

import { LibraryExercise } from 'types/ExerciseTypes'
import { AssignedExercise } from 'types/UserTypes'
import { Dispatch } from 'types/Types'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import './AssignedExercises.css'


interface Props {
    changeUserMessage: (variant: string, text: string) => void
}

const AssignedExercises = ({ changeUserMessage }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch<Dispatch>()
	const [exercisesWithFormats, setExercisesWithFormats] = useState([] as LibraryExercise[])
	const [distinctExercises, setDistinctExercises] = useState([] as LibraryExercise[])
	const [selectedToRemove, setSelectedToRemove] = useState<LibraryExercise[]>([])
	const [showRemoveAssignedModal, setShowRemoveAssignedModal] = useState(false)
	const [showChangeResetDateModal, setShowChangeResetDateModal] = useState(false)
	const assignedExercises = useAppSelector(state => state.currentPatient.exercises)
	const patient = useAppSelector(state => state.currentPatient)
	const user = useAppSelector(state => state.user.data)

	useEffect(() => {
		dispatch(changePreviousPage(`/patients/${patient.id}`))
	}, [])

	/**
     * Callback for fetching and storing the exercise data
     */
	const fetchExercises = useCallback(async () => {
		const exerciseIds = assignedExercises.map(e => e.exerciseId)
		const exerciseList = await exerciseService.getByIds(exerciseIds)
		const tempExercises = exerciseList.map(e => {
			const answerFormats = assignedExercises.filter(a => a.exerciseId === e.id)[0].answerFormat
			e['useSpeaking'] = answerFormats.includes('speak')
			e['useWriting'] = answerFormats.includes('write')
			e['useReading'] = answerFormats.includes('read')
			e['useListening'] = answerFormats.includes('listen')
			return e
		})
		setExercisesWithFormats(tempExercises)
	}, [assignedExercises])

	/**
     * Call the callback for fetching and storing the exercise data
     */
	useEffect(() => {
		try {
			fetchExercises()
		} catch (exception) {
			console.error(exception)
		}
	}, [fetchExercises])

	/**
     * Get the exercise cards that should be visible
	 * If updating so that each exercise is its own object, this function can be removed
     */
	useEffect(() => {
		const tempExercises = [] as LibraryExercise[]
		exercisesWithFormats.forEach(exercise => {
			if (exercise.useReading) {
				const tempExercise = {...exercise }
				tempExercise.renderType = 'read'
				tempExercises.push(tempExercise)
			} if (exercise.useListening) {
				const tempExercise = {...exercise }
				tempExercise.renderType = 'listen'
				tempExercises.push(tempExercise)
			} if (exercise.useSpeaking) {
				const tempExercise = {...exercise }
				tempExercise.renderType = 'speak'
				tempExercises.push(tempExercise)
			} if (exercise.useWriting) {
				const tempExercise = {...exercise }
				tempExercise.renderType = 'write'
				tempExercises.push(tempExercise)
			}
		})
		setDistinctExercises(tempExercises)
	}, [exercisesWithFormats])

	/**
	 * Load the initial data structure of the array that holds what exercises that
	 * are marked to be removed
	 */
	useEffect(() => {
		const tempExercises = exercisesWithFormats.map((e) => {
			const exercise = Object.assign({}, e) as LibraryExercise // copy of object
			exercise['useWriting'] = false
			exercise['useSpeaking'] = false
			exercise['useReading'] = false
			exercise['useListening'] = false
			return exercise
		})
		setSelectedToRemove(tempExercises)
	}, [exercisesWithFormats])

	/**
     * Check an exercise -- add to data structure to remove
     * @param {ChangeEvent<HTMLInputElement>} event - clickevent
	 * @param {LibraryExercise} e - exercise that was selected
	 * @param {string} type - answerFormat of the chosen exercise
     * @returns {void}
     */
	const onItemCheck = (event, e, type) => {
		const tempExercises = selectedToRemove.map((ex) => {
			if (ex.id === e.id) {
				if (type === 'write' && ex.type.includes('write')) {
					ex.useWriting = event.target.checked
				} else if (type === 'speak' && ex.type.includes('speak')) {
					ex.useSpeaking = event.target.checked
				} else if (type === 'read' && ex.type.includes('read')) {
					ex.useReading = event.target.checked
				} else if (type === 'listen' && ex.type.includes('listen')) {
					ex.useListening = event.target.checked
				}
			}
			return ex
		})
		setSelectedToRemove(tempExercises)
	}
	/**
	 * Function to handle when removing exercises as selected.
	 * The function compares the exercises that are assigned to the patient
	 * with the exercises that are checked to be removed
	 * and compiles the ones that remain assigned
	 */
	const handleRemoveAssigned = () => {
		const exerciseMap = new Map<string, string[]>()
		for (const e of distinctExercises) {
			let answerFormats = [] as string[]
			if (exerciseMap.has(e.id)) {
				const existingFormats = exerciseMap.get(e.id)
				if (existingFormats) answerFormats = [...existingFormats]
			}
			const removedExercise = selectedToRemove.filter(ex => ex.id === e.id)[0]
			if (e.renderType === 'write' && !removedExercise.useWriting) answerFormats.push('write')
			if (e.renderType === 'speak' && !removedExercise.useSpeaking) answerFormats.push('speak')
			if (e.renderType === 'read' && !removedExercise.useReading) answerFormats.push('read')
			if (e.renderType === 'listen' && !removedExercise.useListening) answerFormats.push('listen')
			if (answerFormats.length > 0) exerciseMap.set(e.id, answerFormats)
		}
		const assignedExercises = [] as AssignedExercise[]
		Array.from(exerciseMap.entries()).forEach(([key, value]: [string, string[]]) => {
			assignedExercises.push({ exerciseId: key, answerFormat: value })
		})
		dispatch(setExercises(assignedExercises))
		saveUserSettings(assignedExercises)
	}

	/**
	 * wrapper for API call for updating the assigned exercises for the patient
	 * @param {AssignedExercise[]} assignedExercises - the exercises that shall remain assigned
	 */
	const saveUserSettings = async (assignedExercises) => {
		try {
			dispatch(changeCreateLoading(true))
			const data = new FormData()
			data.append('id', patient.id)
			assignedExercises.forEach(e => {
				data.append('excerciseIds', e.exerciseId)
				data.append('exerciseAnswerFormats', JSON.stringify(e.answerFormat))
			})
			await userService.updateUserSettings(data)
			changeUserMessage('success', `${t('edit_patient.messages.success_exercises_remove')}: ${patient.username}`)
			dispatch(setPatientListLoading(true))
			dispatch(getPatientList(user.id))
			setTimeout(() => {
				setShowChangeResetDateModal(true)
			}, 2000)
			setTimeout(() => {
				setShowChangeResetDateModal(false)
			}, 15000)
		} catch (err) {
			console.error('Error: ', err)
			changeUserMessage('danger', `${t('edit_patient.messages.failure_exercises_remove')}: ${err.response.data}`)
		}
	}

	return (
		<>
			<Row xs='auto' className='py-3'>
				<Col className='flex-grow-1'>

				</Col>
				<ExerciseListViewToggle />
			</Row>
			<div className='assigned-exercises--box p-4' data-cy='assigned_exercises_wrapper'>
				<Row xs='auto'>
					<Col className='flex-grow-1'>
						<p className='mb-1'><strong>{t('edit_patient.exercises.exercise_list_title')}</strong></p>
						<p>
							<small>
								{t('sort_exercises.exercise_number', {numExercises: distinctExercises.length})}
							</small>
						</p>
					</Col>
					{selectedToRemove.some(e => e.useWriting || e.useSpeaking || e.useReading || e.useListening)
						? <Col>
							<OverlayTrigger
								key={'delete-tooltip'}
								trigger={['hover', 'focus']}
								placement='left'
								overlay={
									<Tooltip id={'delete-tooltip'}>
										{t('edit_patient.exercises.remove_selected_modal.body')}
									</Tooltip>
								}>
								<Button
									onClick={() => setShowRemoveAssignedModal(true)}
									className='exercise-library--primary-outline-button'
									data-cy='remove_assigned_button'
								>
									<DeleteRoundedIcon />
								</Button>
							</OverlayTrigger>
						</Col>
						: <></>
					}
				</Row>
				<AssignedExercisesWrapper
					distinctExercises={distinctExercises}
					selectedToRemove={selectedToRemove}
					onItemCheck={onItemCheck}
				/>
			</div>
			{showRemoveAssignedModal
				? <RemoveAssignedConfirmModal
					setShowRemoveAssignedModal={setShowRemoveAssignedModal}
					handleRemoveAssigned={handleRemoveAssigned}
				/>
				: <></>
			}
			{showChangeResetDateModal
				? <ChangeResetDateModal
					setShowChangeResetModal={setShowChangeResetDateModal}
				/>
				: <></>
			}
		</>
	)
}

export default AssignedExercises
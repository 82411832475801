import {
	RECORDRTC_SAMPLE_RATE,
	MEDIARECORDER_SAMPLE_RATE,
	RECORDRTC_AUDIO_FORMAT,
	MEDIARECORDER_AUDIO_FORMAT
} from 'utils/config'
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc' // StereoAudioRecorder not maintained anymore!

// Reference: 
// RecordRTC: https://github.com/muaz-khan/RecordRTC
// Recording using MediaRecorder
// https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder 
// Instructions: https://developer.mozilla.org/en-US/docs/Web/API/MediaStream_Recording_API/Using_the_MediaStream_Recording_API 
// and create class perhaps

/**
 * Creates a new instance of the recorder 
 */
export const initializeRecorder = async () => {
	let recorder = null
	let usingRtcRecorder = false
	let stream = null
	if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
		stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
		if (!MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
			// Fallback to the RecordRTC library for iOS devices
			recorder = new RecordRTCPromisesHandler(stream, {
				type: 'audio',
				mimeType: RECORDRTC_AUDIO_FORMAT,
				desiredSampRate: RECORDRTC_SAMPLE_RATE,
				recorderType: StereoAudioRecorder,
				numberOfAudioChannels: 1
			})
			usingRtcRecorder = true
		} else {
			try {
				const options = {
					audioBitsPerSecond: MEDIARECORDER_SAMPLE_RATE,
					mimeType: MEDIARECORDER_AUDIO_FORMAT
				}
				recorder = new MediaRecorder(stream, options)
			} catch (error) {
				console.error(error.message)
			}
		}
	} else {
		console.error('getUserMedia is not supported by your browser!')
	}
	return [recorder, usingRtcRecorder, stream]
}

/**
 * Returns recorder instance
 * @returns {RecordRTCPromisesHandler} Responsible for recording audio data
 */
export const getRecorder = async () => {
	const [recorder, usingRtcRecorder, stream] = await initializeRecorder()
	return [recorder, usingRtcRecorder, stream]
}

/**
 * Processes the raw audio data generated by the TTS server and stored in the database to a format that we can play in the browser
 * @param {Object} buffer - { type: Buffer, data: Array }
 * @returns {AudioBufferSourceNode} Audio node that can be played in the browser
 */
export const processAudio = async (buffer, audioContext) => {
	let outputSource
	try {
		const arrayBuffer = new Uint8Array(buffer).buffer
		if (arrayBuffer.byteLength > 0) {
			let decodedAudio = await audioContext.decodeAudioData(arrayBuffer)
			outputSource = audioContext.createBufferSource()
			outputSource.buffer = decodedAudio
			outputSource.connect(audioContext.destination)
			// outputSource.playbackRate.value = 0.8 // for some reason this does not seem to work, it did earlier            
		}
	} catch (exception) {
		console.error(exception)
	}
	return outputSource
}

/**
 * AudioBufferSourceNode instance can be played only once, so for replays we will need to copy the instance
 * @param {Object} audio - { type: Buffer, data: Array }
 * @param {AudioContext} audioContext - { type: Buffer, data: Array }
 * @returns {AudioBufferSourceNode} Copy of the original audio node
 */
export const getAudioCopy = (audio, audioContext) => {
	const copy = new AudioBufferSourceNode(audioContext)
	copy.buffer = audio.buffer
	copy.connect(audioContext.destination)
	return copy
}
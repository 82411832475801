import React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

interface Props {
	handleContinueConversation: () => void;
	handleFinishConversation: () => void;
  }

const ContinuePrompt= ({handleContinueConversation, handleFinishConversation }:Props) => {
	const [show, setShow] = useState(true)

	const handleContinue = () => {
		handleContinueConversation()
		setShow(false)
	}

	const handleFinish = () => {
		handleFinishConversation()
		setShow(false)
	}

	return (
		<>
			<Modal show={show} onHide={() => handleContinue} className="chatbot--custom-modal">
				<Modal.Header>
					<Modal.Title> Vill du fortsätta konversationen?</Modal.Title>
				</Modal.Header>
				<Modal.Footer>
					<Button
						variant='success'
						size='lg'
						className='chatbot--confirmation-button m-2' onClick={() => handleContinue() }>
						Ja, fortsätt
					</Button>
					<Button variant='danger'
						size='lg'
						className='chatbot--confirmation-button m-2' onClick={() => handleFinish()}>
						Nej, avsluta
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ContinuePrompt
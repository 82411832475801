import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import Thinking from 'components/Chatbot/Thinking/Thinking'
import ChatbotRecorder from 'components/Chatbot/ChatbotRecorder'
import Write from 'components/Chatbot/Write'
import { Button, Row, Col } from 'react-bootstrap'

interface Props {
	message: string
	isThinking: boolean
	isFinished: boolean
	showConfirmation: boolean
	handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleSubmit: () => void
	handleAudioSubmit?: (type:FormData) => void
	handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
  }

const Footer = ({ message, isThinking, isFinished, showConfirmation, handleInput, handleSubmit, handleAudioSubmit, handleClick }:Props) => {
	const useSpeech = useAppSelector(state => state.chatbot.scenarioSettings.useSpeech)

	const speech = () => {
		return (
			<Col className='text-center'>
				{isThinking
					? <Thinking/>
					: showConfirmation
						? null
						: <ChatbotRecorder handleAudioSubmit={handleAudioSubmit} />

				}
			</Col>
		)
	}

	const writing = () => {
		return (
			<Col xs={12} className='d-flex'>
				{isThinking
					? <Thinking/>
					: <Write
						message={message}
						handleInput={handleInput}
						handleSubmit={handleSubmit}
					/>
				}
			</Col>
		)
	}

	const feedback = () => {
		return (
			<Col xs={12} sm={8} lg={6} className='text-center'>
				<Button variant='primary' size='lg' className='m-2' onClick={handleClick} name='home'>Tillbaka till startskärmen</Button>
			</Col>
		)
	}

	return (
		<Row className='justify-content-center align-items-center chatbot--footer exercise--footer-bg'>
			{isFinished
				? feedback()
				: useSpeech
					? speech()
					: writing()
			}
		</Row>
	)
}

export default Footer
export const setItemWithExpiry = (key, value, duration=1036800000) => {
    const now = Date.now()
    // const duration = 1000 * 60 * 60 * 24 * 30 * 12 = 1036800000 // one year in ms

    const item = {
        value: value,
        createdAt: now,
        expiresAt: now + duration
    }
    localStorage.setItem(key, JSON.stringify(item)) 
}

export const removeItemWithExpiry = (key) => {
    localStorage.removeItem(key)
}

export const getItemWithExpiry = (key) => {
    const itemAsString = window.localStorage.getItem(key)
    
    if (!itemAsString) return false

    const item = JSON.parse(itemAsString)
    const now = Date.now()

    if (now > item.expiresAt) {
        localStorage.removeItem(key)
        window.location.href = '/login'
        return false
    }

    return item.value
}
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import dayjs from 'dayjs'
import { Card, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { DBExercise } from 'types/ExerciseTypes'
import { filterIconColor } from 'utils/helpers'
import ExerciseCardProgressBar from 'components/ExerciseCard/ExerciseCardProgressBar'
import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import { DBStatistic } from 'types/StatisticTypes'
import { PatientUser } from 'types/UserTypes'

interface Props {
    exercise: DBExercise
	idx: string
    id: string
	answerFormat:string
    numQuestions: number
    clickEvent: (id: string) => void
    getIcon: () => IconDefinition
 }

const ExerciseCardPatient = ({exercise, idx, id, answerFormat, numQuestions, clickEvent, getIcon}: Props) => {
	const { t } = useTranslation()
	const patientStatistics = useAppSelector(state => state.currentPatient.statistics)
	const exerciseAudio = useAppSelector(state => state.exerciseList.exerciseAudio)
	const user = useAppSelector(state => state.user.data) as PatientUser
	const [resultsLabel, setResultsLabel] = useState<string>('')
	const [isCompleted, setIsCompleted] = useState<boolean>(false)

	/**
     * Function to compile and compute the statistics for the exercise card
	 * Triggers when the statistics are updated
     */
	useEffect(() => {
		const patientStatisticsArray = Object.values(patientStatistics)
		const exerciseStatistic = patientStatisticsArray.filter((item) => {
			return item.exerciseId === exercise.id && item.answerFormat === answerFormat
		})[0]

		// If there is no statistics data for this exercise yet
		if (exerciseStatistic !== undefined) {
			const [lastAttemptNrOfCorrectAnswers, isCompleted] = getLastAttemptNrOfCorrect(exerciseStatistic)
			if (isCompleted) {
				setIsCompleted(true)
				const progressLabel = `${lastAttemptNrOfCorrectAnswers}/${numQuestions}`
				setResultsLabel(progressLabel)
			}
		}

	}, [patientStatistics])

	/**
     * Gets the number of correct answers on the last attempt at the exercise
     * @param {BDStatistics} exerciseStatistic - statistics object for one exercise
     * @param {number} nrOfCorrectAnswers - the number of correct answers in the last completed attempt
	 * @param {boolean} completed - indicates if any attempts was ever completed for the exercise
     */
	const getLastAttemptNrOfCorrect = (exerciseStatistic: DBStatistic): [number, boolean] => {
		const now = dayjs()
		const attempts = exerciseStatistic.attempts

		const filteredAttempts = attempts.filter(attempt => dayjs(attempt.end).isBetween(user.settings.resetDate, now))

		const firstCompletedAttempt = filteredAttempts.find((attempt) => attempt.completed === true)
		let completed = false

		if (firstCompletedAttempt === undefined) {
			// If no completed attempts were found, take the first element
			const lastAttempt = attempts[0]
			const nrOfCorrectAnswers = lastAttempt.answers.filter((answer) => answer.correct === true).length
			return [nrOfCorrectAnswers, completed]
		} else {
			const nrOfCorrectAnswers = firstCompletedAttempt.answers.filter((answer) => answer.correct === true).length
			completed = true
			return [nrOfCorrectAnswers, completed]
		}
	}

	return (
		<>
			<Card
				id={id}
				className="exerciseCard--card"
				onClick={() => clickEvent(exercise.id)}
				data-cy='patient_exercise_card'
			>
				<div className='exercise--card-number-icon'>
					<div className='exercise--card-corner-box' data-cy='exercise_card_number_icon'>{idx}</div>
				</div>
				<ExerciseCardProgressBar
					isCompleted={isCompleted}
				/>
				<div className='exerciseCard--img-wrapper'>
					<FontAwesomeIcon
						data-cy='patient_exercise_card_type_icon'
						className='exerciseCard--answer-format-icon-patient'
						style={{ color: filterIconColor[answerFormat] }} icon={getIcon()} />
				</div>
				<Card.Body>
					<Card.Title data-cy='patient_exercise_card_title'>
						<Row xs='auto' className='align-items-center'>
							<Col className='flex-grow-1'>
								{exercise.title.text}
								{exercise.settings.hasImage
									? <OverlayTrigger
										key={`image-tooltip-${exercise.id}`}
										trigger={['hover', 'focus']}
										placement="right"
										overlay={
											<Tooltip id={`image-tooltip-${exercise.id}`}>
												{t('exercise_card.hasImage')}
											</Tooltip>
										}>
										<div className="d-inline">
											<FontAwesomeIcon className='exercise--card-icon-image' icon={faImage} data-cy='patient_exercise_card_includes_images_icon' />
										</div>
									</OverlayTrigger>
									: <></>
								}
							</Col>
							<Col>
								<AudioPlayer
									buffer={exerciseAudio[exercise.id] ? exerciseAudio[exercise.id].title.audio : []}
									large={false}
									text={true}
								/>
							</Col>
						</Row>
					</Card.Title>
					{exercise.description.patient.text.length > 0
						? <div className='mt-1 mb-1' data-cy='patient_exercise_card_description'>{exercise.description.patient.text}</div>
						: <></>}
					<div className='mb-0' data-cy='patient_exercise_num_question_or_progress'>
						{isCompleted
							? <>
								<b className='mb-1'>{t('exercise_card.card_progress_bar.last_score')}</b>
								<p className='fw-bold exercisePage--completed-label'>{resultsLabel}</p>
							</>
							: <b>{t('exercise_card.num_questions', {numQuestions})}</b>
						}
					</div>
				</Card.Body>
			</Card>
		</>
	)
}

export default ExerciseCardPatient
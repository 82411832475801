import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useAppSelector } from 'stateHandling/hooks'
import { useTranslation } from 'react-i18next'

import ProgressBar from 'react-bootstrap/ProgressBar'

const StatusBar = () => {
	const { t } = useTranslation()

	const stats = useAppSelector(state => state.currentExercise.stats)
	const exerciseLength = useAppSelector(state => state.currentExercise.length)

	/**
     * Generates data for the progress for progress bar
     * @returns {int} - current completion %
     */
	const progressData = () => {
		return ((stats.index + 1) / exerciseLength) * 100
	}

	return (
		<>
			<Row className='exercise--header-bg justify-content-center align-items-center px-2 pb-3'>
				<Col>
					<ProgressBar className='exercise--progress-bar2'>
						<ProgressBar className='exercise--progress-bar1' now={progressData()} />
					</ProgressBar>
				</Col>
			</Row>
		</>
	)
}

export default StatusBar
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import langBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

/**
 * Localization service
 * See: https://react.i18next.com/
 */
i18next
	.use(initReactI18next)
	.use(langBackend)
	.use(LanguageDetector)
	.init({
		supportedLngs: ['en', 'sv', 'fi'],
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		// detection: {
		//   caches: []   // including this would prevent storing i18nextLng in localStorage
		// }
	})

export default i18next
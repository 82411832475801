import { useTranslation } from 'react-i18next'
import { Form, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setShowMessage } from 'reducers/userSlice'

import { CognitoUser } from 'amazon-cognito-identity-js'

const VerifyAccount = ({ username, setSuccessMessage, setErrorMessage, userPool }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const verifyAccount = async event => {
		event.preventDefault()
		try {
			const user = new CognitoUser({
				Username: username,
				Pool: userPool,
			})
			const otp = event.target.otp.value
			await new Promise((resolve, reject) => {
				user.confirmRegistration(otp, true, (err, data) => {
					if (err) {
						reject(err)
					} else {
						resolve(data)
					}
				})
			})
			setSuccessMessage(`${t('verify_account.status_messages.success_verify', { username })}`)
		} catch (err) {
			console.error('Could not verify account', err)
			setErrorMessage(
				`${t('verify_account.status_messages.error_verify', { username, errorMessage: err.message })}`
			)
		}
	}

	const resendSignupCode = async event => {
		event.preventDefault()
		try {
			const user = new CognitoUser({
				Username: username,
				Pool: userPool,
			})
			await new Promise((resolve, reject) => {
				user.resendConfirmationCode((error, result) => {
					if (error) {
						reject(error)
					} else {
						resolve(result)
					}
				})
			})
			dispatch(setShowMessage({
				showMessage: true,
				message: `${t('verify_account.status_messages.success_confirmation_code')}`,
				variant: 'success'
			}))
		} catch (err) {
			console.error('Could not send verification code', err)
			dispatch(setShowMessage({
				showMessage: true,
				message: `${t(
					'verify_account.status_messages.error_confirmation_code',
					{ errorMessage: err.message }
				)}`,
				variant: 'danger'
			}))
		}
	}

	return (
		<Form onSubmit={verifyAccount}>
			<Form.Group className="mb-3" controlId='otp'>
				<Form.Text>{t('verify_account.verification')}</Form.Text>
				<Form.Control
					autoComplete='off' type="text" name='otp'
					placeholder={t('verify_account.verification_placeholder')} required
				/>
			</Form.Group>
			<Button type='submit'>{t('verify_account.verify_button')}</Button>
			<Button className='mx-2' variant='outline-primary' onClick={resendSignupCode}>
				{t('verify_account.send_code_button')}
			</Button>
		</Form>
	)
}

export default VerifyAccount
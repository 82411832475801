import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button, Alert } from 'react-bootstrap'

interface Props {
    handleClose(): void
    addUser(): void
    userNamingConventionAlert(): React.ReactNode
    username: string
}

const ConfirmUserCreationModal = ({handleClose, addUser, userNamingConventionAlert, username }: Props) => {
	const { t } = useTranslation()

	return (
		<>
			<Modal data-cy='create_user_modal' show={true} size='lg'>
				<Modal.Header>
					<Modal.Title>{t('create_user.confirm_username.title')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p className='fs-5'>{t('create_user.confirm_username.new_username')} <b>{username}</b></p>
					{userNamingConventionAlert()}
				</Modal.Body>

				<Modal.Footer>
					<Button className='exercise-library--secondary-button' variant='outline-secondary'
						data-cy='create_user_cancel' onClick={handleClose}
					>
						{t('create_user.confirm_username.cancel')}
					</Button>
					<Button data-cy='create_user_confirm' variant='primary'
						onClick={addUser}
					>
						{t('create_user.confirm_username.confirm')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ConfirmUserCreationModal
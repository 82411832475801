import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const BrowserPopup = ({ handleBrowserPopup }) => {
    const [show, setShow] = useState(true)
    const { t } = useTranslation()

    const handleClose = () => {
      setShow(false)
      handleBrowserPopup()
    }
  
    return (
      <>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title> {t("browser_info.prompt")}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>{t('browser_info.close')}</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
}

export default BrowserPopup
import { FlareSharp } from '@mui/icons-material'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Scenario } from 'types/ChatbotTypes'

interface chatbotState {
  scenarios: Scenario []
  currentScenario: Scenario

  scenarioSettings: {
    useSpeech: boolean,
    maxTurns: number,
    addedTurns: number,
    currentTurn: 0
  }
  isStarted: boolean,
  loading: boolean
}

const MAX_TURNS = 10
const ADDED_TURNS = 10

const initialState: chatbotState = {
	scenarios: [],
    currentScenario: {
        title: '',
        description: '',
        scenario: []
    },
    scenarioSettings: {
        useSpeech: true,
        maxTurns: MAX_TURNS,
        addedTurns: ADDED_TURNS,
        currentTurn: 0
    },
    isStarted: false,
    loading: true
}

export const chatbotSlice = createSlice({
	name: 'chatbot',
	initialState,
	reducers: {
		changeScenarios: (state:chatbotState, action:PayloadAction<Scenario[]>) => {
			state.scenarios = action.payload
		},
		changeCurrentScenario: (state:chatbotState, action:PayloadAction<Scenario>) => {
			state.currentScenario = action.payload
            state.isStarted = true
		},
        changeUseSpeech: (state:chatbotState, action:PayloadAction<boolean>) => {
			state.scenarioSettings.useSpeech = action.payload
		},
        changeLoading: (state:chatbotState, action:PayloadAction<boolean>) => {
			state.loading = action.payload
		},
        resetCurrentScenario: (state:chatbotState) => {
			state.currentScenario = initialState.currentScenario
            state.scenarioSettings = initialState.scenarioSettings
            state.isStarted = false
		},
        changeMaxTurns: (state:chatbotState) => {
            state.scenarioSettings.maxTurns += state.scenarioSettings.addedTurns
        },
        changeCurrentTurn: (state:chatbotState) => {
            state.scenarioSettings.currentTurn += 1
        }

	},
})

export const {
	changeScenarios,
	changeCurrentScenario,
    changeUseSpeech,
    changeLoading,
    resetCurrentScenario,
    changeMaxTurns,
    changeCurrentTurn
} = chatbotSlice.actions

export default chatbotSlice.reducer
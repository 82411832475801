import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'

const SelectCategory = ({ handleChange, category }) => {

	const { t } = useTranslation()

	return (
		<Form.Group>
			<Form.Text>{t('add_image.category')}</Form.Text>
			<Form.Select
				value={category ? category : 'objects'}
				onChange={handleChange}
				className="addImage--select"
				name='category'
				data-cy='edit-image-category-select'
			>
				<option value='adjectives' data-cy='edit-image-category-option'>{t('add_image.adjectives')}</option>
				<option value='animals' data-cy='edit-image-category-option'>{t('add_image.animals')}</option>
				<option value='feelings' data-cy='edit-image-category-option'>{t('add_image.feelings')}</option>
				<option value='food' data-cy='edit-image-category-option'>{t('add_image.food')}</option>
				<option value='home' data-cy='edit-image-category-option'>{t('add_image.home')}</option>
				<option value='indoor' data-cy='edit-image-category-option'>{t('add_image.indoor')}</option>
				<option value='objects' data-cy='edit-image-category-option'>{t('add_image.objects')}</option>
				<option value='outdoor' data-cy='edit-image-category-option'>{t('add_image.outdoor')}</option>
				<option value='people' data-cy='edit-image-category-option'>{t('add_image.people')}</option>
				<option value='verbs' data-cy='edit-image-category-option'>{t('add_image.verbs')}</option>
				<option value='work' data-cy='edit-image-category-option'>{t('add_image.work')}</option>
				<option value='other' data-cy='edit-image-category-option'>{t('add_image.other')}</option>
			</Form.Select>
		</Form.Group>
	)
}

export default SelectCategory
import { configureStore} from "@reduxjs/toolkit";
import loggerMiddleware from './middleware/logger'
import rootReducer from '../reducers/combinedReducers'

const PROD_ENV = process.env.NODE_ENV === 'production'

// TODO: 
// * Figure out how to use enhancers properly with TS if needed.  
// Problem : https://stackoverflow.com/questions/71142320/how-to-properly-type-redux-enhancer-in-ts
// Reference configureStore.js 

const configStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => PROD_ENV ? getDefaultMiddleware() : [...getDefaultMiddleware(), loggerMiddleware],
  devTools: PROD_ENV ? false : true
})

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('reducers/combinedReducers', () => configStore.replaceReducer(rootReducer))
}

export type RootState = ReturnType<typeof configStore.getState> // rootReducer
export type AppDispatch = typeof configStore.dispatch
export default configStore

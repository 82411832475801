import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Alert, Container, Popover } from 'react-bootstrap'

import { useAppSelector } from 'stateHandling/hooks'
import { changeCreateLoading } from 'reducers/appStateSlice'
import { addUserExerciseId } from 'reducers/userSlice'
import { changeLibraryTab } from 'reducers/previousPageSlice'
import { parseString, getLanguageCode, itemIsUnique, itemIsValid } from 'utils/helpers'
import {
	changeQuestions,
	changeAnswersInput,
	changeAlternativesInput,
	addAnswer,
	reset,
	resetAnswers,
	updateAudio,
	changeExerciseMessage,
	resetExerciseMessage
} from 'reducers/newOrEditedExerciseSlice'
import exerciseService from 'services/exercises'
import SelectType from 'components/SelectType'
import CreateExercise from 'components/CreateExercise'
import CreateFillExercise from 'components/CreateFillExercise'
import CreateComprehensionExercise from 'components/CreateComprehensionExercise/CreateComprehensionExercise'
import Loading from 'components/Loading'
import {
	EXERCISE_TEMPLATE_DEFAULT,
	EXERCISE_TEMPLATE_FILL,
	EXERCISE_TEMPLATE_LONG,
	EXERCISE_TEMPLATE_COMPREHENSION // to be removed
} from 'utils/config'
import './CreateExercisePage.css'

interface Props {
	isEdit: boolean
}

const CreateExercisePage = ({ isEdit }: Props) => {
	const { t, i18n } = useTranslation()
	const loading = useAppSelector(state => state.appState.createLoading)
	const newOrEditedExercise = useAppSelector(state => state.newOrEditedExercise)
	const newExercise = useAppSelector(state => state.newOrEditedExercise.exercise)
	const questions = useAppSelector(state => state.newOrEditedExercise.questions)
	const tag = useAppSelector(state => state.newOrEditedExercise.tag)
	const answerInputs = useAppSelector(state => state.newOrEditedExercise.answers)
	const user = useAppSelector(state => state.user)

	// TODO: figure out a better way than this. problem is FormData
	const dispatch = useDispatch<any>()
	const navigate = useNavigate()

	const [disabled, setDisabled] = useState(true)
	const [variant, setVariant] = useState('secondary')
	const [message, setMessage] = useState('')
	const [saveMessage, setSaveMessage] = useState('')
	const [currentAlternativeIdx, setCurrentAlternativeIdx] = useState('')

	// Copy of the original exercise, only used for comparison when checking if exercise is edited
	const [originalExercise, setOriginalExercise] = useState({...newExercise})

	const renderPopover = (content: string) => <Popover><Popover.Body>{content}</Popover.Body></Popover>

	/**
     * Responsible for updating disabled state of the save button
     */
	useEffect(() => {
		const questionsEdited = Object.values(questions).some((q) => q.edited)
		const titleEdited = originalExercise.title.text !== newExercise.title.text
		const descriptionEdited = Object.keys(newExercise.description).some((k) =>
			newExercise.description[k] !== originalExercise.description[k]
		)
		const difficultyEdited = Number(originalExercise.difficulty) !== Number(newExercise.difficulty)

		const settingsEdited = Object.keys(newExercise.settings).some((k) =>
			newExercise.settings[k] !== originalExercise.settings[k]
		)

		const tagsEdited = !(originalExercise.tags.every(tag => newExercise.tags.includes(tag))
            && newExercise.tags.every(tag => originalExercise.tags.includes(tag)))

		const subtypeEdited = !(originalExercise.subtype.every(tag => newExercise.subtype.includes(tag))
            && newExercise.subtype.every(tag => originalExercise.subtype.includes(tag)))

		const categoryEdited = originalExercise.category !== newExercise.category

		const edited = questionsEdited || titleEdited || descriptionEdited || difficultyEdited
            || settingsEdited || tagsEdited || subtypeEdited || categoryEdited

		// requirements to be able to save
		const hasAnswers = Object.values(questions).every((q) => q.answers && q.answers.length > 0)

		let hasAlternatives = true
		if (newExercise.template === 'comprehension' && newExercise.answerFormat !== 'yes/no') {
			hasAlternatives = Object.values(questions).every((q) => {
				const filteredAnswers = q.answers.filter(answerObj => answerObj.answer.text.trim() !== '')
				return filteredAnswers.length === 4
			})
		} else if (newExercise.template === 'comprehension') {
			hasAlternatives = Object.values(questions).every((q) => {
				// only yes and no can be entered as alternatives.
				return q.answers.map(answerObj => answerObj.answer.text).includes('yes') && q.answers.map(answerObj => answerObj.answer.text).includes('no')
			})
		}

		const hasTitle = newExercise.title.text !== ''
		const hasType = newExercise.type.length > 0
		const hasSubtype = newExercise.subtype.length > 0
		const hasDescription = newExercise.description.slp.text.length > 0 && newExercise.description.patient.text.length > 0

		let hasImages = true
		if (newExercise.settings.hasImage) {
			hasImages = Object.values(questions).every((q) => q.imageURL && q.imageURL !== 'undefined')
		}

		let hasRequired = hasType && hasTitle && hasDescription && hasImages
		if (newExercise.template === 'comprehension') {
			hasRequired = hasRequired && hasAlternatives
		} else {
			hasRequired = hasRequired && hasSubtype && hasAnswers
		}

		if (edited && hasRequired) {
			setDisabled(false)
			setVariant('primary')
		} else {
			setDisabled(true)
			setVariant('secondary')
		}
		updateSaveMessage(edited, hasRequired, hasTitle, hasType, hasSubtype, hasDescription, hasAnswers, hasImages, hasAlternatives)
	}, [newOrEditedExercise])

	const updateSaveMessage = (edited, hasRequired, hasTitle, hasType, hasSubtype, hasDescription, hasAnswers, hasImages, hasAlternatives) => {
		if (!edited) {
			setSaveMessage(t('create_exercise.messages.save_messages.edit'))
		} if (!hasRequired) {
			const message = t('create_exercise.messages.save_messages.required_base')
			const fields = []
			if (!hasTitle) fields.push(t('create_exercise.messages.save_messages.title'))
			if (!hasType) fields.push(t('create_exercise.messages.save_messages.type'))
			if (newExercise.template !== 'comprehension') {
			    if (!hasSubtype) fields.push(t('create_exercise.messages.save_messages.subtype'))
			    if (!hasAnswers) fields.push(t('create_exercise.messages.save_messages.answers'))
			}
			if (newExercise.template === 'comprehension' && !hasAlternatives) {
				fields.push(t('create_exercise.messages.save_messages.alternatives'))
			}
			if (!hasImages) fields.push(t('create_exercise.messages.save_messages.images'))
			if (!hasDescription) fields.push(t('create_exercise.messages.save_messages.description'))
			if (fields.length < 2) {
				setSaveMessage(`${message} ${fields.join(' ')}.`)
			} else if (fields.length === 2) {
				setSaveMessage(`${message} ${fields.join(` ${t('create_exercise.messages.save_messages.and')} `)}.`)
			} else {
				setSaveMessage(`${message} ${fields.slice(0, -2).join(', ')}, ${fields.slice(-2).join(` ${t('create_exercise.messages.save_messages.and')} `)}.`)
			}
		}
	}

	/**
     * reset the new exercise object on first render
     */
	useEffect(() => {
		if (!isEdit) {
			dispatch(reset())
		}
	}, [])

	/**
     * reset the new exercise if template changes to/from comprehension to/from production
    */
	useEffect(() => {
		if (!isEdit && ['default', 'fill'].includes(newExercise.template)) {
			dispatch(resetAnswers())
		}
	}, [newExercise.template])

	/**
     * Creates a FormData ('multipart/form-data') element where the text and image content of a new exercise are stored,
     * then this data is sent to the server, and with the response we update the exercise list with a newly created exercise,
     * then the values in the exercise form are emptied and the page is changed to exercise list page
     * @param {*} event 
     */
	const addExercise = async event => {
		event.preventDefault()
		if (disabled) {
			setMessage(`${t('create_exercise.messages.values_missing')}`)
			return
		}
		try {
			dispatch(changeCreateLoading(true))

			const formData = new FormData()
			const template = newExercise.template
			formData.append('user', user.data.id)
			formData.append('title', newExercise.title.text)
			formData.append('slpDescription', newExercise.description.slp.text)
			formData.append('patientDescription', newExercise.description.patient.text)

			// Append the parts of the form that are edited
			Object.values(questions).forEach((q, i) => {
				if (q.edited) {
					if (template === EXERCISE_TEMPLATE_FILL) {
						if (q.start) formData.append('start', q.start)
						else formData.append('start', 'undefined')
						if (q.end) formData.append('end', q.end)
						else formData.append('end', 'undefined')
					} else {
						if (q.question.text) formData.append('question', q.question.text)
						else formData.append('question', 'undefined')
					}
					const answers = [...q.answers].map(answerObj => answerObj.answer.text.trim())
					if (answers[i+1]) {
						const answer = answers[i+1].trim()
						if (itemIsValid(answers, answer)) answers.push(answer)
					}
					formData.append('answers', JSON.stringify(answers))
					if (q.imageURL) {
						formData.append('imageURLs', q.imageURL)
					} else {
						formData.append('imageURLs', 'undefined')
					}
					if (q.id) {
						formData.append('ids', q.id)
					}
				}
			})
			newExercise.type.forEach(t => {
				formData.append('type', t)
			})
			newExercise.skill.forEach(t => {
				formData.append('skill', t)
			})
			newExercise.subtype.forEach(t => {
				formData.append('subtype', t)
			})
			if (newExercise.tags) {
				newExercise.tags.forEach(t => {
					formData.append('tags', t)
				})
				if (tag.trim().length > 0 && itemIsUnique(newExercise.tags, tag)) {
					formData.append('tags', tag.trim())
				}
			}

			const answerFormat = newExercise.template === 'comprehension' ? newExercise.answerFormat! : newExercise.template // for now, when updating exercise builder, we should have newExercise.answerFormat for all exercises
			formData.append('answerFormat', answerFormat)
			const contentFormat = newExercise.template === 'comprehension' ? 'default' : newExercise.template
			formData.append('contentFormat', contentFormat)

			formData.append('isPublic', newExercise.settings.isPublic.toString())
			formData.append('hasImage', newExercise.settings.hasImage.toString())
			formData.append('useHint', newExercise.settings.useHint.toString())
			formData.append('shuffleQuestions', newExercise.settings.shuffleQuestions.toString())
			formData.append('langCode', getLanguageCode(i18n.language))
			formData.append('template', template)
			formData.append('category', newExercise.category)
			formData.append('isAdult', newExercise.isAdult.toString())
			const difficulty = newExercise.category === 'letter' ? 0 : newExercise.difficulty
			formData.append('difficulty', difficulty.toString())

			const userData = new FormData()
			userData.append('user', user.data.id)
			if (newExercise.id) {
				const res = await exerciseService.update(newExercise.id, formData)
				if (res && res.id) {
					dispatch(changeExerciseMessage(`${t('create_exercise.messages.edit_success', {title: res.title.text})}`))
				}
				const qIds = formData.getAll('ids')
				qIds.forEach(rawId => {
					const id = rawId.toString()
					dispatch(updateAudio({ id, userData }))
				})
			} else {
				const res = await exerciseService.create(formData)
				if (res && res.id) {
					dispatch(addUserExerciseId(res.id))
					dispatch(changeExerciseMessage(`${t('create_exercise.messages.create_success', {title: res.title.text})}`))
				}
				res.questions.forEach(rawId => {
					const id = rawId.toString()
					dispatch(updateAudio({ id, userData }))
				})
			}
			setMessage('')
			dispatch(reset())
			setTimeout(() => {
				dispatch(resetExerciseMessage())
			}, 10000)
			dispatch(changeCreateLoading(false))
			dispatch(changeLibraryTab('1'))
			navigate('/edit_exercises')
		} catch (err) {
			console.error('EXCEPTION OCCURED', err)
			dispatch(changeCreateLoading(false))
			setMessage(`${t('create_exercise.messages.error')} ${err.response.data}`)
			dispatch(reset())
		}
	}

	const handleQuestionChange = i => event => {
		event.preventDefault()
		const name = event.target.name
		const value = event.target.value || ''
		const questionData = { name, value, i }
		dispatch(changeQuestions(questionData))
	}

	const handleAnswerInputChange = i => event => {
		event.preventDefault()
		dispatch(changeAnswersInput({i, value: event.target.value}))
	}

	//Handles the input for alternatives, both text and images. Either the text is saved in the answers array or the images url are saved
	const handleAlternativeInputChange = (i, j) => (event) => {
		event.preventDefault()
		const value = event.target.value
		setCurrentAlternativeIdx(j)
		const answerObj = JSON.parse(JSON.stringify(questions[i].answers[j]))
		answerObj.answer.text = value
		dispatch(changeAlternativesInput({ i: i, j: j, value: answerObj }))
	}

	/**
	 * @param {Number} i 
	 * @param {Event} event 
	 * @returns 
   */
	const handleNewAnswerTag = i => event => {
		if (event.key === 'Enter' || !event.key) {
			event.preventDefault()
			const newAnswer = parseString(answerInputs[i])
			dispatch(addAnswer({i, t: newAnswer}))
		}
	}

	/**
     * When the page hook's value is changed updates the view content accordingly
     * @returns {JSX.element} Selected component
    */
	const pageContent = () => {
		if (newExercise.template === EXERCISE_TEMPLATE_DEFAULT) {
			return (
				<>
					<CreateExercise
						addExercise={addExercise}
						isEdit={isEdit}
						handleQuestionChange={handleQuestionChange}
						handleAnswerInputChange={handleAnswerInputChange}
						handleNewAnswerTag={handleNewAnswerTag}
						disabled={disabled}
						variant={variant}
						saveMessage={saveMessage}
						renderPopover={renderPopover}
					/>
				</>
			)
		} else if (newExercise.template === EXERCISE_TEMPLATE_FILL) {
			return (
				<>
					<CreateFillExercise
						addExercise={addExercise}
						isEdit={isEdit}
						handleQuestionChange={handleQuestionChange}
						handleAnswerInputChange={handleAnswerInputChange}
						handleNewAnswerTag={handleNewAnswerTag}
						disabled={disabled}
						variant={variant}
						saveMessage={saveMessage}
						renderPopover={renderPopover}
					/>
				</>
			)
		}
		else if (newExercise.template === EXERCISE_TEMPLATE_COMPREHENSION) {
			return (
				<>
					<CreateComprehensionExercise
						addExercise={addExercise}
						isEdit={isEdit}
						handleQuestionChange={handleQuestionChange}
						handleAlternativeInputChange={handleAlternativeInputChange}
						disabled={disabled}
						variant={variant}
						saveMessage={saveMessage}
						currentAlternativeIdx={currentAlternativeIdx}
						setCurrentAlternativeIdx={setCurrentAlternativeIdx}
						renderPopover={renderPopover}
					/>
				</>
			)
		}
	}
	const typeSelection = () => {
		return (
			<>
				<SelectType
					isEdit={isEdit}
				/>
			</>
		)
	}

	return (
		<Container>
			{loading
				? <Loading />
				: <div className='d-flex justify-content-md-center'>
					<div className="w-75 mb-4">
						<h1>{isEdit ? t('edit_exercise.exercise_title') : t('create_exercise.page_title')}</h1>
						{message.length > 0 ? <Alert variant='danger'>{message}</Alert> : <></> }
						{typeSelection()}
						{pageContent()}
					</div>
				</div>
			}
		</Container>
	)
}

export default CreateExercisePage
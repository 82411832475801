import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { useAppDispatch } from 'stateHandling/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Hotkeys from 'react-hot-keys'
import { changeStatus } from 'reducers/currentExerciseSlice'


const StatusDescription = () => {
	const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const startExercise = () => {
		dispatch(changeStatus('none'))
	}

	const onKeyDown = (keyName, event) => {
		event.preventDefault()
		startExercise()
	}

	return (
        <Row className='justify-content-center exercise--footer-fixed'>
            <Col className='text-center flex-grow-0 align-self-center'>
                <Hotkeys keyName="enter" onKeyDown={onKeyDown}>
                    <Button
                        className='text-nowrap exercise--next-button px-5 py-4 fs-5 my-5'
                        onClick={startExercise} variant='primary'
                        data-cy='exercise_description_start_button'
                    >
                        <FontAwesomeIcon className='exercise--next-button-icon' icon={faPlay} />
                        {t('exercise.button_text.start')}
                    </Button>
                </Hotkeys>
            </Col>
        </Row>
	)
}

export default StatusDescription
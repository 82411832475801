import { useTranslation } from 'react-i18next'
import { Table, Badge, Image } from 'react-bootstrap'
import { AWS_URL } from 'utils/config'

const QuestionsTable = ({ questions }) => {
	const { t } = useTranslation()

	return (
		<>
			<Table striped hover responsive>
				<thead>
					<tr>
						<th>#</th>
						<th>{t('preview_exercise.table.question')}</th>
						<th>{t('preview_exercise.table.answers')}</th>
						<th>{t('preview_exercise.table.image')}</th>
					</tr>
				</thead>
				<tbody>
					{Object.values(questions).map((q, i) => {
						return (
							<tr key={i}>
								<td>{i+1}</td>
								<td>
									{q.template === 'default'
										? q.question.text
										: q.sentence.text
									}
								</td>
								<td>
									{q.answers.map(answerObj =>
										<Badge key={answerObj.answer.text} className='m-1' bg='secondary'>
											{answerObj.answer.text}
										</Badge>
									)}
								</td>
								<td>
									{q.imageURL && q.imageURL !== 'undefined'
										? <Image
											className='images--image'
											src={AWS_URL + q.image.url}
											width='200'
										/>
										: <p>{t('preview_exercise.table.no_image')}</p>
									}
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</>
	)
}

export default QuestionsTable
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Alert, Image, Row, Col} from 'react-bootstrap'
import {faComment, faPen, faHeadphones, faBook, faBrain }
	from '@fortawesome/free-solid-svg-icons'
import PhonologyIcon from 'images/phonology.svg'
import GrammarIcon from 'images/grammar.svg'
import UnderstandingIcon from 'images/understanding.svg'
import VocabularyIcon from 'images/vocabulary.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { filterIconColor } from 'utils/helpers'
import timer from 'timer/index'
import { DBStatistic } from 'types/StatisticTypes'
import { useAppSelector } from 'stateHandling/hooks'

interface Props {
	filteredPatientStatistics: DBStatistic[]
  }

const SingleExerciseStatisticsTable = ({ filteredPatientStatistics }: Props) => {
	const { t } = useTranslation()
	const patientIsAdult = useAppSelector(state => state.currentPatient.isAdult)
	/**
     * Get the correct icon, given the type
     * @param {string} type - event intance for onKeyDown event
     */
	const getTypeIcon = (type: string) => {
		if (type === 'write')
			return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['write']}} icon={faPen} />
		if (type === 'speak')
			return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['speak']}} icon={faComment} />
		if (type === 'read')
			return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['read']}} icon={faBook} />
		if (type === 'listen')
			return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['listen']}} icon={faHeadphones} />
		else return <></>
	}

	/**
     * Get the correct icon, given the skill
     * @param {string} type - event intance for onKeyDown event
     */
	const getSkillIcon = (skill: string) => {
		if (skill === 'phonology')
			return <Image src={PhonologyIcon} className='px-1' />
		if (skill === 'grammar')
			return <Image src={GrammarIcon} className='px-1' />
		if (skill === 'understanding')
			return <Image src={UnderstandingIcon} className='px-1' />
		if (skill === 'vocabulary')
			return <Image src={VocabularyIcon} className='px-1' />
		if (skill === 'pragmatics')
			return <FontAwesomeIcon className='px-1' style={{color: '#808080'}} icon={faBrain} />
		else return <></>
	}


	const getDuration = (attempt) => {
		const endTime = new Date(attempt.end).getTime()/1000
		const startTime = new Date(attempt.start).getTime()/1000
		return timer.getTimeFormatted(Math.floor(endTime - startTime))
	}

	console.log(filteredPatientStatistics)

	return (
		<>
			{filteredPatientStatistics.length === 0 ? (
				<Alert
					className='mt-1'
					variant='success'
					data-cy='single_exercise_statistic_no_data'
				>
					{t('patient_statistics.table.no_data_single_exercise')}
				</Alert>
			) : (
				filteredPatientStatistics.map((stat, index) => (
					<div key={index} className='mb-3'>
						<Row xs='auto'>
							<Col className='mb-3' >
								<span style={{fontWeight: 'bold' }} data-cy='single_exercise_statistic_title'>
									{stat.exercise.text}
								</span>
							</Col>
							<Col>
								{getTypeIcon(stat.answerFormat)}
							</Col>
							{!patientIsAdult && (
								<Col>
									{getSkillIcon(stat.skill[0])}
								</Col>
							)}
						</Row>
						<Table
							striped
							hover
							responsive
						>
							<thead data-cy='single_exercise_statistic_table'>
								<tr>
									<th>{t('patient_statistics.table.header_date')}</th>
									<th>{t('patient_statistics.table.header_duration')}</th>
									<th>{t('patient_statistics.table.header_completed')}</th>
									<th>{t('patient_statistics.table.header_stats')}</th>
								</tr>
							</thead>
							<tbody data-cy='single_exercise_statistic_table'>
								{stat.attempts.map(attempt => (
									<tr key={attempt.id}>
										<td>
											{new Date(attempt.start).toLocaleDateString('en-GB')}
										</td>
										<td>{getDuration(attempt)}</td>
										<td>
											{attempt.completed
												? t('edit_patient.exercises.table.yes')
												: t('edit_patient.exercises.table.no')}
										</td>
										<td>
											{attempt.answers.filter(answer => answer.correct).length} / {stat.numQuestions}
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				))
			)}
		</>
	)
}

export default SingleExerciseStatisticsTable
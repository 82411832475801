import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'stateHandling/hooks'
import { changeLibraryTab } from 'reducers/previousPageSlice'


import LanguageIcon from '@mui/icons-material/Language'


const ExerciseLibraryTabs = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const tabIndex = useAppSelector(state => state.previousPage.libraryTab)

	return (
		<Tabs
			defaultActiveKey={tabIndex}
			className='mb-3'
			onSelect={(idx) => dispatch(changeLibraryTab(idx!))}
			data-cy='exercise-library-tabs'
		>
			<Tab eventKey='0' title={t('edit_exercise.tabs.0.title')}/>
			<Tab eventKey='1' title={t('edit_exercise.tabs.1.title')}/>
			<Tab eventKey='2'
				title={
					<span>
						{t('edit_exercise.tabs.2.title')}
						<LanguageIcon className='mx-2 pb-1'/>
					</span>
				}
			/>
		</Tabs>
	)
}

export default ExerciseLibraryTabs
import axios from 'axios'
import qs from 'qs'
import { getAuthHeader } from 'utils/authHelper'

const baseUrl = '/api/statistics'

axios.interceptors.request.use(async function (config) {
	const header = await getAuthHeader()
	config.headers = header
	return config
})

/**
 * POST request to register a new user
 * @param {Object} statistic
 * @returns {Object} The saved statistics object
 */
const postStatistic = async statistic => {
	const res = await axios.post(`${baseUrl}/statisticsObject`, statistic)
	return res.data
}


/**
 * GET request to get user with specific MongoDB id
 * @param {string} id The patient cognito id
 * @returns {Array} Array of statistics objects for the user
 */
const getStatsForUser = async cognitoId => {
	const res = await axios.get(`${baseUrl}/statistics`, {
		params: {
			cognitoId: cognitoId
		},
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' })
		}
	})
	return res.data
}

export default {
	postStatistic,
	getStatsForUser
}
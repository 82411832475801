import React, { useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'

interface Props {
	handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
}
const ExitScenarioButton = ({handleClick}:Props) => {

	const [show, setShow] = useState(false)
	const handleShow = () => setShow(true)

	const {t} = useTranslation()

	const handleClose = () => {
		setShow(false)
	}

	return (
		<>
			<Button variant='danger' className='exercise--close-btn' onClick={handleShow}>
				<FontAwesomeIcon className='exercise--header-info' icon={faX} />
			</Button>
			<Modal show={show} onHide={() => handleClose}>
				<Modal.Header>
					<Modal.Title>Är du säker på att du vill avsluta konversationen? Alla nuvarande framsteg kommer förloras.</Modal.Title>
				</Modal.Header>
				<Modal.Footer>
					<Button variant='secondary' onClick={() => handleClose()}>Stäng</Button>
					<Button variant='danger' onClick={handleClick} name='home'>Avsluta konversation</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ExitScenarioButton
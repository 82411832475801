import React, { Fragment, ChangeEvent} from 'react'
import { DBStatistic } from 'types/StatisticTypes'
import ExerciseLibraryCard from 'components/ExerciseLibraryCard/ExerciseLibraryCard'
import { LibraryExercise } from 'types/ExerciseTypes'
import SingleExerciseStatisticsModal from 'components/Statistics/SingleExerciseStatisticsModal'

interface Props {
    exercises: LibraryExercise[]
    baseLib: boolean
	viewExercise: (id: string, renderType: string, baseLib: boolean) => any
    startExercise: (id: string, renderType: string) => void
	assign: boolean
	selectedExercises: LibraryExercise[]
	exerciseSelect: (event: ChangeEvent<HTMLInputElement>, exercise: LibraryExercise, renderType: string) => void
	showPlay: boolean
	showStatistics: boolean
	filteredPatientStatistics: DBStatistic[]
	toggleShowStatistics: (exerciseId: string) => void
	handleCloseStatisticsModal: () => void
}

const ExerciseLibraryCardGroup = (
	{ exercises, baseLib, viewExercise, startExercise, assign, selectedExercises, exerciseSelect, showPlay,showStatistics, filteredPatientStatistics, toggleShowStatistics,handleCloseStatisticsModal}
: Props) => {

	return (
		<div className={`exercise-library--card-group ${assign ? 'mb-5' : 'mb-3'}`}
			data-cy='exercise_library_card_group'
		>
			<SingleExerciseStatisticsModal
				showStatistics={showStatistics}
				handleCloseStatisticsModal={handleCloseStatisticsModal}
				filteredPatientStatistics={filteredPatientStatistics}
			/>
			{exercises.map((exercise, i) => {
				return <Fragment key={`${exercise.id}_${i}`}>

					<ExerciseLibraryCard
						key={`${exercise.id}_${i}`}
						exercise={exercise}
						idx={i.toString()}
						baseLib={exercise.baselib ? exercise.baselib : baseLib}
						viewExercise={viewExercise}
						startExercise={startExercise}
						assign={assign}
						selectedExercises={selectedExercises}
						exerciseSelect={exerciseSelect}
						showPlay={showPlay}
						toggleShowStatistics={toggleShowStatistics}
					/>
				</Fragment>
			})}
		</div>
	)
}

export default ExerciseLibraryCardGroup
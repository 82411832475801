import React from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, Title } from 'chart.js'
import { useTranslation } from 'react-i18next'

// Register required components from Chart.js
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, Title)

interface DataPoint {
  month: string;
  count: number;
}

interface Props {
  data: DataPoint[];
}

const AllTimeLineChart = ({ data }: Props) => {
	// Extract months and counts for the chart
	const labels = data.map(item => item.month)
	const counts = data.map(item => item.count)
	const { t } = useTranslation()

	const chartData = {
		labels: labels,
		datasets: [
			{
				label: t('patient_statistics.bar_chart.completed_attempts'),
				data: counts,
				backgroundColor: 'rgba(158, 119, 237, 0.2)', // Semi-transparent background color
				borderColor: 'rgba(158, 119, 237, 1)', // Solid border color
				fill: true, // Fill area under the line
				tension: 0.1 // Smooth lines
			},
		],
	}

	const chartOptions = {
		responsive: true,
		scales: {
			x: {
				title: {
					display: true,
				},
				grid: {
					display: false, // Hide grid lines on the x-axis
				},
			},
			y: {
				title: {
					display: true,
				},
				ticks: {
					callback: function (value: number) {
						return Number.isInteger(value) ? value : '' // Show only integer values on the y-axis
					},
				},
				grid: {
					display: false, // Hide grid lines on the y-axis
				},
			},
		},
		plugins: {
			legend: {
				display: false,
				position: 'top' as const, // Ensure 'position' is a valid Chart.js position
				onClick: null // Disable legend item click to toggle datasets visibility on and off , add back if we want to show more data in the same chart
			},
			tooltip: {
				callbacks: {
					label: function (context: any) {
						return `${context.dataset.label}: ${context.raw}`
					},
				},
			},
			title: {
				display: false,
				position: 'bottom' as const, // Ensure 'position' is a valid Chart.js position
				text: t('patient_statistics.line_chart.title'), // Set the title text using translation
				font: {
					family: 'Helvetica Neue, Helvetica, Arial, sans-serif',
					size: 18,
					weight: 'bold',
				},
				padding: {
					top: 5,
					bottom: 30,
				},
			},
		},
		layout: {
			padding: {
				top: 20,
				bottom: -10,
				left: 20,
				right: 20
			}
		},
		elements: {
			point: {
				radius: 4,
				hoverRadius: 6
			}
		},
	}

	return (
		<>
			<Line data-cy='statistics_line_chart' style={{ marginTop: '10px'}} data={chartData} options={chartOptions as any} />
			<h6 data-cy='statistics_line_chart_title' style={{ fontWeight: 'bold', textAlign:'center' }}>{t('patient_statistics.line_chart.title')}</h6>
		</>
	)
}

export default AllTimeLineChart

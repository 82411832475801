import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getPage, clearIds, clearPageNr, setSearchCategory, setSearchTerm } from 'reducers/imageSlice'
import { getLanguageCode} from 'utils/helpers'

const SelectSearchCategory = () => {
	const { t,i18n } = useTranslation()
	const category = useSelector(state => state.image.search.category)
	const dispatch = useDispatch()

	/**
     * Responsible for handling the search for different categories
     * @param {Event} event - form event
     */
	const handleCategoryChange = async (event) => {
		event.preventDefault()
		const value = event.target.value
		dispatch(setSearchTerm(''))
		dispatch(setSearchCategory(value))
		dispatch(clearIds())
		dispatch(clearPageNr())
		dispatch(getPage({
			nextPageBool: true,
			searchCategory: value,
			searchTerms: '',
			langCode:getLanguageCode(i18n.language)}))
	}

	return (
		<Form.Group className='w-50'>
			<Form.Text>{t('search_images.category_search')}</Form.Text>
			<Form.Select
				className="addImage--select"
				name='category'
				onChange={handleCategoryChange}
				value={category}
				data-cy='image-category-search'
			>
				<option value='all' data-cy='image-category-search-option'>{t('add_image.all')}</option>
				<option value='adjectives' data-cy='image-category-search-option'>{t('add_image.adjectives')}</option>
				<option value='animals' data-cy='image-category-search-option'>{t('add_image.animals')}</option>
				<option value='feelings' data-cy='image-category-search-option'>{t('add_image.feelings')}</option>
				<option value='food' data-cy='image-category-search-option'>{t('add_image.food')}</option>
				<option value='home' data-cy='image-category-search-option'>{t('add_image.home')}</option>
				<option value='indoor' data-cy='image-category-search-option'>{t('add_image.indoor')}</option>
				<option value='objects' data-cy='image-category-search-option'>{t('add_image.objects')}</option>
				<option value='outdoor' data-cy='image-category-search-option'>{t('add_image.outdoor')}</option>
				<option value='people' data-cy='image-category-search-option'>{t('add_image.people')}</option>
				<option value='verbs' data-cy='image-category-search-option'>{t('add_image.verbs')}</option>
				<option value='work' data-cy='image-category-search-option'>{t('add_image.work')}</option>
				<option value='other' data-cy='image-category-search-option'>{t('add_image.other')}</option>
			</Form.Select>
		</Form.Group>
	)
}

export default SelectSearchCategory
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend,Title } from 'chart.js'
import dayjs from 'dayjs'
import { TimeInterval }  from 'types/StatisticTypes'

// Register required components from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title)

interface Props {
  data: number[]
  interval: TimeInterval
  resetDate: string
}

const ExerciseChart = ({ data, interval, resetDate }: Props) => {

	const { t } = useTranslation()

	const daysInMonth = data.length
	const daysInWeek = 7

	const [titleString, setTitleString] = useState('')

	useEffect(() => {
		if (interval === 'weekly'){
			const weekNumber = dayjs().isoWeek() // Get the current week number
			setTitleString(t('patient_statistics.bar_chart.title_week') + ` ${weekNumber}` + ')')
		} else if (interval === 'monthly'){
			const currentMonthNumeric = dayjs().month()
			setTitleString(t('patient_statistics.bar_chart.title_month') + ' '
			+ t(`patient_statistics.bar_chart.months.${currentMonthNumeric}`))
		} else if (interval === 'period'){
			setTitleString(t('patient_statistics.bar_chart.title_period') + ' '
			+ resetDate.substring(0,10))
		}

	}, [interval])

	const generateLabels = () => {
		if (interval === 'weekly') {
			return Array.from({ length: daysInWeek }, (_, i) => t(`patient_statistics.bar_chart.weekdays.${i}`))
		} else if (interval === 'monthly') {
			return Array.from({ length: daysInMonth }, (_, i) =>`${t('patient_statistics.bar_chart.day')} ${i + 1}`)
		} else if (interval === 'period') {
			return Array.from({ length: getPeriodLength() }, (_, i) =>`${t('patient_statistics.bar_chart.day')} ${i + 1}`)
		}
		return []
	}

	const getPeriodLength = () => {
		const today = dayjs()
		const startDay = dayjs(resetDate)
		const daysInPeriod = today.diff(startDay, 'days')
		return daysInPeriod+1 // +1 to include the current day
	}


	const chartData = {
		labels: generateLabels(),
		datasets: [
			{
				label: t('patient_statistics.bar_chart.completed_attempts'),
				data: data,
				backgroundColor: 'rgba(158, 119, 237)', // Adjust color for bar chart
				borderColor: 'rgba(158, 119, 237, 0.2)', // Border color for bars 158, 119, 237, 0.2)
				borderWidth: 1, // Width of the border for bars
			},
		],
	}

	const chartOptions = {
		responsive: true,
		scales: {
			x: {
				beginAtZero: true,
				title: {
					display: true,
				},
				grid: {
					display: false, // Hide grid lines on the x-axis
				},
			},
			y: {
				beginAtZero: true,
				title: {
					display: true
				},
				ticks: {
					callback: function (value: number) {
						return Number.isInteger(value) ? value : '' // Show only integer values on the y-axis
					},
				},
				grid: {
					display: false, // Hide grid lines on the y-axis
				},
			},
		},
		plugins: {
			legend: {
				display: false, // Hide the legend
				position: 'top' as const, // Ensure 'position' is a valid Chart.js position
				onClick: null // Disable legend item click to toggle datasets visibility on and off, , add back if we want to show more data in the same chart
			},
			tooltip: {
				callbacks: {
					label: function (context: any) {
						return `${context.dataset.label}: ${context.raw}`
					},
				},
			},
			title: {
				display: false,
				text: titleString, // Set the title text using translation
				font: {
					family: 'Helvetica Neue, Helvetica, Arial, sans-serif',
					size: 18,
					weight: 'bold',
				},
				padding: {
					top: 10,
					bottom: 30,
				},
			},
		},
	}

	return (
		<>
			<Bar data-cy='statistics_bar_chart' data={chartData} options={chartOptions as any} />
			<h6 data-cy='statistics_bar_chart_title' style={{ fontWeight: 'bold', marginBottom: '30px', marginTop: '10px', textAlign:'center' }}>{titleString}</h6>
		</>
	)
}

export default ExerciseChart

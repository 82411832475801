import React from 'react'
import { faComment, faPen, faHeadphones, faBook, faQuestion}
	from '@fortawesome/free-solid-svg-icons'

import { DBExercise } from 'types/ExerciseTypes'
import ExerciseCardPatient from 'components/ExerciseCard/ExerciseCardPatient'
import './ExerciseCard.css'

interface Props {
   exercise: DBExercise,
   clickEvent: (id: string) => void
   numQuestions: number,
   answerFormat: string,
   idx: string
}

const ExerciseCard = ({ exercise, clickEvent, numQuestions, answerFormat, idx }: Props) => {

	const getIcon = () => {
		let icon
		switch (answerFormat) {
		case 'speak':
			icon = faComment
			break
		case 'write':
			icon = faPen
			break
		case 'listen':
			icon = faHeadphones
			break
		case 'read':
			icon = faBook
			break
		default:
			icon = faQuestion
			console.error('Unknown answer format!')
		}
		return icon
	}

	return (
		<ExerciseCardPatient
			exercise={exercise}
			idx={idx}
			id={exercise.template + '_' + answerFormat + '_' + idx}
			answerFormat={answerFormat}
			numQuestions={numQuestions}
			clickEvent={clickEvent}
			getIcon={getIcon}
		/>
	)
}

export default ExerciseCard
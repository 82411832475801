import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setItemWithExpiry, getItemWithExpiry, removeItemWithExpiry } from 'utils/cookieManager'

const cookieConsent: boolean = getItemWithExpiry('CookieConsent')
const audioProcessingConsent: boolean = getItemWithExpiry('AudioProcessingConsent')

interface appState {
  loading: boolean,
  createLoading: boolean,
  cookieConsent: boolean,
  audioProcessingConsent: boolean
}

const initialState: appState = {
	loading: false,
	createLoading:false,
	cookieConsent: cookieConsent ? cookieConsent : false,
	audioProcessingConsent: audioProcessingConsent ? audioProcessingConsent : false
}

export const appStateSlice = createSlice({
	name: 'appState',
	initialState,
	reducers: {
		changeAppLoading: (state:appState, action:PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		changeCreateLoading: (state:appState, action:PayloadAction<boolean>) => {
			state.createLoading = action.payload
		},
		changeCookieConsent: (state:appState, action:PayloadAction<boolean>) => {
			state.cookieConsent = action.payload
			if (action.payload) {
				setItemWithExpiry('CookieConsent', action.payload)
			} else {
				removeItemWithExpiry('CookieConsent')
			}
		},
		changeAudioProcessingConsent: (state:appState, action:PayloadAction<boolean>) => {
			state.audioProcessingConsent = action.payload
			if (action.payload) {
				setItemWithExpiry('AudioProcessingConsent', action.payload)
			} else {
				removeItemWithExpiry('AudioProcessingConsent')
			}
		}
	},
})

export const { changeAppLoading, changeCreateLoading, changeCookieConsent, changeAudioProcessingConsent } = appStateSlice.actions

export default appStateSlice.reducer
import axios from 'axios'
import { getAuthHeader } from 'utils/authHelper'

const baseUrl = '/api/questions'

axios.interceptors.request.use(async function (config) {
	const header = await getAuthHeader()
	config.headers = header
	return config
})

/**
 * PUT request to update question with new audio
 * @param {FormData} newObject - object containing data for updating
 * @returns {Object} Updated question data
 */
const updateAudio = async (id, data) => {
	const res = await axios.put(`${baseUrl}/audio/${id}`, data)
	return res.data
}

export default { updateAudio }
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { generateQuestionObj, generateFillQuestionObj, itemIsValid, deleteItem } from 'utils/helpers'
import { N_QUESTIONS } from 'utils/config'
import questionService from 'services/questions'
import { DBExercise } from 'types/ExerciseTypes'
import { NewOrEditedQuestion, QuestionImage } from 'types/QuestionTypes'
import { Answer } from 'types/AnswerTypes'

const nQuestions = Number(N_QUESTIONS)

interface NewOrEditedExerciseStatus {
	showAudioMessage: boolean
	audioMessage: string
	showExerciseMessage: boolean
	exerciseMessage: string
	exerciseMessageVariant: string
}

interface AnswerTag {
	i: string
	t: string
}

interface NewImage {
	i: number
	image: QuestionImage
}

interface NewQuestion {
	i: number
	name: string
	value: string
}

interface NewAnswer {
	i: number
	value: string
}

interface NewAlternative {
	i: number
	j: number
	value: Answer
}

interface NewOrEditedQuestions {
	[key: number]: NewOrEditedQuestion
}

interface NewOrEditedAnswers {
	[key: number]: string
}

interface NewOrEditedExerciseState {
	exercise: DBExercise
	tag: string
	nrOfQ: number
	nrOfAlt: number
	questions: NewOrEditedQuestions
	answers: NewOrEditedAnswers
	status: NewOrEditedExerciseStatus
}

export const updateAudio = createAsyncThunk(
	'question/updateAudio',
	async ({ id, userData }: { id: string, userData: any }, thunkAPI) => {
		try {
			const res = await questionService.updateAudio(id, userData)
			return res
		} catch (e) {
			return thunkAPI.rejectWithValue(
				`Could not update audio for question with id ${id}: ${e.response.data}`
			)
		}
	}
)
const initialState: NewOrEditedExerciseState = {
	exercise: {
		title: {
			text: '',
			audio: '',
			slowAudio: ''
		},
		id: '',
		description: {
			slp: {
				text: '',
				audio: '',
				slowAudio: ''
			},
			patient: {
				text: '',
				audio: '',
				slowAudio: ''
			}
		},
		type: [],
		skill:[],
		answerFormat: 'image',
		subtype: [],
		category: 'word',
		isAdult: false,
		template: 'default',
		tags: [],
		difficulty: 0,
		settings: {
			isPublic: false,
			hasImage: true,
			useHint: false,
			shuffleQuestions: true
		},
		questions: [],
		langCode: '',
		owner: '',
		createdAt: ''
	},
	tag: '',
	nrOfQ: nQuestions,
	nrOfAlt: 4,
	questions: generateQuestionObj(nQuestions, 'obj'),
	answers: generateQuestionObj(nQuestions, 'str'),
	status: {
		showAudioMessage: false,
		audioMessage: '',
		showExerciseMessage: false,
		exerciseMessage: '',
		exerciseMessageVariant: 'success'
	},
}


export const newOrEditedExerciseSlice = createSlice({
	name: 'newOrEditedExercise',
	initialState: initialState,
	reducers: {
		changePracticeUserCategory: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			if (action.payload === 'child'){
				state.exercise.isAdult = false
			}
			else if (action.payload === 'adult'){
				state.exercise.isAdult = true
			}
		},
		changeExerciseType: (state:NewOrEditedExerciseState, action:PayloadAction<string[]>) => {
			state.exercise.type = action.payload
		},
		changeExerciseSkill: (state:NewOrEditedExerciseState, action:PayloadAction<string[]>) => {
			state.exercise.skill = action.payload
		},
		changeExerciseAnswerFormat: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.exercise.answerFormat = action.payload
		},
		changeExerciseSubtype: (state:NewOrEditedExerciseState, action:PayloadAction<string[]>) => {
			state.exercise.subtype = action.payload
		},
		changeExerciseCategory: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.exercise.category = action.payload
		},
		changeExerciseTemplate: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.exercise.template = action.payload
			if (action.payload === 'fill') {
				state.questions = generateFillQuestionObj(nQuestions)
			} else if (action.payload === 'default') {
				state.questions = generateQuestionObj(nQuestions)
			}
			if (action.payload === 'comprehension' && state.exercise.answerFormat === 'image') {
				Object.keys(state.questions).map(qId => {
					state.questions[qId].answers = new Array(4).fill({ answer: { text: '' } })
				})
			}
		},
		changeExerciseTags: (state:NewOrEditedExerciseState, action:PayloadAction<string[]>) => {
			state.exercise.tags = action.payload
		},
		changeExerciseTag: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.tag = action.payload
		},
		changeDifficulty: (state:NewOrEditedExerciseState, action:PayloadAction<number>) => {
			state.exercise.difficulty = action.payload
		},
		changeNrOfComprehensionQ: (state:NewOrEditedExerciseState, action:PayloadAction<number>) => {
			const oldNrQ = state.nrOfQ
			state.nrOfQ = action.payload
			const diff = Number(action.payload) - Number(oldNrQ)
			if (diff > 0) {
				// We add more questions
				for (let i = oldNrQ+1; i <= Number(action.payload); i++) {
					state.questions = { ...state.questions,
						[i]: {
							question: { text: '' },
							answers: new Array(state.nrOfAlt).fill({ answer: { text: '' } }),
							edited: true
						}
					}
				}
			} else if (diff < 0) {
				// We remove questions
				for (let i = oldNrQ; i > Number(action.payload); i--) {
					delete state.questions[i]
					delete state.answers[i]
				}
			}
		},
		changeNrOfQ: (state:NewOrEditedExerciseState, action:PayloadAction<number>) => {
			const oldNrQ = state.nrOfQ
			state.nrOfQ = action.payload
			const diff = action.payload - Number(oldNrQ)
			if (diff > 0) {
				// We add more questions
				for (let i = oldNrQ+1; i <= action.payload; i++) {
					state.questions = { ...state.questions,
						[i]: {
							question: { text: '' },
							answers: [],
							edited: true
						}
					}
					state.answers[i] = ''
				}
			} else if (diff < 0) {
				// We remove questions
				for (let i = oldNrQ; i > action.payload; i--) {
					delete state.questions[i]
					delete state.answers[i]
				}
			}
		},
		//When changing answerFormat, questions are kept but nothing else. The number of alternatives change, all alternatives are empty.
		changeNrOfAlt: (state:NewOrEditedExerciseState, action:PayloadAction<number>) => {
			const nrOfAlt = action.payload
			state.questions = {...state.questions}
			Object.keys(state.questions).map(qId => {
				state.questions[qId].answers = new Array(nrOfAlt).fill({ answer: { text: '' } })
			})
			state.nrOfAlt = nrOfAlt
		},
		setYesNoAlt: (state:NewOrEditedExerciseState) => {
			state.questions = {...state.questions}
			Object.keys(state.questions).map(qId => {
				state.questions[qId].answers = [
					{ answer: { text: 'yes' } },
					{ answer: { text: 'no' } }
				]
			})
			state.nrOfAlt = 2
		},
		addAnswer: (state:NewOrEditedExerciseState, action:PayloadAction<AnswerTag>) => {
			const i = action.payload.i
			const newAnswer = action.payload.t
			const currentAnswers = state.questions[i].answers || []
			if (itemIsValid(currentAnswers.map(answerObj => answerObj.answer.text), newAnswer)) {
				currentAnswers.push({ answer: { text: newAnswer } })
				state.questions = { ...state.questions,
					[i]: { ...state.questions[i], answers: currentAnswers, 'edited': true }
				}
			}
			state.answers[i] = ''
		},
		deleteAnswer: (state:NewOrEditedExerciseState, action:PayloadAction<AnswerTag>) => {
			const i = action.payload.i
			const tag = action.payload.t
			const remainingAnswers = deleteItem(state.questions[i].answers, tag)
			state.questions = { ...state.questions,
				[i]: { ...state.questions[i], answers: remainingAnswers, 'edited': true }
			}

		},
		changeImage: (state:NewOrEditedExerciseState, action:PayloadAction<NewImage>) => {
			const image = action.payload.image
			const i = action.payload.i
			state.questions = { ...state.questions,
				[i]: { ...state.questions[i],
					imageURL: image.url,
					edited: true,
					image: { url: image.url, name: image.url }
				}
			}
		},
		changeTitle: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.exercise.title.text = action.payload
		},
		changeSlpDescription: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.exercise.description.slp.text = action.payload
		},
		changePatientDescription: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.exercise.description.patient.text = action.payload
		},
		changeQuestions: (state:NewOrEditedExerciseState, action:PayloadAction<NewQuestion>) => {
			if (action.payload.name === 'question') {
				state.questions = { ...state.questions,
					[action.payload.i]: {
						...state.questions[action.payload.i],
						[action.payload.name]: { text: action.payload.value },
						edited: true
					}
				}
			} else {
				state.questions = { ...state.questions,
					[action.payload.i]: {
						...state.questions[action.payload.i],
						[action.payload.name]: action.payload.value,
						edited: true
					}
				}
			}

		},
		changeAnswersInput: (state:NewOrEditedExerciseState, action:PayloadAction<NewAnswer>) => {
			// The string value that is currently in the answers-field
			const i = action.payload.i
			state.answers[i] = action.payload.value
			state.questions = { ...state.questions,
				[i]: { ...state.questions[i], edited: true }
			}
		},
		changeAlternativesInput: (state:NewOrEditedExerciseState, action:PayloadAction<NewAlternative>) => {
			const { i, j, value } = action.payload

			const updatedQuestions = {...state.questions}
			const updatedAnswers = [...updatedQuestions[i].answers]

			updatedAnswers[j] = value

			updatedQuestions[i] = {
				...updatedQuestions[i],
				answers: updatedAnswers,
				edited: true,
			}

			return {
				...state,
				questions: updatedQuestions,
			}
		},
		setEditExercise: (state:NewOrEditedExerciseState, action:PayloadAction<DBExercise>) => {
			const exercise = action.payload
			state.exercise = exercise
			const n = exercise.questions.length
			state.nrOfQ = n
			const questionObj = {}

			for (let i = 1; i <= n; i++) {
				const q = exercise.questions[i - 1]
				q['image'] = { 'url': q.imageURL, 'name': q.imageURL }
				q['edited'] = false
				questionObj[i] = q
			}
			state.questions = questionObj
		},
		reset: (state:NewOrEditedExerciseState) => {
			state.exercise = initialState.exercise
			state.tag = initialState.tag
			state.questions = initialState.questions
			state.answers = initialState.answers
		},
		resetAnswers: (state:NewOrEditedExerciseState) => {
			const updatedQuestions = { ...state.questions }
			Object.keys(updatedQuestions).forEach((key) => {
				updatedQuestions[key].answers = []
			})
			state.questions = updatedQuestions
		},
		changeHasImage: (state:NewOrEditedExerciseState) => {
			state.exercise.settings.hasImage = !state.exercise.settings.hasImage
			if (!state.exercise.settings.hasImage) {
				for (let i = 1; i <= state.nrOfQ; i++) {
					state.questions = { ...state.questions,
						[i]: { ...state.questions[i],
							edited: true,
							imageURL: undefined,
							image: { url: undefined, name: undefined }
						}
					}
				}
			}
		},
		changeUsingHint: (state:NewOrEditedExerciseState) => {
			state.exercise.settings.useHint = !state.exercise.settings.useHint
		},
		changeIsPublic: (state:NewOrEditedExerciseState) => {
			state.exercise.settings.isPublic = !state.exercise.settings.isPublic
		},
		changeShuffleQuestions:  (state:NewOrEditedExerciseState) => {
			state.exercise.settings.shuffleQuestions = !state.exercise.settings.shuffleQuestions
		},
		changeExerciseMessage: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.status.showExerciseMessage = true
			state.status.exerciseMessage = action.payload
		},
		changeExerciseMessageVariant: (state:NewOrEditedExerciseState, action:PayloadAction<string>) => {
			state.status.exerciseMessageVariant = action.payload
		},
		resetExerciseMessage: (state:NewOrEditedExerciseState) => {
			state.status.showExerciseMessage = false
			state.status.exerciseMessage = ''
			state.status.exerciseMessageVariant = 'success'
		}
	},
	extraReducers: (builder) => {
		builder.addCase(updateAudio.fulfilled, (state:NewOrEditedExerciseState, action:PayloadAction<any>) => {
			// console.log(`Updated audio for question with id: ${action.payload.id}`)
		})
		builder.addCase(updateAudio.rejected, (state:NewOrEditedExerciseState, action:PayloadAction<any>) => {
			console.error(action.payload)
			state.status.showAudioMessage = true
			state.status.audioMessage = `Could not update or create audio for question with id: ${action.payload.id}`
		})
	}
})

export const {
	changePracticeUserCategory,
	changeExerciseType,
	changeExerciseSkill,
	changeExerciseAnswerFormat,
	changeExerciseSubtype,
	changeExerciseCategory,
	changeExerciseTemplate,
	changeExerciseTags,
	changeExerciseTag,
	changeDifficulty,
	changeNrOfQ,
	changeNrOfComprehensionQ,
	changeNrOfAlt,
	setYesNoAlt,
	addAnswer,
	deleteAnswer,
	changeImage,
	changeTitle,
	changeSlpDescription,
	changePatientDescription,
	changeQuestions,
	changeAnswersInput,
	changeAlternativesInput,
	setEditExercise,
	reset,
	resetAnswers,
	changeHasImage,
	changeUsingHint,
	changeIsPublic,
	changeShuffleQuestions,
	changeExerciseMessage,
	resetExerciseMessage,
	changeExerciseMessageVariant
} = newOrEditedExerciseSlice.actions

export default newOrEditedExerciseSlice.reducer
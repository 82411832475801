import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Badge, Image } from 'react-bootstrap'
import { AWS_URL } from 'utils/config'
import { useAppSelector } from 'stateHandling/hooks'

const ComprehensionQuestionsTable = () => {
	const { t } = useTranslation()
	const questions = useAppSelector(state => state.newOrEditedExercise.questions)

	return (
		<>
			<Table striped hover responsive>
				<thead>
					<tr>
						<th>#</th>
						<th>{t('preview_exercise.table.question')}</th>
						<th>{t('preview_exercise.table.image')}</th>
						<th>{t('preview_exercise.table.correct_alternative')}</th>
						<th>{t('preview_exercise.table.wrong_alternative')}</th>
					</tr>
				</thead>
				<tbody>
					{/* TODO: change from any */}
					{Object.values(questions).map((q: any, i) => {
						return (
							<tr key={i}>
								<td>{i+1}</td>
								<td>
									{q.question.text}
								</td>
								<td>
									{q.imageURL && q.imageURL !== 'undefined'
										? <Image
											className='images--image'
											src={AWS_URL + q.imageURL}
											width='150'
										/>
										: <p>{t('preview_exercise.table.no_image')}</p>
									}
								</td>
								<td>
									{q.answers.map((answerObj, j) =>
										j === 0 ? (
											q.answerFormat !== 'image' ? (
												<Badge key={i+j} className='m-1' bg='secondary'>
													{answerObj.answer.text}
												</Badge>
											) : (
												<Image
													key={i+j}
													className='images--image'
													src={AWS_URL + answerObj.answer.text}
													width='150'
												/>
											)
										) : <></>
									)}
								</td>
								<td>
									{q.answers.map((answerObj, j) =>
										j !== 0 ? (
											q.answerFormat !== 'image' ? (
												<Badge key={i+j} className='m-1' bg='secondary'>
													{answerObj.answer.text}
												</Badge>
											) : (
												<Image
													key={i+j}
													className='images--image'
													src={AWS_URL + answerObj.answer.text}
													width='150'
												/>
											)
										) : <></>
									)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</>
	)
}

export default ComprehensionQuestionsTable
import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col} from 'react-bootstrap'
import ExitScenarioButton from 'components/Chatbot/ExitScenarioButton'

interface Props {
	handleCloseChat: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
}

const Header = ({ handleCloseChat }:Props) => {

	const turn = useAppSelector(state => state.chatbot.scenarioSettings.currentTurn)
	const maxTurns = useAppSelector(state => state.chatbot.scenarioSettings.maxTurns)
	const scenarioTitle = useAppSelector(state => state.chatbot.currentScenario.title)

	
	return (
			<Row className='align-items-center p-2 pt- chatbot--header exercise--header-bg'>
				<Col xs={2} sm={1} className='d-flex justify-content-center align-items-center'>
					<ExitScenarioButton handleClick={handleCloseChat}></ExitScenarioButton>
				</Col>
				<Col xs={8} sm={10} className='d-flex justify-content-center align-items-center chatbot--header-title'>
					<div>{scenarioTitle}</div>
				</Col>
				<Col xs={2} sm={1} className='text-end'>
					<div>{turn} / {maxTurns}</div>
				</Col>
			</Row>
	)
}

export default Header
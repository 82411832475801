import React from 'react'

 
interface Props {
	message: string
  }

const UserMessage = ({ message }:Props) => {
	return (
		<div className="chatbot--user-message">
			{message}
		</div>
	)
}

export default UserMessage
import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import UserMessage from 'components/Chatbot//UserMessage'
import AIMessage from 'components/Chatbot/AIMessage'
import Confirmation from 'components/Chatbot/Confirmation'
import Header from 'components/Chatbot/Header'
import { Row, Col } from 'react-bootstrap'

interface Message {
	role: 'USER' | 'AI'
	content: string
  }

interface Props {
	conversation: Message[];
	audioBuffer: Uint8Array
	isThinking: boolean
	message: string
	showConfirmation: boolean
	autoplay: boolean
	handleConfirmation: (type:boolean) => void
	handleCloseChat: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
}


const Chat = ({
	conversation,
	audioBuffer,
	isThinking,
	message,
	showConfirmation,
	autoplay,
	handleConfirmation,
	handleCloseChat
}:Props) => {

	const useSpeech = useAppSelector(state => state.chatbot.scenarioSettings.useSpeech)

	const renderConfirmation = () => {
		return (
			<>
				<div>
					<AIMessage
						message={conversation[conversation.length - 1].content}
						audioBuffer={audioBuffer}
						isThinking={isThinking}
						showConfirmation={showConfirmation}
						autoplay={autoplay}
					/>
				</div>
				<Confirmation message={message} handleConfirmation={handleConfirmation} />
			</>
		)
	}

	const renderConversation = () => {
		return (
			<div>{conversation.slice(-2).map((turn, i) => {
				return (
					<Row key={i}>
						{turn['role'] === 'USER'
							? <Col md={11} className='align-self-end'>
								<UserMessage message={turn.content} />
							</Col>
							: <Col md={11} className='align-self-start'>
								<AIMessage
									message={turn.content}
									audioBuffer={audioBuffer}
									isThinking={isThinking}
									showConfirmation={showConfirmation}
									autoplay={autoplay}
								/>
							</Col>
						}
					</Row>
				)
			})}
			</div>
		)
	}
	return (
		<div>
			<Header
				handleCloseChat={handleCloseChat}
			/>
			<Row className='justify-content-center chatbot--chat-container'>
				<Col className='align-self-center' xs={12} md={10} lg={10} xl={10}>
					{showConfirmation && useSpeech
						? renderConfirmation()
						: renderConversation()
					}
				</Col>
			</Row>
		</div>
	)
}

export default Chat
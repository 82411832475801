import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { Badge, Container, Image, Form, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import SelectCategory from 'components/SelectCategory'
import { AWS_URL } from 'utils/config'
import { getLanguageCode, itemIsUnique } from 'utils/helpers'
import { updateImage, deleteCurrentEditImageTag, setCurrentEditImageCategory, setCurrentEditImageTags, deleteImage } from 'reducers/imageSlice'

const ImageEditCard = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const image = useSelector(state => state.image.currentEditImage)
	const allTags = useSelector(state => state.image.currentEditImage.tags)

	const [tag, setTag] = useState('')
	const [tags, setTags] = useState([])

	const [langCode, setLangCode] = useState(getLanguageCode(i18n.language))

	/**
     * Function to delete image from database and S3
     */
	const deleteHandler = () => {
		const imageId = image.id
		const response = prompt(t('images.delete_prompt', {imageId}))
		if (response === 'yes') {
			dispatch(deleteImage(imageId))
		}
	}

	/**
     * Filter out the tags for the selected language (the tags to be displayed)
     */
	useEffect(() => {
		const tags = allTags.filter(t => t.langCode === langCode).map(t => t.tags)[0]
		setTags(tags ? tags : [])
	}, [allTags, langCode])



	/**
     * Responsible for handling events on image specific fields
     * @param {Event} event - form event
     */
	const handleChange = (event) => {
		const name = event.target.name
		const value = event.target.value
		if (name === 'lang') {
			setLangCode(value)
		} else if (name === 'tag') {
			setTag(value.toLowerCase())
		} else if (name === 'category') {
			dispatch(setCurrentEditImageCategory(value))
		}
	}

	/**
     * Clicking enter creates a new tag, so we listen for this. Also some sanitary checks are performed
     * @param {Event} event - event intance for onKeyDown event
     */
	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			event.preventDefault()
			if (tag.trim().length > 0 && itemIsUnique(tags, tag)) {
				dispatch(setCurrentEditImageTags({langCode, tags: [tag]}))
				setTag('')
			}
		}
	}

	/**
     * Function run when the save button is pressed
     * the data that is in redux currentEditImage and any value in tags-field gets saved
     */
	const saveChanges = () => {
		if (tag.trim().length > 0 && itemIsUnique(tags, tag)) {
			dispatch(setCurrentEditImageTags({langCode, tags: [tag]}))
		}
		setTag('')
		const update = {
			category: image.category,
			tags: image.tags
		}
		dispatch(updateImage(update))
	}

	/**
     * Deletes the selected tag from the tag array
     * @param {String} tag - tag to be deleted
     * @returns 
    */
	const deleteTag = tag => () => {
		dispatch(deleteCurrentEditImageTag({tags: [tag], langCode}))
	}

	return (
		<span className='images--card-container' key={image.id}>
			<Image
				className='images--image'
				src={AWS_URL + image.url}
				width='300'
			/>
			<Container>
				<div>{t('images.id')} {image.id}</div>
				<div>{t('images.name')} {image.name}</div>
				<SelectCategory
					handleChange={handleChange}
					category={image.category}
				/>
				<div>
					{t('images.language')}
					<Form.Select
						name='lang'
						className="menu--language-select"
						onChange={handleChange}
						value={langCode}
						data-cy='edit-image-lang-select'
					>
						<option value="sv-SE">Svenska</option>
						<option value="en-US">English</option>
						<option value="fi-FI">Suomi</option>
					</Form.Select>
					{t('images.add_tags')}
					<Form.Control
						name='tag'
						type='text'
						onChange={handleChange}
						onKeyDown={handleKeyDown}
						value={tag}
						data-cy='edit-image-add-tag-field'>
					</Form.Control>
					{tags.map(tag =>
						<Badge key={tag} className='p-2 m-1' bg="primary" onClick={deleteTag(tag)} data-cy={`edit-image-tag-${tag}`}>
							{tag} ✕
						</Badge>
					)}
				</div>
				<div className='images--wrapper'>
					<Button onClick={saveChanges} variant='primary' data-cy='edit-image-save-changes'>
						{t('images.save')}
					</Button>
					<Button
						className='images--button-delete'
						onClick={deleteHandler}
						variant='danger'
						data-cy='edit-image-delete'
					>
						{t('images.delete')}
					</Button>
				</div>
			</Container>
		</span>
	)
}

export default ImageEditCard
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'stateHandling/hooks'
import { useNavigate } from 'react-router-dom'
import { getScenarios } from 'services/chatbot'
import ChatbotScenarioList from 'components/Chatbot/ChatbotScenarioList'
import './ChatbotPage.css'
import Loading from 'components/Loading'
import { Container, Row, Col } from 'react-bootstrap'
import { changeScenarios, changeCurrentScenario, changeLoading, changeUseSpeech } from 'reducers/chatbotSlice'

const ChatbotPage = () => {
	const { t } = useTranslation()
	const scenarios = useAppSelector(state => state.chatbot.scenarios)
	const scenariosLoading = useAppSelector(state => state.chatbot.loading)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	/**
   * Callback function in useEffect fetches the scenarios from server so they can be render for the user to choose
   */
	useEffect(() => {
		const fetchData = async () => {
			dispatch(changeLoading(true))
			const scenarioList = await getScenarios()
			dispatch(changeScenarios(scenarioList))
			dispatch(changeLoading(false))
		}
		fetchData()
	}, [])

	/**
	   * Handles user click event of selecting scenario
	   * changes the current scenario in the state
	   * Navigates to scenario page
	   * @param {number} id - id (index) of scenario
	   * @param {String} format - can be speak or write
	   */
	const selectScenario = async (id, format) => {
		format === 'speak' ? dispatch(changeUseSpeech(true)) : dispatch(changeUseSpeech(false))
		dispatch(changeCurrentScenario(scenarios[id]))
		navigate(`/chatbot/scenario/${id}`)
	}

	return (
		<Container fluid className='exercise--container h-100 min-vh-100'>
			<Row xs="auto">
				<Col className='flex-grow-1'>
					<h2>{t('chatbot.page_title')}</h2>
				</Col>
			</Row>
			<hr />
			<Row xs='auto'>
				<Col className='flex-grow-1'>
					<p>{t(`chatbot.page_subtitle`)}</p>
				</Col>
			</Row>
			{scenariosLoading
				? <Loading />
				: <ChatbotScenarioList
						selectScenario={selectScenario}
					/>
			}
		</Container>
	)
}

export default ChatbotPage

import React, { useState, useEffect } from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { useTranslation } from 'react-i18next'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { getFilteredUserStatistics } from 'utils/statisticsHelper'
import { PatientUser } from 'types/UserTypes'

const PatientTotalProgressBar = () => {

	const { t } = useTranslation()
	const filteredExercises = useAppSelector(state => state.exerciseList.filteredExercises)
	const patientStatistics = useAppSelector(state => state.currentPatient.statistics)
	const user = useAppSelector(state => state.user.data) as PatientUser
	const [percentageOfCompletedExercises, setPercentageOfCompletedExercises] = useState(0)
	const [completedLabel, setCompletedLabel] = useState('')
	const [nrOfExerciseCards, setNrOfExerciseCards] = useState(0)
	const [resetDateLabel, setResetDateLabel] = useState('')

	useEffect(() => {
		let formattedDate = user.createdAt.substring(0, 10)
		if (user.settings.resetDate) {
			formattedDate = user.settings.resetDate.substring(0, 10)
		}
		setResetDateLabel(t('total_progress_bar.reset_date',{
			resetDate: formattedDate
		}))
	}, [user.settings.resetDate])

	useEffect(() => {
		if (filteredExercises.length > 0) {
			let nrOfExerciseCards = 0
			filteredExercises.forEach((exercise) => {

				if (exercise.useListening) {nrOfExerciseCards ++}
				if (exercise.useReading) {nrOfExerciseCards ++}
				if (exercise.useWriting) {nrOfExerciseCards ++}
				if (exercise.useSpeaking) {nrOfExerciseCards ++}
			})
			setNrOfExerciseCards(nrOfExerciseCards)
		}
	}, [filteredExercises])

	/**
	 * @param {ExerciseForAssignment} exercise assigned to patients, with some answer formats
	 * @param {String} answerFormat the answerFormat for a statistics object
	 * @returns {Boolean} - true if the answerFormat of the exercise match the answerFormat of the current statistics object
	 */
	const exerciseHasAnswerFormat = (exercise, answerFormat) => {
		return exercise.useSpeaking && answerFormat == 'speak'
		|| exercise.useWriting && answerFormat == 'write'
		|| exercise.useListening && answerFormat == 'listen'
		|| exercise.useReading && answerFormat == 'read'
	}

	useEffect(() => {
		if (patientStatistics) {
			const patientStatisticsArray = Object.values(patientStatistics)

			// Only count statistics for the filtered exercises
			const statisticsByExerciseId = filteredExercises.flatMap((exercise) => {
				const statistics = patientStatisticsArray.filter((statistic) => {
					return statistic.exerciseId === exercise.id
					&& exerciseHasAnswerFormat(exercise, statistic.answerFormat)
				})
				return statistics
			})

			const filteredUserStatistics = getFilteredUserStatistics(statisticsByExerciseId, user.settings.resetDate, false)
			const nrOfCompletedExercises = filteredUserStatistics.length

			if (nrOfExerciseCards !== 0) {
				setPercentageOfCompletedExercises(nrOfCompletedExercises/nrOfExerciseCards * 100)
			} else {
				setPercentageOfCompletedExercises(0)
			}
		}
	}, [patientStatistics, nrOfExerciseCards])

	useEffect(() => {
		const percentageRounded = Math.round(percentageOfCompletedExercises)
		setCompletedLabel(t('total_progress_bar.percentage',{
			percentageRounded: percentageRounded
		}))
	}, [percentageOfCompletedExercises])

	return (
		<>
			<div style={{ display: 'flex', gap: '10px' }} data-cy='practice_user_total_progress_title'>
				<h2 className="exercisePage--completed-label">{completedLabel}</h2>
				<h2 className="exercisePage--progress-subtitle">{resetDateLabel}</h2>
			</div>
			<ProgressBar data-cy='exercise_page_total_progressbar' className='exercisePage--total-progress-bar mb-5'>
				<ProgressBar className='exercisePage--total-progress-bar1'
					now={percentageOfCompletedExercises}
				/>
				<ProgressBar className='exercisePage--total-progress-bar'
					now={100 - percentageOfCompletedExercises}
				/>
			</ProgressBar>

		</>
	)
}

export default PatientTotalProgressBar
import { combineReducers } from 'redux'
import exerciseListReducer from './exerciseListSlice'
import appStateReducer from './appStateSlice'
import currentExerciseReducer from './currentExerciseSlice'
import newOrEditedExerciseReducer from './newOrEditedExerciseSlice'
import userReducer from './userSlice'
import currentPatientReducer from './currentPatientSlice'
import imageReducer from './imageSlice'
import previousPageReducer from './previousPageSlice'
import chatbotReducer from './chatbotSlice'

const rootReducer = combineReducers({
    exerciseList: exerciseListReducer,
    appState: appStateReducer,
    currentExercise: currentExerciseReducer,
    newOrEditedExercise: newOrEditedExerciseReducer,
    user: userReducer,
    currentPatient: currentPatientReducer,
    image: imageReducer,
    previousPage: previousPageReducer,
    chatbot: chatbotReducer
})

export default rootReducer

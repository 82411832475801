import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from 'stateHandling/hooks'
import { useDispatch } from 'react-redux'
import { setExerciseDuration } from 'reducers/currentExerciseSlice'
import timer from 'timer/index'

interface Props {
	handleExerciseLifecycle:(type:string) => void
}

// ExitButton is a ReactFunctionalComponent -> React.FC
const ExitButton = ({ handleExerciseLifecycle }: Props) => {
	const [show, setShow] = useState(false)

	// Type is implicitly inferred
	const previousPage = useAppSelector(state => state.previousPage.path)
	const startTime = useAppSelector(state => state.currentExercise.stats.timer.start)
	const exerciseLoading = useAppSelector(state => state.currentExercise.loading)

	const dispatch = useDispatch()

	const handleShow = () => setShow(true)

	// Did not understand how to explicitly type the 't' variable , but you can view type when hovering
	// Might have to config i18next somehow
	// https://react.i18next.com/latest/typescript
	const {t} = useTranslation()

	const handleClose = (close:boolean) => {
		setShow(false)
		if (close) handleExerciseLifecycle('discontinue')
	}

	useEffect(() => {
		// To prevent page from navigating to previous page
		// Documentation on pushState: https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
		// More on the solution: https://stackoverflow.com/questions/47095827/how-to-show-modal-box-on-back-button-in-react-router-v4
		history.pushState(null, document.title, location.href)

		// On 'back' click in browser
		window.addEventListener('popstate', onWindowBack)

		return () => {
			window.removeEventListener('popstate', onWindowBack)
		}
	}, [show])

	useEffect(() => {
		// Problem: when starting an exercise, this function is triggered by onWindowBack
		if (!exerciseLoading) {
			const exerciseDuration = timer.getDuration(startTime)
			dispatch(setExerciseDuration(exerciseDuration))
		}
	}, [show])

	const onWindowBack = () => {
		handleShow()
	}

	return (
		<>
			<Button
				variant='danger' className='exercise--close-btn'
				onClick={handleShow} data-cy='exercise_close_button'>
				<FontAwesomeIcon icon={faX} />
			</Button>
			<Modal show={show} onHide={() => handleClose}>
				<Modal.Header>
					<Modal.Title> {t('exercise.exit_prompt')}</Modal.Title>
				</Modal.Header>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => handleClose(false)}
						data-cy='exit_exercise_cancel_button'
					>
						{t('exercise.exit_button_1')}
					</Button>
					<Link to={previousPage}>
						<Button
							variant='danger'
							onClick={() => handleClose(true)}
							data-cy='exit_exercise_confirm_button'
						>
							{t('exercise.exit_button_2')}
						</Button>
					</Link>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ExitButton
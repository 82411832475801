import React from 'react'
import { Image, Col } from 'react-bootstrap'
import { AnswerColor } from 'types/QuestionTypes'
import { Answer} from 'types/AnswerTypes'
import { AWS_URL } from 'utils/config'
import { useAppSelector } from 'stateHandling/hooks'

interface Props {
    alternatives: Answer[]
    answerColor: AnswerColor
    altOnClick: (answerObj: Answer, index: number) => void
}

const AnswerFormatImage = ({alternatives, answerColor, altOnClick }: Props) => {
	const qHasImage = useAppSelector(state => state.currentExercise.exercise.settings.hasImage)

	return (
		<>

			{alternatives.map((answerObj: Answer, i: number) => {
				return (
					<Col
						className='text-center' xs={6} sm={6} md={qHasImage ? 6 : 6} lg={6} xl={3}
						key={`${answerObj.answer.text}_${i}`}
					>
						<Image
							src={AWS_URL + answerObj.answer.text}
							alt='Alternative Image'
							className={`img-fluid comprehension--alt-base comprehension--alt-img comprehension--alt-img-zoom comprehension--alt-${answerColor[i]}`}
							onClick={() => altOnClick(answerObj, i)}
						/>
					</Col>
				)
			})}
		</>
	)
}

export default AnswerFormatImage

import React, { useState, useEffect } from 'react'
import { Button, Form, Accordion, Image, Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormEvent, ChangeEvent } from 'react'
import { faSquareCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAppSelector } from 'stateHandling/hooks'
import CreateExerciseDesc from 'components/CreateExerciseDesc'
import SelectImageModal from 'components/SelectImageModal'
import { changeNrOfAlt, setYesNoAlt } from 'reducers/newOrEditedExerciseSlice'
import { clearIds, clearPageNr } from 'reducers/imageSlice'
import { AWS_URL } from 'utils/config'
import { NewOrEditedQuestion } from 'types/QuestionTypes'
import { Dispatch } from 'types/Types'
import './CreateComprehensionExercise.css'

interface Props {
	addExercise: (event: FormEvent<HTMLFormElement>) => void
	disabled: boolean
	isEdit: boolean
	variant: string
	saveMessage: string
	currentAlternativeIdx: string
	handleQuestionChange: (i: string) => (event: ChangeEvent<HTMLInputElement>) => void
	handleAlternativeInputChange: (i: string, j: string) => (event: ChangeEvent<HTMLInputElement>) => void
	// TODO: below function is state setter - move to this component!
	setCurrentAlternativeIdx: (i: string) => void
	renderPopover: (content: string) => JSX.Element
  }

const CreateComprehensionExercise = ({
	addExercise,
	isEdit,
	handleQuestionChange,
	handleAlternativeInputChange,
	disabled,
	variant,
	saveMessage,
	currentAlternativeIdx,
	setCurrentAlternativeIdx,
	renderPopover }: Props) => {

	const {t} = useTranslation()
	const questions = useAppSelector(state => state.newOrEditedExercise.questions)
	const hasImage = useAppSelector(state => state.newOrEditedExercise.exercise.settings.hasImage)
	const answerFormat = useAppSelector(state => state.newOrEditedExercise.exercise.answerFormat)
	const [showSelectImageModal, setShowSelectImageModal] = useState(false)
	const [currentQuestionIdx, setCurrentQuestionIdx] = useState('')

	// TODO: replace with useAppDispatch
	const dispatch = useDispatch<Dispatch>()

	//Change nr of alternatives in a question, depending on the answerformat (yes/no, text or image)
	useEffect(() => {
		setCurrentQuestionIdx('')
		setCurrentAlternativeIdx('')
		if (!isEdit) {
			if (answerFormat === 'image' || answerFormat === 'text') {
				dispatch(changeNrOfAlt(4))
			} else {
				dispatch(setYesNoAlt())
			}}
	}, [answerFormat])

	//Check if image is defined if image is part of a question. The image is then rendered form the imageURL property in quetsions obj.
	const questionImageDefined = (question: NewOrEditedQuestion) => {
		if (question) {
			return question.imageURL
		}
		return false
	}

	//Check if images is defined if images are the answerformat. The images are then rendered from the answers array with url strings.
	const alternativeImageDefined = (question: NewOrEditedQuestion) => {
		if (isEdit) {
			return question.answers.every(answerObj => answerObj.answer.text !== '')
		} else {
			if (
				questions[currentQuestionIdx] &&
				questions[currentQuestionIdx].answers[currentAlternativeIdx] !== 'undefined'
			) {
				return question.answers[currentAlternativeIdx] !== 'undefined'
			}
		}
	}

	const getQuestionBorderColor = (i: string) => {
		const q = questions[i]

		let hasAnswers = true
		if (answerFormat !== 'yes/no') {
			hasAnswers = q.answers && q.answers.length === 4 && q.answers.every(answerObj => answerObj.answer.text && answerObj.answer.text.trim() !== '')
		} else {
			hasAnswers = q.answers && q.answers.length === 2 && q.answers.map(answerObj => answerObj.answer.text).includes('yes') && q.answers.map(answerObj => answerObj.answer.text).includes('no')
		}

		let qHasImage: boolean | string = true
		if (hasImage) {
			qHasImage = q.imageURL && q.imageURL !== 'undefined'
		}
		if (qHasImage && hasAnswers) return ''
		return 'red'
	}

	//Close image modal
	const closeModal = () => {
		setShowSelectImageModal(false)
	}

	//set the selected image (when only one image?)
	const setSelectedImage = () => {
		//hide image modal
		setShowSelectImageModal(false)
	}

	//Show image modal
	const showModal = (idx: string, j?: string) => {
		setCurrentAlternativeIdx('')
		//Set current quesions index
		setCurrentQuestionIdx(idx)
		if (j) {
			//If j is passed as an argument, set the alternative index
			setCurrentAlternativeIdx(j)
		}
		dispatch(clearIds())
		dispatch(clearPageNr())
		setShowSelectImageModal(true)
	}

	return (
		<>
			<Form onSubmit={addExercise} name='comprehension'>
				<CreateExerciseDesc
					renderPopover={renderPopover}
				/>
				<h2>{t('create_exercise.general.question_title')}</h2>
				<p>{isEdit ? t('edit_exercise.exercise_subtitle') :
					t('create_exercise.general.question_subtitle_comprehension')}</p>
				{Object.keys(questions).map((i: string) => {
					return (
						<div key={i}>
							<Accordion defaultActiveKey={i}>
								<Accordion.Item
									className='mt-3'
									eventKey={i}
									style={{borderColor: getQuestionBorderColor(i)}}>
									<Accordion.Header>
										<Form.Label
											className="fw-bold">{t('create_exercise.question_title', {i})}</Form.Label>
									</Accordion.Header>
									<Accordion.Body>
										{/* The form group for the questions, same for all answertypes */}
										<Form.Group
											className="mb-3"
											controlId={`formQuestion${i}`}>

											<Form.Text>{t('create_exercise.question')}</Form.Text>

											<Form.Control
												data-cy={'exercise_question_' + i}
												type="text"
												className="w-75"
												name='question'
												onChange={handleQuestionChange(i)}
												value={questions[i].question.text}
												placeholder=""
												maxLength={200} />
										</Form.Group>
										{/* The image modal, hidden on default */}
										{showSelectImageModal && i === currentQuestionIdx
											? <SelectImageModal
												question={questions[i]}
												idx={i}
												j={currentAlternativeIdx}
												closeModal={closeModal}
												selectImage={setSelectedImage}
											/>
											: null
										}

										{/* The button that triggers show image modal, if answerformat is text or yes/no */}
										{hasImage ?
											<div>
												<Button
													data-cy={'select_image_btn_' + i}
													onClick={() => showModal(i)}>
													{t('search_images.open_search_modal')}
												</Button>
											</div>
											: <></>
										}
										{hasImage && questionImageDefined(questions[i]) && answerFormat ?
											<Image
												className='images--image my-2 px-0'
												src={AWS_URL + questions[i].imageURL}
												width='200'
											/>
											: <></>
										}

										{/* ANSWERFORMAT IS TEXT OR YES/NO */}
										{answerFormat !== 'image' &&
										<>
											{/*Text describing the alernatives  */}
											<Form.Label className='my-2 d-block'>
												{answerFormat === 'text'
													? `${t('create_exercise.alternatives.text')}`
													: `${t('create_exercise.alternatives.yes_no')}`
												}
											</Form.Label>

											{/* Checks the keys in answers property in question obj and maps over the array of strings*/}
											{Object.keys(questions[i].answers ?
												questions[i].answers : []).map((j: string) => {
												return (
													<Form.Group
														className="mb-2 d-flex"
														controlId={`formAnswer${j}`}
														key={`${i}${j}`}>
														<>
															<Form.Label className='my-0'>
																{+j === 0
																	? <FontAwesomeIcon icon={faSquareCheck} className='m-1 comp-ex--checkbox-success'/>
																	: <FontAwesomeIcon icon={faSquareXmark} className='m-1 comp-ex--checkbox'/>
																}
															</Form.Label>
															<Form.Control data-cy={'exercise_answers_' + i + j}
																type="text"
																className={'w-75'}
																name='alternative'
																onChange={handleAlternativeInputChange(i, j)}
																value={
																	questions[i].answers[j].answer.text ?
																		questions[i].answers[j].answer.text : ''}
																placeholder={+j === 0 ?
																	t('create_exercise.correct_alternative') :
																	t('create_exercise.wrong_alternative')}
																maxLength={100}
															/>
														</>
													</Form.Group>
												)
											})}
										</>
										}

										{/* ANSERFORMAT IS IMAGE */}
										{answerFormat === 'image'
											? <>
												{/*Text describing the alernatives  */}
												<Form.Label className='my-2 d-block'>
													{t('create_exercise.alternatives.image')}
												</Form.Label>

												<span className='w-75 d-flex flex-row justify-content-between mt-2'>
													{/* Checks the keys in answers property in question obj and maps over the array of strings*/}
													{Object.keys(questions[i].answers).map((j: string) => {
														return (
															<Form.Group
																className="m-1"
																controlId={`formAnswer${j}`}
																key={`${i}${j}`}>

																<span className='d-flex flex-column align-items-center'>
																	<div className='d-flex flex-column'>
																		<Form.Label className='mr-1 font-weight-bold text-center'>
																			{+j === 0
																				? <>
																					<FontAwesomeIcon icon={faSquareCheck} className='m-1 comp-ex--checkbox-success'/>
																					<p className='mb-0'>{t('create_exercise.correct_alternative')}</p>
																				</>
																				: <>
																					<FontAwesomeIcon icon={faSquareXmark} className='m-1 comp-ex--checkbox'/>
																					<p className='mb-0'>{t('create_exercise.wrong_alternative')}</p>
																				</>
																			}
																		</Form.Label>
																		<Button
																			data-cy={'select_image_btn_' + i + j}
																			onClick={() => showModal(i, j)}>
																			{t('search_images.open_search_modal')}
																		</Button>
																	</div>
																	{alternativeImageDefined(questions[i]) ? (
																		<>
																			{questions[i].answers[j].answer.text !== '' &&
																		<Image
																			key={`${i}${j}`}
																			className='images--image mt-2'
																			src={AWS_URL + questions[i].answers[j].answer.text}
																			width='100'
																		/>
																			}
																		</>
																	) : null}
																</span>
															</Form.Group>
														)
													})}
												</span>
											</>
											: <></>
										}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
					)
				})}
				<br />
				{disabled ? <Alert variant='warning'>{saveMessage}</Alert> : <></> }
				<Button data-cy={'submit_exercise_btn'} disabled={disabled} variant={variant} size='lg' type="submit">
					{isEdit ? t('create_exercise.update') : t('create_exercise.save')}
				</Button>
			</Form>
		</>
	)
}

export default CreateComprehensionExercise

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useAppSelector, useAppDispatch } from 'stateHandling/hooks'
import { changeCookieConsent, changeAudioProcessingConsent } from 'reducers/appStateSlice'

import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table'

import dialogLogo from 'dialog_logo_inverted_text_purple.png'

import './CookiesPage.css'

const CookiesPage = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const cookieConsent = useAppSelector(state => state.appState.cookieConsent)
	const audioProcessingConsent = useSelector(state => state.appState.audioProcessingConsent)

	const handleConsentUpdate = () => {
		if (cookieConsent) {
			dispatch(changeCookieConsent(false))
		} else {
			dispatch(changeCookieConsent(true))
		}
	}

	const handleAudioProcessingConsentUpdate = () => {
		if (audioProcessingConsent) {
			dispatch(changeAudioProcessingConsent(false))
		} else {
			dispatch(changeAudioProcessingConsent(true))
		}
	}

	// Example?, ks. Aalto
	// https://www.aalto.fi/fi/aalto-kasikirja/evastekaytanto

	return (
		<Container>
			<Image
				src={dialogLogo}
				width="130"
				className='cookies--image'
				alt="Dialog logo"
			/>
			<h1>{t('cookies.title')}</h1>
			<br />
			<h2 className='cookies--h2'>{t('cookies.subtitle')}</h2>
			<br />
			<p>{t('cookies.body')}
				<Link
					to= 'https://www.dialog-therapy.com/privacy-policy'
					className='cookies--link'
					target='_blank'
					rel='noopener noreferrer'
				> https://www.dialog-therapy.com/privacy-policy</Link>
			</p>
			<span className='cookies--allow-text'>{t('cookies.label')} </span>
			<label className="switch">
				<input
					type="checkbox"
					checked={cookieConsent}
					onChange={handleConsentUpdate}
				/>
				<span className="slider round"></span>
			</label>
			<br />
			<br />
			<span className='cookies--allow-text'>{t('cookies.audio_processing_label')} </span>
			<label className="switch">
				<input
					type="checkbox"
					checked={audioProcessingConsent}
					onChange={handleAudioProcessingConsentUpdate}
				/>
				<span className="slider round"></span>
			</label>
			<br />
			<br />
			<h3>{t('cookies.necessary')}</h3>
			<Table striped bordered>
				<thead>
					<tr>
						<th>{t('cookies.name')}</th>
						<th>{t('cookies.provider')}</th>
						<th>{t('cookies.purpose')}</th>
						<th>{t('cookies.validity')}</th>
						<th>{t('cookies.type')}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>i18nextLng</td>
						<td>https://app.dialog-therapy.com/</td>
						<td>Stores the user's language detected by location or by selection</td>
						<td>{t('cookies.list.persistent')}</td>
						<td>Local Storage</td>
					</tr>
					<tr>
						<td>idToken</td>
						<td><a className='cookies--external-link' href='https://aws.amazon.com/cognito/'>AWS</a></td>
						<td>Stored by AWS Cognito for identity and access management of users on the Dialog platform.
                            Obtained by a user when successfully logging in to their Dialog account, destroyed when logged out.</td>
						<td>60 {t('cookies.list.minutes')}</td>
						<td>Local Storage</td>
					</tr>
					<tr>
						<td>refreshToken</td>
						<td><a className='cookies--external-link' href='https://aws.amazon.com/cognito/'>AWS</a></td>
						<td>Stored by AWS Cognito for identity and access management of users on the Dialog platform.
                            Obtained by a user when successfully logging in to their Dialog account, destroyed when logged out.</td>
						<td>30 {t('cookies.list.days')}</td>
						<td>Local Storage</td>
					</tr>
					<tr>
						<td>accessToken</td>
						<td><a className='cookies--external-link' href='https://aws.amazon.com/cognito/'>AWS</a></td>
						<td>Stored by AWS Cognito for identity and access management of users on the Dialog platform.
                            Obtained by a user when successfully logging in to their Dialog account, destroyed when logged out.</td>
						<td>1 {t('cookies.list.day')}</td>
						<td>Local Storage</td>
					</tr>
					<tr>
						<td>LastAuthUser</td>
						<td><a className='cookies--external-link' href='https://aws.amazon.com/cognito/'>AWS</a></td>
						<td>Stored by AWS Cognito. Used to select the correct user's cookies to use for the current session.
                            Obtained by a user when successfully logging in to their Dialog account, destroyed when logged out.</td>
						<td>1 {t('cookies.list.day')}</td>
						<td>Local Storage</td>
					</tr>
					<tr>
						<td>clockDrift</td>
						<td><a className='cookies--external-link' href='https://aws.amazon.com/cognito/'>AWS</a></td>
						<td>Stored by AWS Cognito. Used to handle time synchronization between the client's machine and the AWS Cognito service.
							 Obtained by a user when successfully logging in to their Dialog account, destroyed when logged out.</td>
						<td>1 {t('cookies.list.day')}</td>
						<td>Local Storage</td>
					</tr>
					<tr>
						<td>Hubspot Form</td>
						<td><a className='cookies--external-link' href='https://knowledge.hubspot.com/privacy-and-consent/what-cookies-does-hubspot-set-in-a-visitor-s-browser'>Hubspot</a></td>
						<td>When accessing the dashboard page containing the HubSpot form, HubSpot sets a number of tracking cookies.</td>
						<td>{t('cookies.list.details')}</td>
						<td>HTTP Cookie</td>
					</tr>
					<tr>
						<td>AudioProcessingConsent</td>
						<td>https://app.dialog-therapy.com/</td>
						<td>Stores the user's audio processing consent state for the current domain</td>
						<td>1 {t('cookies.list.year')}</td>
						<td>Local Storage</td>
					</tr>
				</tbody>
			</Table>
			<br />
			<h3>{t('cookies.statistics')}</h3>
			<Table striped bordered>
				<thead>
					<tr>
						<th>{t('cookies.name')}</th>
						<th>{t('cookies.provider')}</th>
						<th>{t('cookies.purpose')}</th>
						<th>{t('cookies.validity')}</th>
						<th>{t('cookies.type')}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>CookieConsent</td>
						<td>https://app.dialog-therapy.com/</td>
						<td>Stores the user's cookie consent state for the current domain</td>
						<td>1 {t('cookies.list.year')}</td>
						<td>Local Storage</td>
					</tr>
					<tr>
						<td>__mpq_*_ev</td>
						<td><a className='cookies--external-link' href='https://mixpanel.com/legal/privacy-policy/'>Mixpanel</a></td>
						<td>This cookie tracks specific events on our platform, such as clicks, page views, and form submissions. They help us understand how users interact with our app and identify areas for improvement.</td>
						<td>{t('cookies.list.persistent')}</td>
						<td>Local Storage</td>
					</tr>
					<tr>
						<td>mp_*_mixpanel</td>
						<td><a className='cookies--external-link' href='https://mixpanel.com/legal/privacy-policy/'>Mixpanel</a></td>
						<td>This cookie store information about user sessions and overall behavior. It helps us identify unique users, track their activity across sessions, and provide a better user experience by analyzing usage patterns.</td>
						<td>1 {t('cookies.list.week')}</td>
						<td>HTTP Cookie</td>
					</tr>
				</tbody>
			</Table>
			<br />
			<Link to='/' className='cookies--link'>{t('cookies.link')}</Link>
		</Container>
	)
}

export default CookiesPage
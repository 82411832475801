import { useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getLanguageCode} from 'utils/helpers'
import SelectSearchCategory from 'components/SelectSearchCategory'
import { getPage, clearIds, clearPageNr, setSearchTerm, setSearchCategory } from 'reducers/imageSlice'

const ImageSearch = () => {
	const { t, i18n} = useTranslation()
	const search = useSelector(state => state.image.search)
	const dispatch = useDispatch()

	// each time modal is openend sets the modal state to initial state, i.e. show all
	useEffect(() => {
		handleSearchPageState()
	}, [])

	const parseSearchTerm = str => {
		const res = str.replace(/[^\w\säöåÄÖÅ]/gi, ' ').toLowerCase()
		return res.split(' ').filter(i => i)
	}

	const handleSearchPageState = (searchTerms = '') => {
		dispatch(setSearchCategory('all'))
		dispatch(setSearchTerm(searchTerms))
		dispatch(clearIds())
		dispatch(clearPageNr())
		dispatch(getPage({
			nextPageBool: true,
			searchCategory: 'all',
			searchTerms: searchTerms,
			langCode:getLanguageCode(i18n.language)}))
	}

	/**
     Handles searching for images. 
    */
	const handlePagedSearch = async event => {
		event.preventDefault()
		const searchTerms = parseSearchTerm(event.target.value)
		handleSearchPageState(searchTerms)
	}

	// Function that blocks enter event from having effect or propagating in nested component
	const blockFormSubmit = event => {
		event.preventDefault()
		event.stopPropagation()
	}

	return (
		<>
			<SelectSearchCategory />
			<Form onSubmit={blockFormSubmit}>
				<Form.Group>
					<Form.Text>{t('search_images.search_field')}</Form.Text>
					<Form.Control
						type="search"
						placeholder={t('search_images.placeholder')}
						className='mb-4 w-50'
						onChange={handlePagedSearch}
						name='search'
						value={search.term}
						minLength='1'
						maxLength='200'
						data-cy='image-text-search'
					/>
				</Form.Group>
			</Form>
		</>
	)
}

export default ImageSearch
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'stateHandling/hooks'
import { Form, Button, Alert, Container, Badge, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'

import { getPatientStatistics, getPatientData } from 'reducers/currentPatientSlice'
import { getExerciseList, resetSearch } from 'reducers/exerciseListSlice'
import { changePreviousPage, changeAdultLibrary } from 'reducers/previousPageSlice'
import { setShowMessage } from 'reducers/userSlice'
import ConfirmDeletePatientModal from 'components/ConfirmDeletePatientModal'
import ResetPasswordModal from 'components/ResetPasswordModal'
import Statistics from 'components/Statistics/Statistics'
import PatientPageSelect from 'components/PatientPage/PatientPageSelect'
import PatientEditPageTabs from 'components/PatientPage/PatientEditPageTabs'
import AssignedExercises from 'components/PatientPage/AssignedExercises/AssignedExercises'
import PracticePeriodSettings from 'components/PatientPage/PracticePeriodSettings'

import { getLanguageCode } from 'utils/helpers'
import patientUserPool from 'utils/patientUserPool'
import { Dispatch } from 'types/Types'
import './PatientEditPage.css'

import { FEATURE_USER_STATISTICS } from 'utils/config'

const PatientEditPage = () => {
	const { id } = useParams()
	const { t, i18n } = useTranslation()
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)

	const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
	const tabIndex = useAppSelector(state => state.previousPage.patientPageTab)
	const adultLibrary = useAppSelector(state => state.previousPage.adultLibrary)

	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()

	const patient = useAppSelector(state => state.currentPatient)
	const user = useAppSelector(state => state.user)
	const previousPage = useAppSelector(state => state.previousPage.path)


	/**
	* Get the patient data, if not available in redux
	*/
	useEffect(() => {
		if (patient.id === '') {
			if (id) {
				dispatch(getPatientData({slpId: user.data.id, patientId: id}))
			} else {
				navigate('/patients')
			}
		}
	}, [id])

	useEffect(() => {
		if (patient.userStatus === 'DOES NOT EXIST') {
			changeUserMessage('danger', `${t('edit_patient.messages.load_patient_failure')}`)
			navigate('/patients')
		}
	}, [patient.userStatus])

	useEffect(() => {
		const langCode = getLanguageCode(i18n.language)
		dispatch(getExerciseList({user, langCode, adultLibrary}))
		if (previousPage !== `/patients/${id}`) {
			dispatch(resetSearch())
		}
		dispatch(changePreviousPage(`/patients/${id}`))
	}, [])

	useEffect(() => {
		dispatch(getPatientStatistics(patient.cognitoId))
	}, [patient.cognitoId])

	/**
	 * Sets the state with the user message after updating user
	 * @param {string} variant - color of the user message
	 * @param {string} text - text in the user message
	 */
	const changeUserMessage = (variant, text) => {
		dispatch(setShowMessage({
			showMessage: true,
			variant: variant,
			message: text
		}))
		setTimeout(() => {
			dispatch(setShowMessage({showMessage: false, variant: 'warning', message: ''}))
		}, 10000)
	}

	/**
	 * Launch the select exercises flow
	 * Update the Library that will be displayed depending on if the user isAdult or !isAdult
	 * Navigate to the assign- rounte
	 */
	const openSelectExercises = () => {
		if (patient.isAdult) {
			if (!adultLibrary) {
				dispatch(resetSearch())
			}
			dispatch(changeAdultLibrary(true))
		} else {
			if (adultLibrary) {
				dispatch(resetSearch())
			}
			dispatch(changeAdultLibrary(false))
		}
		navigate(`/edit_exercises/assign/${patient.id}`)
	}

	const showPasswordModal = () => {
		setShowResetPasswordModal(true)
	}

	const handleClose = () => {
		setShowResetPasswordModal(false)
	}

	return (
		<Container>
			<Row xs='auto' className='justify-content-start'>
				<Col>
					<h2>{t('edit_patient.settings.title')}</h2>
				</Col>
				<Col className='mb-4'>
					<PatientPageSelect />
				</Col>
			</Row>
			<hr />
			<PatientEditPageTabs />
			{user.status.message.showMessage
				? <Alert variant={user.status.message.variant} data-cy='patient_edit_page_alert'>
					{user.status.message.message}
				</Alert>
				: <></>
			}
			{ tabIndex === '2'
				? <>
					<Form name='patientuserinfo' autoComplete="off">
						<Row>
							<Col>
								<Form.Group className="mb-3" controlId='username'>
									<Form.Text>{t('edit_patient.info.username')}</Form.Text>
									<Form.Control type="text" name='username' value={patient.username} disabled />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className="mb-3" controlId='email'>
									<Form.Text>{t('edit_patient.info.email')}</Form.Text>
									<Form.Control type="text" name='email' value={patient.email} disabled />
								</Form.Group>
							</Col>
						</Row>
						<Form.Group className="mb-3" controlId='confirmed'>
							<Form.Text>{t('edit_patient.info.user_status')}</Form.Text>
							<Badge className='mx-2 px-2' data-cy="confirmation_badge"
								bg={patient.userStatus === 'CONFIRMED' ? 'success' : 'secondary'}>
								{t(`edit_patient.info.${patient.userStatus}`)}
							</Badge>
						</Form.Group>
					</Form>
					<Button onClick={showPasswordModal} className='exercise-library--secondary-button mb-4 ms-2'
						variant='outline-dark' data-cy='edit_patient_change_password_button'
					>
						{t('edit_user.change_password')}
					</Button>
					{showResetPasswordModal
						? <ResetPasswordModal
							username={patient.username}
							handleClose={handleClose}
							userPool={patientUserPool}
						/>
						: <></>
					}
					<Button data-cy="delete_patient_button" className='mb-4 ms-2 btn-danger'
						onClick={() => setShowDeleteConfirmModal(true)}>
						{t('edit_patient.delete_account')}
					</Button>
					<ConfirmDeletePatientModal
						showDeleteConfirmModal={showDeleteConfirmModal}
						setShowDeleteConfirmModal={setShowDeleteConfirmModal}
						changeUserMessage={changeUserMessage}
					/>
				</>
				: <></>
			}
			{ tabIndex === '0'
				? <>
					<Row xs='auto'>
						<Col className='flex-grow-1' xs={6} sm={8} data-cy='exercise_library_tab_subtitle'>
							<p>{t('edit_patient.exercises.subtitle')}</p>
						</Col>
						{patient.settings.assignedExercises
							? <Col className='float-end text-end mb-4'>
								{/*<Button className='mb-2' onClick={() => setShowSelectExercisesModal(true)}
									data-cy='select_exercises_button'
								>*/}
								<Button className='mb-2' onClick={openSelectExercises}
									data-cy='select_exercises_button'
								>
									<FontAwesomeIcon icon={faAdd}/>
									<span className='mx-2'>{t('edit_patient.exercises.select_button')}</span>
								</Button>
							</Col>
							: <></>
						}
					</Row>
					<PracticePeriodSettings
						id={id ? id : ''}
						changeUserMessage={changeUserMessage}
					/>
					{patient.settings.assignedExercises
						? <AssignedExercises
							changeUserMessage={changeUserMessage}
						/>
						: <></>
					}
				</>
				: <></>
			}
			{ tabIndex === '1' && FEATURE_USER_STATISTICS === 'true'
				?
				<>
					<Row xs='auto'>
						<Col className='flex-grow-1 mb-3' xs={6} sm={8}>
							<p data-cy='patient_statistics_tab_subtitle'>{t('patient_statistics.subtitle')}</p>
						</Col>
					</Row>
					<Statistics />
				</>
				: <></>
			}
		</Container>
	)
}

export default PatientEditPage
import React, { useState, Fragment} from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Tooltip, OverlayTrigger, Alert, Image } from 'react-bootstrap'
import { faCaretDown, faCaretUp, faComment, faPen, faHeadphones, faBook, faBrain }
	from '@fortawesome/free-solid-svg-icons'
import PhonologyIcon from 'images/phonology.svg'
import GrammarIcon from 'images/grammar.svg'
import UnderstandingIcon from 'images/understanding.svg'
import VocabularyIcon from 'images/vocabulary.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { filterIconColor } from 'utils/helpers'
import timer from 'timer/index'
import { DBStatistic } from 'types/StatisticTypes'
import { useAppSelector } from 'stateHandling/hooks'

interface Props {
	filteredPatientStatistics: DBStatistic[]
  }

const StatisticsTable = ({ filteredPatientStatistics }: Props) => {
	const { t } = useTranslation()
	// TODO: compile separate list of the statistics of the assigned exercises
	// const assignedExercises = useAppSelector(state => state.currentPatient.settings.assignedExercises)
	const [expandedRows, setExpandedRows] = useState<string[]>([])
	const patientIsAdult = useAppSelector(state => state.currentPatient.isAdult)

	const handleExpandRow = (event, id) => {
		event.preventDefault()
		const currentExpandedRows = expandedRows
		const isRowExpanded = currentExpandedRows.includes(id)


		const newExpandedRows = isRowExpanded ?
			currentExpandedRows.filter(id => id !== id) :
			currentExpandedRows.concat(id)


		setExpandedRows(newExpandedRows)
	}

	/**
     * Get the correct icon, given the type
     * @param {string} type - event intance for onKeyDown event
     */
	const getTypeIcon = (type: string) => {
		if (type === 'write')
			return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['write']}} icon={faPen} />
		if (type === 'speak')
			return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['speak']}} icon={faComment} />
		if (type === 'read')
			return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['read']}} icon={faBook} />
		if (type === 'listen')
			return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['listen']}} icon={faHeadphones} />
		else return <></>
	}

	/**
     * Get the correct icon, given the skill
     * @param {string} type - event intance for onKeyDown event
     */
	const getSkillIcon = (skill: string) => {
		if (skill === 'phonology')
			return <Image src={PhonologyIcon} className='px-1' />
		if (skill === 'grammar')
			return <Image src={GrammarIcon} className='px-1' />
		if (skill === 'understanding')
			return <Image src={UnderstandingIcon} className='px-1' />
		if (skill === 'vocabulary')
			return <Image src={VocabularyIcon} className='px-1' />
		if (skill === 'pragmatics')
			return <FontAwesomeIcon className='px-1' style={{color: '#808080'}} icon={faBrain} />
		else return <></>
	}


	const getDuration = (attempt) => {
		const endTime = new Date(attempt.end).getTime()/1000
		const startTime = new Date(attempt.start).getTime()/1000
		return timer.getTimeFormatted(Math.floor(endTime - startTime))
	}

	return (
		<>
			<Table striped hover responsive data-cy='statistics_exercise_table'>
				<thead>
					<tr>
						<th>{t('patient_statistics.table.header_expand')}</th>
						<th>{t('patient_statistics.table.header_title')}</th>
						<th>{t('patient_statistics.table.header_num_attempts')}</th>
						{!patientIsAdult
							? <th>{t('patient_statistics.table.header_skill')}</th>
							: <></>
						}
						<th>{t('patient_statistics.table.header_type')}</th>
					</tr>
				</thead>
				<tbody>
					{Object.values(filteredPatientStatistics).map(stat => {
						return (
							<Fragment key={`${stat.exerciseId}-${stat.answerFormat}`}>
								<tr key={`${stat.exerciseId}-exercise`} data-cy='statistics_exercise_table_row'>
									<td>
										<a href='#'
											onClick={
												event => handleExpandRow(event, `${stat.exerciseId}-${stat.answerFormat}`)
											}
										>
											<FontAwesomeIcon
												icon={
													expandedRows.includes(`${stat.exerciseId}-${stat.answerFormat}`)
														? faCaretUp : faCaretDown
												}
											/>
										</a>
									</td>
									<td>
										{stat.exercise.text}
									</td>
									<td>
										{stat.attempts.length}
									</td>
									{!patientIsAdult
										? <OverlayTrigger
											key={`type-tooltip-${stat.skill[0]}-${stat.exerciseId}`}
											trigger={['hover', 'focus']}
											overlay={
												<Tooltip id={`type-tooltip-${stat.skill[0]}-${stat.exerciseId}`}>
													{t(`patient_statistics.table.tooltip_${stat.skill[0]}`)}
												</Tooltip>
											}>
											<td>
												<span className='text-nowrap'>
													{getSkillIcon(stat.skill[0])}
												</span>
											</td>
										</OverlayTrigger>
										: <></>
									}
									<OverlayTrigger
										key={`type-tooltip-${stat.answerFormat}-${stat.exerciseId}`}
										trigger={['hover', 'focus']}
										overlay={
											<Tooltip id={`type-tooltip-${stat.answerFormat}-${stat.exerciseId}`}>
												{t(`patient_statistics.table.tooltip_${stat.answerFormat}`)}
											</Tooltip>
										}>
										<td>
											<span className='text-nowrap'>
												{getTypeIcon(stat.answerFormat)}
											</span>
										</td>
									</OverlayTrigger>
								</tr>
								{ expandedRows.includes(`${stat.exerciseId}-${stat.answerFormat}`) ?
									<tr key={`${stat.exerciseId}-attempts`}>
										<td></td>
										<td>
											<Table striped hover responsive data-cy='statistics_attempts_table'>
												<thead>
													<tr>
														<th>{t('patient_statistics.table.header_date')}</th>
														<th>{t('patient_statistics.table.header_duration')}</th>
														<th>{t('patient_statistics.table.header_completed')}</th>
														<th>{t('patient_statistics.table.header_stats')}</th>
													</tr>
												</thead>
												<tbody>
													{stat.attempts.map(attempt => {
														return (
															<tr key={attempt.id} data-cy='statistics_attempts_table_row'>
																<td>
																	{new Date(attempt.start).toLocaleDateString('en-GB')}
																</td>
																<td>
																	{getDuration(attempt)}
																</td>
																<td>
																	{attempt.completed
																		? t('edit_patient.exercises.table.yes')
																		: t('edit_patient.exercises.table.no')
																	}
																</td>
																<td>
																	{attempt.answers
																		.filter(answer => answer.correct).length
																	}
																		/
																	{stat.numQuestions}
																</td>
															</tr>
														)
													})}
												</tbody>
											</Table>
										</td>
									</tr>
									: <></>
								}
							</Fragment>
						)
					})}
				</tbody>
			</Table>
			{filteredPatientStatistics.length === 0
				? <Alert className='mt-1' variant='success' data-cy='statistics_exercise_table_no_data'>{t('patient_statistics.table.no_data')}</Alert>
				: <></>
			}
		</>
	)
}


export default StatisticsTable
import React, { Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'stateHandling/hooks'
import { changeGridView } from 'reducers/previousPageSlice'


import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'


const ExerciseListViewToggle = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const gridView = useAppSelector(state => state.previousPage.gridView)

	return (
		<Fragment>
			<Col className='float-end text-end'>
				<GridViewRoundedIcon
					onClick={() => dispatch(changeGridView(true))}
					className={`exerciseLibraryCard--icon-button ${gridView ? 'selected': ''}`}
					data-cy='exercise_library_sort_grid_view_button'
				/>
			</Col>
			<Col className='float-end text-end'>
				<ViewListRoundedIcon
					onClick={() => dispatch(changeGridView(false))}
					className={`exerciseLibraryCard--icon-button ${gridView ? '': 'selected'}`}
					data-cy='exercise_library_sort_list_view_button'
				/>
			</Col>
		</Fragment>
	)
}

export default ExerciseListViewToggle
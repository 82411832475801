import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useAppSelector, useAppDispatch } from 'stateHandling/hooks'
import { changeAudioProcessingConsent } from 'reducers/appStateSlice'

import 'pages/CookiesPage/CookiesPage.css'

const AudioProcessingConsentModal = () => {
	const previousPagePath = useAppSelector(state => state.previousPage.path)

	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const handleResponse = (selection) => {
		dispatch(changeAudioProcessingConsent(selection))
		if (!selection) navigate(previousPagePath)
	}

	return (
		<Modal
			show={true}
			onHide={() => handleResponse(false)}
			backdrop="static"
			keyboard={false}
			centered
			data-cy='audio_processing_consent_modal'
		>
			<Modal.Header closeButton>
				<Modal.Title>{t('audio_processing_consent.title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{t('audio_processing_consent.body_p1')}</p>
				<p>{t('audio_processing_consent.body_p2')}</p>
				<p>{t('audio_processing_consent.body_p3')}</p>
				<Link
					to={t('audio_processing_consent.link_privacy_policy')}
					className='cookies--link'
					target='_blank'
					rel='noopener noreferrer'
				>
					{t('audio_processing_consent.link_privacy_policy')}</Link>
				<p>{}</p>
				<p>{t('audio_processing_consent.body_p4')}</p>
				<Link
					to='/cookies'
					className='cookies--link'
					target='_blank'
					rel='noopener noreferrer'
				>{t('audio_processing_consent.link')}</Link>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="outline-dark"
					onClick={() => handleResponse(false)}
					className='exercise-library--secondary-button'
					data-cy='audio_processing_cancel_button'
				>
					{t('audio_processing_consent.cancel')}
				</Button>
				<Button
					variant="primary"
					onClick={() => handleResponse(true)}
					data-cy='audio_processing_allow_button'
				>{
						t('audio_processing_consent.accept')}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default AudioProcessingConsentModal
import timer from 'timer/index'
import { getLanguageCode } from 'utils/helpers'
import { DBExercise, ExerciseStatsState } from 'types/ExerciseTypes'

/**
 * Function to compile the data to send to mixpanel
 * after an exercise is discontinued or completed
 * @param {DBExercise} exercise
 * @param {string} language
 * @param {number} exerciseLength
 * @param {ExerciseStatsState} stats
 * @param {string} cognitoId
 * @returns {Object} - data object to be sent to Mixpanel
 */
export const getExerciseData = (
	exercise: DBExercise,
	language: string,
	exerciseLength: number,
	stats: ExerciseStatsState
) => {
	const data = {
		'lang_code': getLanguageCode(language),
		'exercise_id': exercise.id,
		'exercise_title': exercise.title.text,
		'exercise_type': exercise.answerFormat,
		'exercise_category': exercise.category,
		'exercise_format': exercise.template,
		'exercise_difficulty': exercise.difficulty + 1,
		'total_questions': exerciseLength,
		'exercise_start_time': stats.timer.start,
		'exercise_duration': stats.timer.exercise,
		'exercise_duration_formatted': timer.getTimeFormatted(stats.timer.exercise),
		'recording_duration': stats.timer.recording,
		'recording_duration_formatted': timer.getTimeFormatted(stats.timer.recording),
	}
	return data
}

/**
 * Function to compile the data to send to mixpanel
 * when an exercise is started
 * @param {DBExercise} exercise
 * @param {string} language
 * @param {number} exerciseLength
 * @param {string} cognitoId
 * @returns {Object} - data object to be sent to Mixpanel
 */
export const getExerciseStartData = (
	exercise: DBExercise,
	language: string,
	exerciseLength: number
) => {
	const data = {
		'lang_code': getLanguageCode(language),
		'exercise_id': exercise.id,
		'exercise_title': exercise.title.text,
		'exercise_type': exercise.answerFormat,
		'exercise_category': exercise.category,
		'exercise_format': exercise.template,
		'exercise_difficulty': exercise.difficulty + 1,
		'total_questions': exerciseLength,
	}
	return data
}
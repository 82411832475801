import { ExerciseStatsState, DBExercise } from 'types/ExerciseTypes'
import { DBStatistic } from 'types/StatisticTypes'
import dayjs from 'dayjs'

/**
 * Function to compile the statistics object for a question
 * @param { ExerciseStatsState } stats
 * @param { string } id
 * @param { boolean } correct 
 * @returns { QuestionStat } Object containing the statistic for a single question
 */
export const getQuestionStat = (stats: ExerciseStatsState, id: string, correct=true) => {
	const startTime = stats.answers.length > 0
		? new Date(stats.answers[stats.answers.length-1].end)
		: new Date(stats.timer.start)
	const endTime = new Date()
	return {
		questionId: id,
		correct,
		// userInput: '', // TODO add
		start: startTime.toISOString(),
		end: endTime.toISOString()
	}
}

/**
 * Function to compile the statistics object for an exercise 
 * @param { DBExercise } exercise
 * @param { string } cognitoId
 * @param { ExerciseStatsState } stats
 * @param { boolean } completed 
 * @returns { ExerciseStat } Object containing the statistic for a single question
 */
export const getExerciseStatistic = (exercise: DBExercise, cognitoId, stats, completed=true) => {
	const endTime = new Date()
	const data = {
		exerciseId: exercise.id,
		answerFormat: exercise.answerFormat,
		cognitoId: cognitoId,
		start: new Date(stats.timer.start).toISOString(),
		end: endTime.toISOString(),
		completed: completed,
		answers: stats.answers
	}
	return data
}

/**
 * Filters statistics data for a specific exercise id
 * @param {string} id - id of exercise
 * @returns {void}
 */
export const filterStatisticsByExerciseId = (exerciseId,statistics:DBStatistic[]) => {

	const tempFilteredPatientStatistics = Object.values(statistics).map(statistic => ({
		...statistic,
		attempts: statistic.attempts.filter(attempt =>
			attempt.exerciseId === exerciseId && attempt.completed
		)
	}))
		// To remove any already existing instances with only non-completed attempts
		.filter(statistic => statistic.attempts.length > 0)

	return tempFilteredPatientStatistics
}

/**
 * Calculates the number of completed exercises per day for the current week or month.
 * @param statistics - An array of DBStatistic objects containing exercise attempts.
 * @returns An array of numbers where each number corresponds to the count of completed
 *          exercises for a specific day in the current week or month.
 */
export const getCompletedAttemptsCount = (statistics: DBStatistic[], timeFrame: 'weekly' | 'monthly' | 'period', resetDate): number[] => {
	const today = dayjs()

	let startDate
	let endDate
	let interval
	let dataLength

	switch (timeFrame) {
	case 'weekly':
		startDate = today.startOf('isoWeek')
		endDate = today.endOf('isoWeek')
		interval = 'day'
		dataLength = 7
		break
	case 'monthly':
		startDate = today.startOf('month')
		endDate = today.endOf('month')
		interval = 'day'
		dataLength = today.daysInMonth()
		break
	case 'period':
		startDate = dayjs(resetDate)
		endDate = dayjs()
		interval = 'day'
		dataLength = endDate.diff(startDate, 'days') + 1
		if (!dataLength || dataLength < 0) dataLength = 0
		break
	}

	const counts = new Array(dataLength).fill(0)

	Object.values(statistics).forEach(statistic => {
		statistic.attempts.forEach(attempt => {
			if (attempt.completed) {
				const attemptDate = dayjs(attempt.start)
				if (attemptDate.isBetween(startDate, endDate, interval, '[]')) {
					const index = attemptDate.diff(startDate, 'day')
					if (index >= 0 && index < counts.length) {
						counts[index]++
					}
				}
			}
		})
	})
	return counts
}

/**
 * Filters the statistics data so that only completed attempts after the resetDate remains
 * @param statistics - An array of DBStatistic objects containing exercise attempts.
 * @param {string} resetDate - resetDate from which the statistics should be kept
 * @param {boolean} countAttempts - if !countAttempts, only completed attempts are kept
 * @returns An array of completed exercise attempt within daterange
 */
export const getFilteredUserStatistics = (statistics: DBStatistic[], resetDate: string, countAttempts: boolean):DBStatistic[] => {
	const now = dayjs()

	const tempFilteredStatistics = Object.values(statistics).map(statistic => ({
		...statistic,
		attempts: statistic.attempts.filter(attempt =>
			dayjs(attempt.end).isBetween(resetDate, now) && (countAttempts || attempt.completed)
		)
	}))
		// To remove any already existing instances with only non-completed attempts
		.filter(statistic => statistic.attempts.length > 0)
	return tempFilteredStatistics
}
import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col } from 'react-bootstrap'

import ExerciseTextAndAudioPrompt from 'components/ExercisePrompt/ExerciseTextAndAudioPrompt/ExerciseTextAndAudioPrompt'
import Answers from 'components/Answers/Answers'
import ExerciseFeedback from 'components/ExerciseFeedback/ExerciseFeedback'
import ExerciseFeedbackChildren from 'components/ExerciseFeedback/ExerciseFeedbackChildren'

import { EXERCISE_PROGRESS_STATUS_NEXT, EXERCISE_PROGRESS_STATUS_RETRY } from 'utils/config'
import { FillAudioBuffer, FillContentQuestionState } from 'types/QuestionTypes'


const ExercisePromptFill = () => {
	const status = useAppSelector(state => state.currentExercise.stats.status)
	const isAdult = useAppSelector(state => state.currentExercise.exercise.isAdult)
	const question = useAppSelector(state => state.currentExercise.currentQuestion) as FillContentQuestionState
	const currentAudioBuffer = useAppSelector(state => state.currentExercise.currentAudioBuffer) as FillAudioBuffer

	const renderFeedback = () => {
		if (isAdult) {
			return (
				<>
					<Col xs={12} sm={6} className='px-5 exercise--prompt-border'>
						<ExerciseTextAndAudioPrompt
							questionText={question.fullSentence!.text}
							questionAudio={currentAudioBuffer.fullSentence}
						/>
					</Col>
					<Col xs={12} sm={6} className='px-5'>
						<ExerciseFeedback />
						<Answers />
					</Col>
				</>
			)
		} else {
			return <ExerciseFeedbackChildren />
		}
	}

	return (
		<>
			{status === EXERCISE_PROGRESS_STATUS_NEXT || status === EXERCISE_PROGRESS_STATUS_RETRY
				? <Row className='exercise--div-question mb-2 flex-grow-1'>
					{renderFeedback()}
				</Row>
				: <>
					<ExerciseTextAndAudioPrompt
						questionText={question.sentence!.text}
						questionAudio={currentAudioBuffer.sentence}
					/>
				</>
			}
		</>
	)
}

export default ExercisePromptFill
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from 'stateHandling/hooks'
import 'components/Answers/Answers.css'
import { getAudioCopy, processAudio } from 'recorder/recorder'
import { DefaultContentQuestionState, FillContentQuestionState } from 'types/QuestionTypes'


const AnswerTags = () => {
	const question = useAppSelector(state =>
		state.currentExercise.currentQuestion) as DefaultContentQuestionState | FillContentQuestionState
	const selectedAudioAnswers = useAppSelector(state => state.currentExercise.currentAudioBuffer.answers)
	const [audioContext, setAudioContext] = useState<AudioContext| null>(null)
	const [currentAudio, setCurrentAudio] = useState<AudioBufferSourceNode| null>(null)

	/**
	* Responsible for creating audioContext when component is mounted
	* And closing the audioContext when it is unmounted (the return statement)
	*/
	useEffect(() => {
		let audioContext
		try {
			audioContext = new AudioContext()
			setAudioContext(audioContext)
		} catch (exception) {
			console.error('AudioContext is not supported by the browser: ', exception)
		}
		return () => {
			if (audioContext) {
				audioContext.close()
			}
		}
	}, [])

	/**
     * Plays audio question or answer audio based on the given argument
     * Note: we need to create a copy of the original audio buffer to replay it
     * @returns {void}
     */
	const playAudio = async (buffers, i) => {
		if (buffers && buffers.length > i && audioContext) {
			try {
				audioContext.resume()
				const buffer = buffers[i]

				if (currentAudio != null) {
					currentAudio.stop()
					setCurrentAudio(null)
				}
				const processedAudio = await processAudio(buffer, audioContext)
				const audioCopy = getAudioCopy(processedAudio, audioContext)
				audioCopy.start(0)
				setCurrentAudio(audioCopy)

				audioCopy.onended = () => {
					setCurrentAudio(null)
				}
			} catch (exception) {
				console.error(exception)
			}
		}
	}

	const getVariant = (buffers, i) => {
		if (buffers && buffers.length > i) {
			const buffer = buffers[i]
			if (buffer && buffer.length > 0) return 'outline-primary'
			else return 'outline-secondary'
		}
		return 'outline-secondary'
	}

	return (
		<div>
			{question.answers.map((answerObj, i) => {
				if (i == 5) {
					return (
						<span style={{ fontSize: '2rem' }}> ...</span>
					)
				} else if (i > 5) {
					return null
				} else {
					return (
						<Button
							key={i}
							className='mx-2 my-1 exercise--header-button'
							onClick={() => playAudio(selectedAudioAnswers, i)}
							variant={getVariant(selectedAudioAnswers, i)}
						>
							<FontAwesomeIcon key={i} className='audio--play-icon' icon={faVolumeUp}/>
							<span className='exercise--audio-button-text mx-2'>
								{answerObj.answer.text}
							</span>
						</Button>
					)
				}
			})}
		</div>
	)
}

export default AnswerTags
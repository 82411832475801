import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'
import PlayAnswerChildren from 'components/PlayAnswerChildren'
import WrittenAnswer from 'components/WrittenAnswer' // we might want to add this back later
import FeedbackIncorrectChildren from 'components/ExerciseFeedback/FeedbackIncorrectChildren'
import FeedbackCorrectChildren from 'components/ExerciseFeedback/FeedbackCorrectChildren'
import { EXERCISE_ANSWER_STATUS_CORRECT, EXERCISE_TYPE_SPEAK, SKILL_PHONOLOGY } from 'utils/config'

const ExerciseFeedbackChildren = () => {
	const format = useAppSelector(state => state.currentExercise.exercise.answerFormat)
	const skill = useAppSelector(state => state.currentExercise.exercise.skill)
	const answerStatus = useAppSelector(state => state.currentExercise.stats.answerStatus)

	const renderFeedback = () => {
		if (skill.includes(SKILL_PHONOLOGY) && format === EXERCISE_TYPE_SPEAK) {
			return (
				<>
					<Col xs={6} sm={6}>
						<PlayAnswerChildren />
					</Col>
					<Col xs={6} sm={6}>
						{answerStatus === EXERCISE_ANSWER_STATUS_CORRECT
							? <FeedbackCorrectChildren />
							: <FeedbackIncorrectChildren />
						}
					</Col>
				</>
			)
		} else {
			return (
				<Col className='d-flex justify-content-center'>
					{answerStatus === EXERCISE_ANSWER_STATUS_CORRECT
						? <FeedbackCorrectChildren />
						: <FeedbackIncorrectChildren />
					}
				</Col>
			)
		}
	}

	return (
		<>
			<Row className='align-items-center justify-content-center'>
				{renderFeedback()}
			</Row>
		</>
	)
}

export default ExerciseFeedbackChildren
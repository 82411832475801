import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp, faStop } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from 'react'
import { processAudio, getAudioCopy } from 'recorder/recorder'
import { Button } from 'react-bootstrap'

interface Props {
	audioBuffer: Uint8Array
	isThinking: boolean
	showConfirmation: boolean
	autoplay: boolean
}

const PlayAudio = ({ audioBuffer, isThinking, showConfirmation, autoplay}:Props) => {
	const [audioContext, setAudioContext] = useState<AudioContext | null>(null)
	const [currentAudio, setCurrentAudio] = useState<AudioBufferSourceNode| null>(null)
	const [isPlaying, setIsPlaying] = useState(false) // used to make sure audio is not played over audio - not implemented yet

	useEffect(() => {
		let audioContext:AudioContext
		try {
			audioContext = new AudioContext()
			setAudioContext(audioContext)
		} catch (exception) {
			console.error('AudioContext is not supported by the browser: ', exception)
		}
		return () => {
			if (audioContext) {
				audioContext.close()
			}
		}
	}, [])

	useEffect(() => {
		const autoPlay = async () => await playAudio()
		if (!isThinking && autoplay && !showConfirmation) autoPlay()
	}, [audioContext])

	const playAudio = async () => {
		if (audioContext) {
			try {
				if (currentAudio != null) {
					currentAudio.stop()
					setIsPlaying(false)
					setCurrentAudio(null)
					return
				}

				// Thought this was required for Safari to play audio, but seems to work without it 
				// audioContext.resume() // I have moved this here, as it didn't have desired effect if set in processAudio			

				const processedAudio = await processAudio(audioBuffer, audioContext)
				const audioCopy = getAudioCopy(processedAudio, audioContext)
				audioCopy.start(0)
				setCurrentAudio(audioCopy)
				setIsPlaying(true)

				audioCopy.onended = () => {
					setIsPlaying(false)
					setCurrentAudio(null)
				}
			} catch (exception) {
				console.error(exception)
			}
		}
	}
	const handleKeyPress = (event: { key: string }) => {
		if (event.key === 'Enter') {
			playAudio()
		}
	}

	return (
		<div>
			{isPlaying
				? <Button
					className='exercise--secondary-button'
					onClick={playAudio}
					onKeyDown={handleKeyPress}
					tabIndex={3}
					variant='outline-danger'>
					<FontAwesomeIcon icon={faStop} className='fa-beat' />
				</Button>
				: <Button
					className='exercise--secondary-button'
					onClick={playAudio}
					onKeyDown={handleKeyPress}
					tabIndex={3}
					variant='outline-primary'
				>
					<FontAwesomeIcon icon={faVolumeUp} />
				</Button>
			}
		</div>
	)
}

export default PlayAudio
import { Card, Button, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import Confetti from 'react-confetti'
import React, { useEffect, useState } from 'react'
import Hotkeys from 'react-hot-keys'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import timer from 'timer/index'
import finishedExerciseCalm from 'sounds/finish_exercise_calm.mp3'
import finishedExerciseCelebrate from 'sounds/finish_exercise_celebrate.mp3'

import { setExerciseDuration } from 'reducers/currentExerciseSlice'

interface Props {
	handleFinished:() => void
}
const Finished = ({ handleFinished }: Props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const length = useAppSelector(state => state.currentExercise.length)
	const stats = useAppSelector(state => state.currentExercise.stats.numCorrect)
	const startTime = useAppSelector(state => state.currentExercise.stats.timer.start)
	const timeData = useAppSelector(state => state.currentExercise.stats.timer)

	const [numberOfConfettiPieces, setnumberOfConfettiPieces] = useState(200)

	const randomIntFromInterval = (min: number, max: number) => {
		return Math.floor(Math.random() * (max - min + 1) + min)
	}

	useEffect(() => {
		playFinishedSound()

		const exerciseDuration = timer.getDuration(startTime)
		dispatch(setExerciseDuration(exerciseDuration))
		setTimeout(() => {
			setnumberOfConfettiPieces(0)
		}, 3500)
	}, [])

	const playFinishedSound = () => {
		if (stats >= 2 * length / 3) {
			const finishedExerciseCelebrateSound = new Audio (finishedExerciseCelebrate)
			finishedExerciseCelebrateSound.play()
		} else {
			const finishedExerciseCalmSound = new Audio (finishedExerciseCalm)
			if (finishedExerciseCalmSound) finishedExerciseCalmSound.play()
		}
	}

	const calculatePercentage = (stats,length) => { // min and max included 
		const fraction = Math.round((stats/length)*100)
		return `${fraction}%`
	}

	const getRoundedMinutes = (min, sec) => {
		const roundedMin = Math.round((min*60+sec)/60)
		return `${roundedMin}'`
	}

	const finishedContent = () => {
		const percentageOfCorrectAnswers = calculatePercentage(stats, length)
		const randomIndex = randomIntFromInterval(0, 1)
		let title = ''
		const showConfetti = stats >= 2 * length / 3
		if (stats >= 2 * length / 3) {
			if (randomIndex == 0) {
				title = t('exercise.completed.title_celebratory_1', {percentageOfCorrectAnswers})
			} else if (randomIndex == 1) {
				title = t('exercise.completed.title_celebratory_2', {percentageOfCorrectAnswers})
			}
		} else if (stats >= length / 3) {
			if (randomIndex == 0) {
				title = t('exercise.completed.title_calm_1', {percentageOfCorrectAnswers})

			} else if (randomIndex == 1) {
				title = t('exercise.completed.title_calm_2', {percentageOfCorrectAnswers})
			}
		} else {
			if (randomIndex == 0) {
				title = t('exercise.completed.title_cheer_up_1')
			} else if (randomIndex == 1){
				title = t('exercise.completed.title_cheer_up_2')
			}
		}

		return (
			<Row className="exercise--header-bg align-items-center p-2 pt-3">
				{showConfetti ?
					<Confetti
						numberOfPieces={numberOfConfettiPieces}
						width={window.innerWidth} height={window.innerHeight}/>
					: <></>}
				<Col className='text-center'>
					<h1 data-cy='exercise-finished-title'>
						{t('exercise.completed.top_bar_text')}
					</h1>
					<h2>{title}</h2>
				</Col>
			</Row>
		)
	}

	const answerStatsBox = () => {
		const percentage = calculatePercentage(stats, length)
		return (
			<Card.Body data-cy='exercise-finished-results-card'>
				<h3 className='exercise-finished--text-big'>
					{percentage}
				</h3>
				<h4>
					{t('exercise.completed.results_stats', {percentage, stats, length})}
				</h4>
			</Card.Body>
		)
	}

	const timeStatsBox = () => {
		const seconds = timer.getSeconds(timeData.exercise)
		const minutes = timer.getMinutes(timeData.exercise)
		const roundedMinutes = getRoundedMinutes(minutes, seconds)
		return (
			<Card.Body data-cy='exercise-finished-time-card'>
				<h3 className='exercise-finished--text-big'>
					{roundedMinutes}
				</h3>
				<h4>
					{t('exercise.completed.time_stats', {minutes, seconds})}
				</h4>
			</Card.Body>
		)
	}

	return (
		<>
			{finishedContent()}
			<Row className='align-items-center flex-grow-1'>
				<Col className='mt-4' xs={12} md={6}>
					<Card>
						<Card.Header as='h2'>
                        ✅ {t('exercise.completed.results_title')}
						</Card.Header>
						{answerStatsBox()}
					</Card>
				</Col>
				<Col className='mt-4' xs={12} md={6}>
					<Card>
						<Card.Header as='h2'>
                        ⏱️ {t('exercise.completed.time_title')}
						</Card.Header>
						{timeStatsBox()}
					</Card>
				</Col>
				<Col>
				</Col>
			</Row>
			<Row className="exercise--footer-fixed justify-content-center align-items-center">
				<Col className='text-center'>
					<Hotkeys keyName="enter" onKeyDown={handleFinished}>
						<Button
							autoFocus={true} variant='primary'
							className='text-nowrap exercise--next-button px-5 py-4 fs-5'
							data-cy='exercise_finished_return_button'
							onClick={handleFinished}>
							<FontAwesomeIcon className='exercise--next-button-icon' icon={faCheck} />
							{t('exercise.completed.back_btn')}
						</Button>
					</Hotkeys>
				</Col>
			</Row>
		</>
	)
}

export default Finished
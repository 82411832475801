import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import adminUserPool from 'utils/adminUserPool'

import ResetPasswordModal from 'components/ResetPasswordModal'
import ConfirmDeleteModal from 'components/ConfirmDeleteModal'

const UserPage = () => {
	const { t } = useTranslation()
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
	const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)

	const navigate = useNavigate()

	const user = useAppSelector(state => state.user)

	const showEditPatientsPage = () => {
		navigate('/patients')
	}

	return (
		<Container>
			<h1>{t('edit_user.title')}</h1>
			<Form name='userinfo' autoComplete="off">
				<Form.Group className="mb-3" controlId='username'>
					<Form.Text>{t('create_user.username')}</Form.Text>
					<Form.Control type="text" className="" name='username' value={user.data.username} disabled />
				</Form.Group>
				<Form.Group className="mb-3" controlId='email'>
					<Form.Text>{t('create_user.email')}</Form.Text>
					<Form.Control type="text" className="" name='email' value={user.data.email} disabled />
				</Form.Group>
				<Form.Group className="mb-3" controlId='type'>
					<Form.Text>{t('create_user.user_type.text')}</Form.Text>
					<Form.Control type="text" name='type' value={t(`edit_user.user_type_${user.data.type}`)} disabled />
				</Form.Group>
			</Form>

			<Button data-cy='change_pw_btn' variant="primary" onClick={() => setShowResetPasswordModal(true)}>
				{t('edit_user.change_password')}
			</Button>
			<Button
				onClick={() => setShowDeleteAccountModal(true)}
				data-cy='delete_acc_btn'
				className="mx-2"
				variant="danger"
			>
				{t('edit_user.delete_account')}
			</Button>

			{showDeleteAccountModal ? <ConfirmDeleteModal
				handleClose={() => setShowDeleteAccountModal(false)}/> : <></>}
			{showResetPasswordModal ?
				<ResetPasswordModal
					username={user.data.username}
					handleClose={() => setShowResetPasswordModal(false)}
					userPool={adminUserPool}
				/>
				: <></>}
		</Container>
	)
}

export default UserPage
import { useTranslation } from 'react-i18next'
import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'

import { changeAppLoading } from 'reducers/appStateSlice'
import { setEditExercise } from 'reducers/newOrEditedExerciseSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'
import exerciseService from 'services/exercises'
import QuestionsTable from 'components/QuestionsTable'
import ComprehensionQuestionsTable from 'components/ComprehensionQuestionsTable'
import ConfirmDeleteExerciseModal from 'components/ConfirmDeleteExerciseModal'
import ExercisePreviewTable from 'components/ExercisePreviewTable'

import { Dispatch } from 'types/Types'
import { LibraryExercise } from 'types/ExerciseTypes'


const ExercisePreviewPage = () => {
	const { t } = useTranslation()
	const { id } = useParams()
	const { renderType } = useParams()
	const { baselib } = useParams()
	const [showDeleteExerciseModal, setShowDeleteExerciseModal] = useState(false)

	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()

	const exercise = useAppSelector(state => state.newOrEditedExercise.exercise)
	const user = useAppSelector(state => state.user.data)
	const previousPage = useAppSelector(state => state.previousPage.path)


	useEffect(() => {
		if (id && previousPage === '/') {
			dispatch(changePreviousPage('/edit_exercises'))
		}
	}, [id])
	/**
     * Callback for fetching and dispatching action wiht exercise data
     */
	const fetchExercise = useCallback(async () => {
		if (exercise.id === '') {
			dispatch(changeAppLoading(true))
			const data = await exerciseService.getById(id!)
			dispatch(setEditExercise(data))
			dispatch(changeAppLoading(false))
		}
	}, [id])

	/**
     * Call the callback for fetching and storing the exercise data
    */
	useEffect(() => {
		try {
			fetchExercise()
		} catch (exception) {
			console.error(exception)
		}
	}, [fetchExercise])

	/**
	 * Function that is called from the delete exercise modal when it is closed
	 */
	const handleCloseDeleteExerciseModal = () => {
		setShowDeleteExerciseModal(false)
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
	}

	/**
	 * Function that is called from the delete exercise modal, when an exercise is deleted
	 */
	const handleDeleteExerciseModal = () => {
		setShowDeleteExerciseModal(false)
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
		navigate(previousPage === '/' ? '/edit_exercises' : previousPage)
	}

	/**
	 * Function that is called from the delete exercise model when it is closed
	 */
	const openEditExerciseView = () => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
		navigate('/edit')
	}

	return (
		<Container className='user-select'>
			<h2>{t('preview_exercise.page.title')}</h2>
			<hr />
			<ExercisePreviewTable exercise={exercise as LibraryExercise} baselib={baselib ? baselib === 'true' : false} renderType={renderType!} />
			{/** Don't show edit and delete buttons if viewing exercise when assigning to patient */}
			{ previousPage === '/edit_exercises' && (exercise.owner === user.id || user.type === 'admin')
				? <>
					<Button
						onClick={() => openEditExerciseView()}
						data-cy='edit_exercise_btn'
						className="my-2"
					>
						{t('preview_exercise.page.edit_button')}
					</Button>
					<Button
						onClick={() => setShowDeleteExerciseModal(true)}
						data-cy='delete_exercise_btn'
						className="my-2 mx-4"
						variant="danger"
					>
						{t('preview_exercise.delete.delete_button')}
					</Button>
				</>
				: <></>
			}
			<h2 className='mt-3'>{t('preview_exercise.description.title')}</h2>
			<Row className='mb-3'>
				<Col className='m-2 p-2' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
					<h5>{t('preview_exercise.description.slp')}</h5>
					<p>{exercise.description.slp.text}</p>
				</Col>
				<Col className='m-2 p-2' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
					<h5>{t('preview_exercise.description.patient')}</h5>
					<p>{exercise.description.patient.text}</p>
				</Col>
			</Row>
			
			<h2>{t('preview_exercise.page.additional_info')}</h2>
			<Row className='mb-3' data-cy='exercise_preview_additional_info_row'>
				<Col className='m-2 p-2' data-cy='exercise_preview_additional_info_col_1' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
					<p className='mb-1'>
						{`${t('create_exercise.settings.langCode.label')}: `}
						<b>
							{t(`create_exercise.settings.langCode.${exercise.langCode}`)}
						</b>
					</p>
					<p>{`${t('edit_patient.exercises.table.number')}: `}
					<b>
						{exercise.questions.length}
					</b>
					</p>
				</Col>
				<Col className='m-2 p-2' data-cy='exercise_preview_additional_info_col_2' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
					<p className='mb-1'>
						{t('create_exercise.settings.question_settings.shuffle_questions_label')}
						<b>
							{exercise.settings.shuffleQuestions
								? `: ${t('edit_patient.exercises.table.yes')}`
								: `: ${t('edit_patient.exercises.table.no')}`}
						</b>
					</p>
					<p className='mb-1'>
						{t('create_exercise.settings.question_settings.using_images_label')}
						<b>
							{exercise.settings.hasImage
								? `: ${t('edit_patient.exercises.table.yes')}`
								: `: ${t('edit_patient.exercises.table.no')}`}
						</b>
					</p>
					<p className='mb-1'>
						{t('create_exercise.settings.question_settings.using_hints_label')}
						<b>
							{exercise.settings.useHint
								? `: ${t('edit_patient.exercises.table.yes')}`
								: `: ${t('edit_patient.exercises.table.no')}`}
						</b>
					</p>
				</Col>
			</Row>

			<h2>{t('preview_exercise.page.questions')}</h2>
			{exercise.id && (
				exercise.template !== 'comprehension' ?
					<QuestionsTable questions={exercise.questions}/>
					: <ComprehensionQuestionsTable />
			)}
			{showDeleteExerciseModal
				? <ConfirmDeleteExerciseModal
					handleClose={handleCloseDeleteExerciseModal}
					handleDelete={handleDeleteExerciseModal}
				/>
				: <></>
			}
		</Container>
	)
}

export default ExercisePreviewPage
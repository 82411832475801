import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Popover, OverlayTrigger, Image } from 'react-bootstrap'
import { useAppSelector } from 'stateHandling/hooks'
import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import Hint from 'images/avatars/dialo_hint.png'

const TextPromptChildren = () => {
	const { t } = useTranslation()
	const hint = useAppSelector(state => state.currentExercise.currentQuestion.hint)
	const currentAudioBuffer = useAppSelector(state => state.currentExercise.currentAudioBuffer)

	const popover = (
		<Popover data-cy='exercise_hint_popover'>
			<Popover.Header><h3>{t('exercise.hint.popover_title')}</h3></Popover.Header>
			<Popover.Body>
				<h4>
					{currentAudioBuffer.hint!.length > 0
						? <span className='px-2'>
							<AudioPlayer
								buffer={currentAudioBuffer.hint}
								large={false}
								text={false}
							/>
						</span>
						: <></>
					}
					{hint}
				</h4>
			</Popover.Body>
		</Popover>
	)

	return (
		<>
			<OverlayTrigger trigger="click" placement='top' overlay={popover} rootClose>
				<Button
					className='exercise--hint-children'
					data-cy='exercise_hint_button'
				>
					<Image src={Hint} style={{ 'height': 'inherit' }} />
				</Button>
			</OverlayTrigger>
		</>
	)
}

export default TextPromptChildren
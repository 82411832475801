import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Image, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

import { useAppSelector } from 'stateHandling/hooks'
import { AWS_URL } from 'utils/config'
import AnswerAlternativeText from 'components/ComprehensionAnswerFormats/AnswerAlternativeText'
import { DefaultContentQuestionState } from 'types/QuestionTypes'
import FeedbackCorrect from 'components/ExerciseFeedback/FeedbackCorrect'
import FeedbackIncorrect from 'components/ExerciseFeedback/FeedbackIncorrect'
import { EXERCISE_ANSWERFORMAT_YESNO, EXERCISE_ANSWERFORMAT_IMAGE, EXERCISE_ANSWERFORMAT_TEXT } from 'utils/config'

const ComprehensionAnswers = () => {
	const { t } = useTranslation()
	const question = useAppSelector(state => state.currentExercise.currentQuestion) as DefaultContentQuestionState
	const alternativesFormat = useAppSelector(state => state.currentExercise.exercise.answerFormat)
	const chosenAnswer = useAppSelector(state => state.currentExercise.chosenAnswer)

	const [correctAlternative, setCorrectAlternative] = useState(question.answers?.filter(answerObj => answerObj.isCorrect)[0])

	const correctAnswer = () => {
		if (question.answerFormat === EXERCISE_ANSWERFORMAT_YESNO) {
			return (
				<Button
					className='m-2 py-2 px-5 comprehension--alt-base comprehension--alt-tag-yesNo comprehension--alt-correct'
					variant='outline-dark'
					key={`${correctAlternative.answer.text}`}
				>
					<FontAwesomeIcon
						icon={correctAlternative.answer.text === 'yes' ? faThumbsUp : faThumbsDown}
						className={`${correctAlternative.answer.text === 'yes'
							? 'comprehension--success' : 'comprehension--fail'}`}
					/>
					<p>{t(`exercise.${correctAlternative.answer.text}`)}</p>
				</Button>
			)
		} else if (question.answerFormat === EXERCISE_ANSWERFORMAT_IMAGE) {
			return (
				<Image
					src={AWS_URL + correctAlternative.answer.text}
					alt='Alternative Image'
					className='comprehension--alt-base comprehension--alt-img img-fluid comprehension--alt-correct'
				/>
			)
		} else {
			return (
				<AnswerAlternativeText
					answerObj={correctAlternative}
					confidence={1}
				/>
			)
		}
	}

	const incorrectAnswer = () => {
		if (question.answerFormat === EXERCISE_ANSWERFORMAT_YESNO) {
			return (
				<Button
					className='m-2 py-2 px-5 comprehension--alt-base comprehension--alt-tag-yesNo comprehension--alt-incorrect'
					variant='outline-dark'
					key={`${chosenAnswer.answer.text}`}
				>
					<FontAwesomeIcon
						icon={chosenAnswer.answer.text === 'yes' ? faThumbsUp : faThumbsDown}
						className={`${chosenAnswer.answer.text === 'yes'
							? 'comprehension--success' : 'comprehension--fail'}`}
					/>
					<p>{t(`exercise.${chosenAnswer.answer.text}`)}</p>
				</Button>
			)
		} else if (question.answerFormat === EXERCISE_ANSWERFORMAT_IMAGE) {
			return (
				<Image
					src={AWS_URL + chosenAnswer.answer.text}
					alt='Alternative Image'
					className='comprehension--alt-base comprehension--alt-img img-fluid comprehension--alt-incorrect'
				/>
			)
		} else {
			return (
				<Col className='flex-grow-1'>
					<AnswerAlternativeText
						answerObj={chosenAnswer}
						confidence={0}
					/>
				</Col>
			)
		}
	}

	return (
		<>
			{correctAlternative.answer.text == chosenAnswer.answer.text
				? <>
					<p className='exercise--feedback-title-text fs-6 my-0'>
						{t('comprehension_exercise.answer_feedback.your_selection')}
					</p>
					<Row className={`justify-content-start ${question.answerFormat === EXERCISE_ANSWERFORMAT_TEXT ? 'align-items-end' : 'align-items-center'}`}>
						<Col xs={question.answerFormat === EXERCISE_ANSWERFORMAT_TEXT ? 8 : 6}>
							{correctAnswer()}
						</Col>
						<Col xs={4} className='mb-2'>
							<FeedbackCorrect />
						</Col>
					</Row>
				</>
				: <>
					<p className='exercise--feedback-title-text fs-6 my-0'>
						{t('comprehension_exercise.answer_feedback.your_selection')}
					</p>
					<Row className='justify-content-start align-items-center'>
						<Col xs={question.answerFormat === EXERCISE_ANSWERFORMAT_TEXT ? 8 : 6}>
							{incorrectAnswer()}
						</Col>
						<Col xs={4} className='mb-2'>
							<FeedbackIncorrect />
						</Col>
					</Row>
					<Row className='justify-content-start'>
						<Col xs={question.answerFormat === EXERCISE_ANSWERFORMAT_TEXT ? 8 : 6}>
							<p className='exercise--feedback-title-text fs-6 my-0'>
								{t('comprehension_exercise.answer_feedback.correct_alt')}
							</p>
							{correctAnswer()}
						</Col>
					</Row>
				</>
			}
		</>
	)
}

export default ComprehensionAnswers
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Image as Img, Button, Container, Alert, ListGroup } from 'react-bootstrap'

import imageService from 'services/images'
import { getLanguageCode, hasWhiteSpace, itemIsUnique } from 'utils/helpers'

import FilePicker from 'components/FilePicker'
import SelectCategory from 'components/SelectCategory'
import Loading from 'components/Loading'
import Tags from 'components/Tags'

/** 
 * 
 * Autocreating tags: https://cloud.google.com/vision/docs/detect-labels-image-client-libraries#client-libraries-install-nodejs
 * 
*/

const AddImage = () => {
	const [image, setImage] = useState(null)
	const [imageURL, setImageURL] = useState(null)
	const [tag, setTag] = useState('')
	const [tags, setTags] = useState([])
	const [category, setCategory] = useState('adjectives')
	const [fileInput, setFileInput] = useState(null)
	const [loading, setLoading] = useState(false)
	const [btnDisabled, setBtnDisabled] = useState(true)
	const [btnVariant, setBtnVariant] = useState('secondary')

	const [showTagMessage, setShowTagMessage] = useState(false)
	const [tagMessage, setTagMessage] = useState('')

	const [showImageMessage, setShowImageMessage] = useState(false)
	const [imageMessage, setImageMessage] = useState('')
	const [imageMessageVariant, setImageMessageVariant] = useState('warning')

	const { t, i18n } = useTranslation()

	/**
     * Responsible for updating disabled state of the save button
     */
	useEffect(() => {
		const hasRequired = tags.length > 0 && category && image
		if (hasRequired) {
			setBtnDisabled(false)
			setBtnVariant('primary')
		} else {
			setBtnDisabled(true)
			setBtnVariant('secondary')
		}
	}, [image, tags, category])

	/**
     * Handles submitting the form by generating a FormData instance, filling it with suitable content
     * and calling the imageService to send a POST request to the server
     * @param {Event} event - event instance of the form
     */
	const handleSubmit = async event => {
		event.preventDefault()
		setShowImageMessage(false)
		try {
			setLoading(true)
			const formData = new FormData()
			formData.append('image', image)
			formData.append('tags', tags.join(' '))
			formData.append('langCode', getLanguageCode(i18n.language))
			formData.append('category', category) // update when categories are chosen
			await imageService.create(formData)
			setImageMessage(`${t('add_image.message.success')}: ${tags.join(', ')}`)
			deleteUploadedImage()
			setTag('')
			setTags([])
			setCategory('adjectives')
			setLoading(false)
			setShowImageMessage(true)
			setImageMessageVariant('success')
		} catch (exception) {
			console.error(exception)
			setLoading(false)
			setImageMessage(`${t('add_image.message.error')}: ${tags.join(' ')}`)
			setShowImageMessage(true)
			setImageMessageVariant('danger')
		}
	}

	/**
     * Clicking enter creates a new tag, so we listen for this. Also some sanitary checks are performed
     * @param {Event} event - event intance for onKeyDown event
     */
	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			event.preventDefault()
			if (tag.trim().length > 0) {
				if (itemIsUnique(tags, tag)) setTags([...tags, tag.toLowerCase().trim()])
				setTag('')
			}
		}
	}

	/**
     * Adding a new tag by pressing 'add' button
     */
	const addTag = () => {
		if (tag.trim().length > 0) {
			if (itemIsUnique(tags, tag)) setTags([...tags, tag.toLowerCase().trim()])
			setTag('')
		}
	}

	/**
     * Handles the states of tag field and image upload field
     * @param {String} i - index required by FilePicker component
     * @returns callback function with event instance as a parameter
     */
	const handleChange = event => {
		event.preventDefault()
		const name = event.target.name
		if (name === 'tag') {
			const value = event.target.value
			if (hasWhiteSpace(value)) {
				if (tag.trim().length > 0 && itemIsUnique(tags, tag)) {
					setTagMessage(`${t('add_image.message.space_warning')}`)
					setShowTagMessage(true)
					setTags([...tags, tag.trim()])
				}
				setTag('')
			} else {
				setTag(value.toLowerCase())
				setShowTagMessage(false)
			}
		} else if (name === 'image') {
			setFileInput(event)
			const img = new Image() // needed for checking image dimensions
			const uploadedImage = event.target.files[0]
			const imgUrl = URL.createObjectURL(uploadedImage)
			img.src = imgUrl
			img.onload = function () {
				const width = img.width
				const height = img.height
				if (height > width) { // we can add more cases later on if needed
					setImageURL(null)
					setImage(null)
					setImageMessage(`${t('add_image.message.dimension_warning')}`)
					setShowImageMessage(true)
				} else {
					setImageURL(URL.createObjectURL(uploadedImage))
					setImage(uploadedImage)
					setShowImageMessage(false)
				}
			}
		} else if (name === 'category') {
			const value = event.target.value
			setCategory(value)
		}
	}

	/**
     * Deletes the selected tag from the tag array
     * @param {String} t - tag to be deleted
     * @returns 
     */
	const deleteTag = t => () => setTags(tags.filter(item => item !== t))

	/**
     * By clicking the image user can delete the uploaded image e.g. if wrong image was uploaded
     * UPDATE: name better, e.g. resetImageState
     */
	const deleteUploadedImage = () => {
		fileInput.target.value = null
		setImage(null)
		setImageURL(null)
	}

	/**
     * 
     * @returns uploaded image as Image component instance
     */
	const imageComponent = () => <Img src={imageURL} width='300' onClick={deleteUploadedImage} />

	return (
		<Container>
			{loading
				? <Loading />
				: <div className='w-75'>
					<h1>{t('add_image.page_title')}</h1>
					<p>{t('add_image.instructions')}</p>
					<ListGroup className='mb-3' as="ol" numbered>
						<ListGroup.Item as="li">
							<a href={t('add_image.link_1')}>
								{t('add_image.link_1')}
							</a>
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<a href={t('add_image.link_2')}>
								{t('add_image.link_2')}
							</a>
						</ListGroup.Item>
					</ListGroup>
					{showImageMessage
						? <Alert variant={imageMessageVariant}>{imageMessage}</Alert>
						: <></>
					}
					{showTagMessage
						? <Alert variant='warning'>{tagMessage}</Alert>
						: <></>
					}
					<Form onSubmit={handleSubmit} className='mb-4'>
						{imageComponent()}
						<Form.Group>
							<Form.Text>{t('add_image.image')}</Form.Text>
							<FilePicker
								handleChange={handleChange}
							/>
						</Form.Group>
						<br />
						<SelectCategory
							handleChange={handleChange}
							category={category}
						/>
						<br />
						<Form.Group>
							<Form.Text>{t('add_image.tags')}</Form.Text>
							<div className='d-flex'>
								<Form.Control
									onChange={handleChange} onKeyDown={handleKeyDown}
									name='tag' value={tag} data-cy='add-image-tag-input'/>
								<Button className='ms-2 text-nowrap' onClick={addTag} data-cy='add-image-tag-button'>
									{t('create_exercise.add')}
								</Button>
							</div>
						</Form.Group>
						<Tags
							tags={tags}
							deleteTag={deleteTag}
						/>
						<br />
						<Button
							disabled={btnDisabled}
							variant={btnVariant} size='lg' type="submit"
							data-cy='add-image-save-button'>
							{t('add_image.save')}
						</Button>
					</Form>
				</div>
			}
		</Container>
	)
}

export default AddImage
import React, { useState } from 'react'
import { Button, Form, Accordion, Image, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {useDispatch } from 'react-redux'
import { AWS_URL } from 'utils/config'
import { deleteAnswer } from 'reducers/newOrEditedExerciseSlice'
import Tags from 'components/Tags'
import CreateExerciseDesc from 'components/CreateExerciseDesc'
import SelectImageModal from 'components/SelectImageModal'
import { useAppSelector } from 'stateHandling/hooks'
import { FormEvent, ChangeEvent } from 'react'

interface Props {
	addExercise: (event: FormEvent<HTMLFormElement>) => void
	isEdit: boolean;
	handleQuestionChange: (i: string) => (event: ChangeEvent<HTMLInputElement>) => void;
	handleAnswerInputChange: (i: string) => (event: React.ChangeEvent<HTMLInputElement>) => void
	handleNewAnswerTag: (i: string) => (event: any) => void
	disabled: boolean
	variant: string
	saveMessage: string
	renderPopover: (content: string) => JSX.Element
 }

const CreateFillExercise = ({ addExercise, isEdit, handleQuestionChange, handleAnswerInputChange, handleNewAnswerTag, disabled, variant, saveMessage, renderPopover }:Props) => {
	const { t } = useTranslation()
	const [showSelectImageModal, setShowSelectImageModal] = useState(false)
	const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0)
	const questions = useAppSelector(state => state.newOrEditedExercise.questions)
	const answers = useAppSelector(state => state.newOrEditedExercise.answers)
	const hasImage = useAppSelector(state => state.newOrEditedExercise.exercise.settings.hasImage)
	const dispatch = useDispatch()

	/**
     * Deletes the selected tag from the tag array
     * @param {String} t - tag to be deleted
     * @param {Int} i - index of question tag belongs to
     */
	const deleteAnswerTag = (t, i) => () => dispatch(deleteAnswer({t, i}))

	const imageDefined = (question) => {
		if (isEdit) {
			return question.image.url !== 'undefined'
		} else {
			return question.imageURL
		}
	}

	const closeModal = () => {
		setShowSelectImageModal(false)
	}

	const showModal = (idx) => {
		setCurrentQuestionIdx(idx)
		setShowSelectImageModal(true)
	}

	const setSelectedImage = () => {
		setShowSelectImageModal(false)
	}

	const getQuestionBorderColor = i => {
		const q = questions[i]
		const hasAnswer = q.answers && q.answers.length > 0

		let qHasImage = true
		if (hasImage) {
			qHasImage = !!q.imageURL && q.imageURL !== 'undefined'
		}
		if (qHasImage && hasAnswer) return ''
		return 'red'
	}

	return (
		<>
			<Form onSubmit={addExercise} name='fill'>
				<CreateExerciseDesc
					renderPopover={renderPopover}
				/>
				<h2>{t('create_exercise.general.question_title')}</h2>
				<p>{isEdit ? t('edit_exercise.exercise_subtitle') : t('create_exercise.general.question_subtitle_fill')}</p>
				{Object.keys(questions).map((i) => {
					return (
						<div key={i}>
							<Accordion defaultActiveKey={i}>
								<Accordion.Item className='mt-3' eventKey={i} style={{borderColor: getQuestionBorderColor(i)}}>
									<Accordion.Header>
										<Form.Label className="fw-bold">{t('create_exercise.question_title', {i})}</Form.Label>
									</Accordion.Header>
									<Accordion.Body>
										<div className='d-flex'>
											<Form.Group className='mb-3' controlId={`formQuestion${i}-1`}>
												<Form.Text>{t('create_exercise.start')}</Form.Text>
												<Form.Control
													type='text'
													name='start'
													onChange={handleQuestionChange(i)}
													value={questions[i].start}
													placeholder={t('create_exercise.placeholder_start')}
													maxLength={200}/>
											</Form.Group>
											<Form.Group className='mb-3 ms-4' controlId={`formQuestion${i}-2`}>
												<Form.Text>{t('create_exercise.answers')}</Form.Text>
												<div className='d-flex'>
													<Form.Control
														type='text'
														name='answersInput'
														onChange={handleAnswerInputChange(i)}
														onKeyDown={handleNewAnswerTag(i)}
														value={answers[i]}
														placeholder={t('create_exercise.placeholder_answer')}
														maxLength={100}/>
													<Button
														data-cy={'handle_new_answer_tag_fill_exercise_' + i}
														className='ms-2 text-nowrap'
														onClick={handleNewAnswerTag(i)}
													>
														{t('create_exercise.add')}
													</Button>
												</div>
												<Tags
													tags={questions[i].answers.map(answerObj => answerObj.answer.text)}
													deleteTag={deleteAnswerTag}
													i={i}
												/>
											</Form.Group>
											<Form.Group className='mb-3 ms-4' controlId={`formQuestion${i}-3`}>
												<Form.Text>{t('create_exercise.end')}</Form.Text>
												<Form.Control
													type='text'
													name='end'
													onChange={handleQuestionChange(i)}
													value={questions[i].end}
													placeholder={t('create_exercise.placeholder_end')}
													maxLength={200}/>
											</Form.Group>
										</div>
										{showSelectImageModal && i === currentQuestionIdx.toString()
											? <SelectImageModal
												question={questions[i]}
												idx={i}
												closeModal={closeModal}
												selectImage={setSelectedImage}
											/>
											: null
										}
										{hasImage ?
											<div>
												<Button data-cy={'select_image_fill_exercise_' + i} onClick={() => showModal(i)}>{t('search_images.open_search_modal')}</Button>
											</div>
											: <></>
										}
										{hasImage && imageDefined(questions[i]) ?
											<Image
												className='images--image'
												src={AWS_URL + questions[i].imageURL}
												width='400'
											/>
											: <></>
										}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
					)}
				)}
				<br />
				{disabled ? <Alert variant='warning'>{saveMessage}</Alert> : <></> }
				<Button data-cy={'submit_exercise_btn'} disabled={disabled} variant={variant} size='lg' type="submit">
					{isEdit ? t('create_exercise.update') : t('create_exercise.save')}
				</Button>
			</Form>
		</>
	)
}

export default CreateFillExercise
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useAppSelector } from 'stateHandling/hooks'
import { useDispatch } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import {
	getExerciseList, getExerciseBaseLib, getExerciseEditList, getCommunityExercises
} from 'reducers/exerciseListSlice'
import { changeLibraryLanguage } from 'reducers/previousPageSlice'
import { getLanguageCode } from 'utils/helpers'

import { Dispatch } from 'types/Types'
import { AdminUser } from 'types/UserTypes'
import LibraryLanguageSelectConfirmModal from 'components/LibraryLanguageSelect/LibraryLanguageSelectConfirmModal'

interface Props {
    assign: boolean
}

const LibraryLanguageSelect = ({ assign }: Props ) => {
	const { i18n, t } = useTranslation()
	const [tmpLang, setTmpLang] = useState(i18n.language)
	const lang = useAppSelector(state => state.previousPage.libraryLanguage)
	const adultLibrary = useAppSelector(state => state.previousPage.adultLibrary)
	const user = useAppSelector(state => state.user)
	const [proceed, setProceed] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [showLangChangeModal, setShowLangChangeModal] = useState(false)
	const dispatch = useDispatch<Dispatch>()

	/**
	 * UseEffect to handle update parameters and get data if user confims library language change
	 */
	useEffect(() => {
		if (proceed) {
			dispatch(changeLibraryLanguage(tmpLang))
			const langCode = getLanguageCode(tmpLang)
			dispatch(getExerciseEditList({ user: user.data as AdminUser, langCode, adultLibrary }))
			dispatch(getExerciseList({user, langCode, adultLibrary }))
			dispatch(getExerciseBaseLib({user, langCode, adultLibrary}))
			dispatch(getCommunityExercises({user, langCode, adultLibrary}))
			setProceed(false)
		}
	}, [proceed, tmpLang])

	/**
	 * Function to handle changing the language of the exercise library
	 * Show confirm modal if in assign-mode and changing to a another language than what is currenlty set.
	 * @param {string} lang - the language that the user is changing to
	 */
	const handleLangChange = (newLang: string) => {
		if (assign && newLang !== lang) {
			setShowLangChangeModal(true)
			setTmpLang(newLang)
		} else {
			setProceed(true)
			setTmpLang(newLang)
		}
	}

	const handleProceed = () => {
		setShowLangChangeModal(false)
		setProceed(true)
	}

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	return (
		<>
			<Dropdown show={isOpen} onToggle={toggleDropdown}>
				<Dropdown.Toggle className='exercise-library--secondary-button' variant='outline-secondary'
					data-cy='exercise_library_language_select_button'>
					{t(`edit_exercise.language_select.language_libraries.${lang}`)}
					<KeyboardArrowDownIcon />
				</Dropdown.Toggle>
				<Dropdown.Menu data-cy='exercise_library_language_select_options'>
					<Dropdown.Item
						onClick={() => handleLangChange('sv')}
					>
						{t('edit_exercise.language_select.language_libraries.sv')}
					</Dropdown.Item>
					<Dropdown.Item
						onClick={() => handleLangChange('fi')}
					>
						{t('edit_exercise.language_select.language_libraries.fi')}
					</Dropdown.Item>
					<Dropdown.Item
						onClick={() => handleLangChange('en')}
					>
						{t('edit_exercise.language_select.language_libraries.en')}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			{showLangChangeModal
				? <LibraryLanguageSelectConfirmModal
					setShowLangChangeModal={setShowLangChangeModal}
					handleProceed={handleProceed}
				/>
				: <></>
			}
		</>
	)
}

export default LibraryLanguageSelect
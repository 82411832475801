import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'

interface Props {
    setShowModal(show: boolean): void
    saveUserSettings: () => Promise<void>
}

const ConfirmUserMessageModal = ({setShowModal, saveUserSettings }: Props) => {

	const { t } = useTranslation()

	const handleProceed = () => {
		setShowModal(false)
		saveUserSettings()
	}

	return (
		<>
			<Modal data-cy='confirm_practice_period_message' show={true}>
				<Modal.Header data-cy='confirm_practice_period_message_title'>
					<Modal.Title>{t('edit_patient.settings.confirm_message_modal.title')}</Modal.Title>
				</Modal.Header>

				<Modal.Body data-cy='confirm_practice_period_message_body'>
					<p>{t('edit_patient.settings.confirm_message_modal.info_1')}</p>
					<p><b>{t('edit_patient.settings.confirm_message_modal.info_2')}</b></p>
				</Modal.Body>

				<Modal.Footer>
					<Button className='exercise-library--secondary-button' variant='outline-secondary'
						data-cy='cancel_save_practice_period_settings'
						onClick={() => setShowModal(false)}
					>
						{t('edit_patient.settings.confirm_message_modal.no_button')}
					</Button>
					<Button data-cy='confirm_save_practice_period_settings' variant='primary'
						onClick={handleProceed}
					>
						{t('edit_patient.settings.confirm_message_modal.yes_button')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ConfirmUserMessageModal
import axios from 'axios'
import { getAuthHeader } from 'utils/authHelper'

const baseUrl = '/api/users'

axios.interceptors.request.use(async function (config) {
	const header = await getAuthHeader()
	config.headers = header
	return config
})

/**
 * POST request to register a new user
 * @returns {Object} The authenicated user
 */
const registerUser = async user => {
	const res = await axios.post(`${baseUrl}/registerUser`, user)
	return res.data
}

/**
 * POST request to register a new admin user
 * @returns {Object} The authenicated user
 */
const registerAdminUser = async user => {
	const res = await axios.post(`${baseUrl}/registerAdmin`, user)
	return res.data
}

/**
 * GET request to get user with specific MongoDB id
 * @returns {Object} The user with the matching id 
 */
const getUser = async id => {
	const res = await axios.get(`${baseUrl}/${id}`)
	return res.data
}

/**
 * GET request to get patients for admin user
 * @returns {Array} The patients for the user
 */
const getPatients = async id => {
	const res = await axios.get(`${baseUrl}/patients/${id}`)
	return res.data
}

/**
 * GET request to get all users for admin user
 * @returns {Array} with all users (slp, admin, patients)
 */
const getAllUsers = async userType => {
	const res = await axios.get(`${baseUrl}/users?type=${userType}`)
	return res.data
}

/**
 * POST request to change password of user
 * @returns {Object} The authenicated user
 */
const updateUserSettings = async data => {
	const res = await axios.post(`${baseUrl}/updateUserSettings`, data)
	return res.data
}

/**
 * DELETE request to delete user
 * @returns {Object} The authenicated user
 */
const deleteUser = async id => {
	const res = await axios.delete(`${baseUrl}/deleteUser/${id}`)
	return res.data
}

/**
 * DELETE request to delete patient user
 * @returns {boolean} True if deletion was successful, false if not
 */
const deletePatient = async data => {
	const res = await axios.delete(`${baseUrl}/deletePatient`, {data})
	return res.data
}

/**
 * POST request to add favorite exercise
 * @returns {Object} The authenicated user
 */
const saveFavoriteExercise = async data => {
	const res = await axios.post(`${baseUrl}/favoriteExercises`, data)
	return res.data
}

/**
 * GET request to the data for a user, given cognito ID
 * @returns {Object} The data for the user
 */
const getUserData = async sub => {
	const res = await axios.get(`${baseUrl}/userData/${sub}`)
	return res.data
}

/**
 * POST request to update user's points
 * @returns {Object} The authenicated user
 */
const updateUserPoints = async data => {
	const res = await axios.put(`${baseUrl}/updatePoints`, data)
	return res.data
}

export default {
	registerUser,
	registerAdminUser,
	getUser,
	getPatients,
	getAllUsers,
	updateUserSettings,
	deleteUser,
	deletePatient,
	saveFavoriteExercise,
	getUserData,
	updateUserPoints
}
import { useRef, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import Hotkeys from 'react-hot-keys'
import React from 'react'

interface Props {
	message: string
	handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleSubmit: () => void
  }

const Write = ({ message, handleInput, handleSubmit }:Props) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		textareaRef.current?.focus()
	}, [])

	return (
		<Form className='d-flex flex-row mx-auto'>
			<Form.Control ref={textareaRef} className='chatbot--chat-input' placeholder='Skriv ditt svar här...' onChange={handleInput} value={message} as='textarea' rows={3} cols={40} name='response' />
			<Hotkeys keyName='enter' filter={(event) => { return true }} onKeyDown={handleSubmit}>
				<div>
					<Button className='chatbot--chat-button' onClick={handleSubmit} size='lg'>Skicka svar</Button>
				</div>
			</Hotkeys>
		</Form>
	)
}

export default Write
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'

interface Props {
    setShowLangChangeModal(show: boolean): void
    handleProceed(proceed: boolean): void
}

const LibraryLanguageSelectConfirmModal = ({setShowLangChangeModal, handleProceed }: Props) => {

	const { t } = useTranslation()

	return (
		<>
			<Modal data-cy='confirm_change_lang_modal' show={true}>
				<Modal.Header>
					<Modal.Title>{t('edit_exercise.language_select.confirm_modal.title')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>{t('edit_exercise.language_select.confirm_modal.body')}</p>
				</Modal.Body>

				<Modal.Footer>
					<Button className='exercise-library--secondary-button' variant='outline-secondary'
						data-cy='cancel_change_lib_lang'
						onClick={() => setShowLangChangeModal(false)}
					>
						{t('edit_exercise.language_select.confirm_modal.button_cancel')}
					</Button>
					<Button data-cy='confirm_change_lib_lang' variant='primary'
						onClick={() => handleProceed(true)}
					>
						{t('edit_exercise.language_select.confirm_modal.button_proceed')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default LibraryLanguageSelectConfirmModal
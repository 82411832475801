import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useAppSelector } from 'stateHandling/hooks'
import { userIsAdminOrSlp } from 'utils/helpers'
import { logoutCognitoUser } from 'reducers/userSlice'
import { clearExerciseList } from 'reducers/exerciseListSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'

import { Dispatch } from 'types/Types'

interface Props {
    handleClose:() => void
}

const ConfirmLogoutModal = ({ handleClose }: Props) => {
	const { t } = useTranslation()
	const user = useAppSelector(state => state.user)
	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()

	const logOutOnClick = event => {
		event.preventDefault()
		const adminUser = userIsAdminOrSlp(user.data)
		dispatch(logoutCognitoUser(adminUser))
		dispatch(changePreviousPage('/'))
		dispatch(clearExerciseList())
		navigate('/login')
	}

	return (

		<>
			<Modal show={true} data-cy='logout_modal'>
				<Modal.Header closeButton onClick={handleClose}>
					<Modal.Title data-cy='logout_modal_title'>
						{t('navigation.logout_modal.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p data-cy='logout_modal_text'>{t('navigation.logout_modal.text')}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose} variant="outline-dark"
						className='exercise-library--secondary-button' data-cy='cancel_logout_button'
					>
						{t('navigation.logout_modal.cancel')}
					</Button>
					<Button onClick={logOutOnClick} variant="primary" data-cy='confirm_logout_button'>
						{t('navigation.logout')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ConfirmLogoutModal
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface previousPageState {
  path: string
  libraryTab: string
  libraryLanguage: string,
  adultLibrary: boolean,
  gridView: boolean
  cardSort: string
  patientPageTab: string
}

const initialState: previousPageState = {
	path: '/',
	libraryTab: '0',
	libraryLanguage: 'sv',
	adultLibrary: false,
	gridView: true,
	cardSort: 'newest_first',
	patientPageTab: '0'
}

export const previousPageSlice = createSlice({
	name: 'previousPage',
	initialState,
	reducers: {
		changePreviousPage: (state:previousPageState, action:PayloadAction<string>) => {
			state.path = action.payload
		},
		changeLibraryTab: (state:previousPageState, action:PayloadAction<string>) => {
			state.libraryTab = action.payload
		},
		changeLibraryLanguage: (state:previousPageState, action:PayloadAction<string>) => {
			state.libraryLanguage = action.payload
		},
		changeAdultLibrary: (state:previousPageState, action:PayloadAction<boolean>) => {
			state.adultLibrary = action.payload
		},
		changePatientPageTab: (state:previousPageState, action:PayloadAction<string>) => {
			state.patientPageTab = action.payload
		},
		changeGridView: (state:previousPageState, action:PayloadAction<boolean>) => {
			state.gridView = action.payload
		},
		changeCardSort: (state:previousPageState, action:PayloadAction<string>) => {
			state.cardSort = action.payload
		},
	},
})

export const {
	changePreviousPage,
	changeLibraryTab,
	changeLibraryLanguage,
	changeAdultLibrary,
	changePatientPageTab,
	changeGridView,
	changeCardSort
} = previousPageSlice.actions

export default previousPageSlice.reducer

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssignedPatientUser, AssignedExercise } from 'types/UserTypes'
import { DBStatistic } from 'types/StatisticTypes'
import statisticsService from 'services/statistics'
import userService from 'services/users'

const initialState: AssignedPatientUser = {
	id: '',
	cognitoId: '',
	username: '',
	type: '',
	isAdult: false,
	exercises: [],
	settings: {
		textSize: '',
		autoPlayAudio: false,
		assignedExercises: false,
		resetDate: '',
		edited: false
	},
	email: '',
	userStatus: '',
	enabled: false,
	message: '',
	statistics: []
}

export const getPatientStatistics = createAsyncThunk(
	'statistics/getStatisticsData',
	async (id: string, thunkAPI) => {
		try {
			const statistics = await statisticsService.getStatsForUser(id)
			return statistics
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get statistics for user: ${e.response.data}` as string)
		}
	}
)

export const getPatientData = createAsyncThunk(
	'patients/getPatientData',
	async ({slpId, patientId}: {slpId: string, patientId: string}, thunkAPI) => {
		try {
			const allPatientsData = await userService.getPatients(slpId)
			const patientData = allPatientsData.filter(patient => patient.id === patientId)[0]
			if (patientData) {
				return patientData
			} else
				return thunkAPI.rejectWithValue('A patient account with this id does not exist!')
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get data for patient: ${e.response.data}` as string)
		}
	}
)

export const currentPatientSlice = createSlice({
	name: 'currentPatient',
	initialState,
	reducers: {
		setCurrentPatient: (state:AssignedPatientUser, action:PayloadAction<AssignedPatientUser>) => {
			state.id = action.payload.id
			state.cognitoId = action.payload.cognitoId
			state.username = action.payload.username
			state.type = action.payload.type
			state.isAdult = action.payload.isAdult
			state.exercises = action.payload.exercises
			state.settings = action.payload.settings
			state.email = action.payload.email
			state.userStatus = action.payload.userStatus
			state.enabled = action.payload.enabled
			state.message = action.payload.message
		},
		setUseAssignedExercises: (state:AssignedPatientUser) => {
			state.settings.assignedExercises = !state.settings.assignedExercises
			state.settings.edited = true
		},
		setAutoPlayAudio: (state:AssignedPatientUser) => {
			state.settings.autoPlayAudio = !state.settings.autoPlayAudio
		},
		setTextSize: (state:AssignedPatientUser, action:PayloadAction<string>) => {
			state.settings.textSize = action.payload
		},
		setExercises: (state:AssignedPatientUser, action:PayloadAction<AssignedExercise[]>) => {
			state.exercises = action.payload
		},
		resetCurrentPatient: (state:AssignedPatientUser) => {
			state.id = initialState.id
			state.username = initialState.username
			state.type = initialState.type
			state.isAdult = initialState.isAdult
			state.exercises = initialState.exercises
			state.settings = initialState.settings
			state.statistics = []
		},
		setResetDate: (state:AssignedPatientUser, action:PayloadAction<string>) => {
			state.settings.resetDate = action.payload
			state.settings.edited = true
		},
		setMessage: (state:AssignedPatientUser, action:PayloadAction<string>) => {
			state.message = action.payload
			state.settings.edited = true
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			getPatientStatistics.fulfilled, (state:AssignedPatientUser, action:PayloadAction<DBStatistic[]>) => {
				state.statistics = action.payload
			})
		builder.addCase(getPatientStatistics.rejected, (state:AssignedPatientUser, action:PayloadAction<any>) => {
			console.error(action.payload)
		})
		builder.addCase(
			getPatientData.fulfilled, (state:AssignedPatientUser, action:PayloadAction<AssignedPatientUser>) => {
				state.id = action.payload.id
				state.cognitoId = action.payload.cognitoId
				state.username = action.payload.username
				state.type = action.payload.type
				state.isAdult = action.payload.isAdult
				state.exercises = action.payload.exercises
				state.settings = action.payload.settings
				state.email = action.payload.email
				state.userStatus = action.payload.userStatus
				state.enabled = action.payload.enabled
				state.message = action.payload.message
			})
		builder.addCase(getPatientData.rejected, (state:AssignedPatientUser, action:PayloadAction<any>) => {
			console.error(action.payload)
			if (action.payload === 'A patient account with this id does not exist!') {
				state.userStatus = 'DOES NOT EXIST'
			}
		})
	}
})

export const {
	setCurrentPatient,
	setUseAssignedExercises,
	setAutoPlayAudio,
	setTextSize,
	setExercises,
	resetCurrentPatient,
	setResetDate,
	setMessage
} = currentPatientSlice.actions

export default currentPatientSlice.reducer
import React from 'react'
import { useEffect, useState } from 'react'
import { Button, Row, Col, Form, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import { useDispatch } from 'react-redux'
import { filterIconColor } from 'utils/helpers'
import { EXERCISE_TYPES, EXERCISE_SKILLS } from 'utils/config'
import { setSearchType, setSearchSkill, resetSearch, setSearchSubtype } from 'reducers/exerciseListSlice'
import { faHeadphones, faBook, faComment, faPen, faBrain } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExerciseFilterDropdown from 'components/ExerciseFilter/ExerciseFilterDropdown'
import { Dispatch } from 'types/Types'
import PhonologyIcon from 'images/phonology.svg'
import GrammarIcon from 'images/grammar.svg'
import UnderstandingIcon from 'images/understanding.svg'
import VocabularyIcon from 'images/vocabulary.svg'

import './ExerciseFilter.css'


const initTypes = (arr, type) => {
	const obj = ['all', ...arr].map(t => {
		const typeObj = { type: t, selected: false }
		if (t === type) typeObj.selected = true
		return typeObj
	})
	return obj
}

const ExerciseLibraryFilter = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch<Dispatch>()
	const search = useAppSelector(state => state.exerciseList.search)
	const [activeSearch, setActiveSearch] = useState(false)


	const [adultTypes, setAdultTypes] = useState(initTypes(EXERCISE_TYPES, 'all'))
	const [childTypes, setChildTypes] = useState(initTypes(EXERCISE_SKILLS, 'all')) // referring to "skills" as "types" from now on

	const adultLibrary = useAppSelector(state => state.previousPage.adultLibrary)

	/**
     * Detect if there is an active search
     * Update the filters to match the search, if search cleared
     */
	useEffect(() => {
		let activeSearch = false
		const filters = [search.skill, search.type, search.subtype, search.template, search.category, search.difficulty]
		if (search.term !== '' || !filters.every(filter => filter.includes('all'))) {
			activeSearch = true
		}
		setActiveSearch(activeSearch)
		adultTypes.forEach(type => {
			if (search.type.includes(type.type)) {
				type.selected = true
			} else type.selected = false
		})
		childTypes.forEach(skill => {
			if (search.skill.includes(skill.type)) {
				skill.selected = true
			} else skill.selected = false
		})
	}, [search])

	/**
     * Event handler for checking a exercise type
     * @param {Event} event - event intance for onKeyDown event
     */
	const onTypeCheck = (event) => {
		const selectedType = event.target.value
		let tempTypes = [] as { type: string; selected: boolean; }[]
		if (selectedType === 'all') {
			tempTypes = ['all', ...EXERCISE_TYPES].map(t => {
				const typeObj = { type: t, selected: false }
				if (t === 'all') typeObj.selected = true
				return typeObj
			})
		} else {
			tempTypes = adultTypes.map(item => {
				if (item.type === selectedType) {
					item.selected = event.target.checked
				}
				return item
			})
			if (tempTypes.filter(item => item.selected).length > 0 ) tempTypes[0].selected = false
			if (tempTypes.filter(item => item.selected).length >= 4) tempTypes[0].selected = true
			if (tempTypes.filter(item => item.selected).length === 0) tempTypes[0].selected = true
		}
		setAdultTypes(tempTypes)
		const selectedTypes = tempTypes.filter(item => item.selected).map(item => item.type)
		dispatch(setSearchType(selectedTypes))
	}

	const onSkillChange = (selectedType) => {
		if (selectedType === 'pragmatics') return // remove when we activate pragmatics skill
		const types = initTypes(EXERCISE_SKILLS, selectedType)
		setChildTypes(types)
		dispatch(setSearchSkill(selectedType))
		dispatch(setSearchSubtype(['all'])) // Clear any selected subtypes, as those are different between skills
	}

	/**
     * Get the correct icon, given the type
     * @param {string} type - event intance for onKeyDown event
     */
	const getTypeIcon = (type: string, isAdult: boolean) => {
		if (isAdult) {
			if (type === 'write')
				return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['write']}} icon={faPen} />
			if (type === 'speak')
				return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['speak']}} icon={faComment} />
			if (type === 'read')
				return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['read']}} icon={faBook} />
			if (type === 'listen')
				return <FontAwesomeIcon className='px-1' style={{color: filterIconColor['listen']}} icon={faHeadphones} />
			else return <></>
		} else {
			if (type === 'phonology')
				return <Image src={PhonologyIcon} className='px-1' />
			if (type === 'grammar')
				return <Image src={GrammarIcon} className='px-1' />
			if (type === 'understanding')
				return <Image src={UnderstandingIcon} className='px-1' />
			if (type === 'vocabulary')
				return <Image src={VocabularyIcon} className='px-1' />
			if (type === 'pragmatics')
				return <FontAwesomeIcon className='px-1' style={{color: '#808080'}} icon={faBrain} />
			else return <></>
		}
	}

	const renderAdultFilters = () => {
		return (
			<>
				<Row xs="auto">
					{adultTypes.map((item) => {
						return (
							<Col key={item.type} className={item.type === 'listen' ? 'flex-grow-1' : ''}>
								<div className={
									item.selected
										? 'exercise-filter--select exercise-filter--type-select-selected my-2'
										: 'exercise-filter--select my-2'
								}
								data-cy={`type_filter_${item.type}`}
								>
									<Form.Check
										key={item.type}
										onChange={(event) => onTypeCheck(event)}
										checked={item.selected}
										type='checkbox'
										value={item.type}
										data-cy={`type_filter_${item.type}_select`}
										label={
											<span className='text-nowrap'>
												{getTypeIcon(item.type, true)}
												{t(`create_exercise.settings.types.${item.type}`)}
											</span>
										}
									/>
								</div>
							</Col>
						)})
					}
					{ activeSearch ?
						<Col className='float-end text-end'>
							<Button variant='link' onClick={() => dispatch(resetSearch())}
								className='exercise-library--filter-button'
								data-cy='exercise_filter_clear_button'>
								{t('search_exercises.search_reset')}
							</Button>
						</Col>
						: <></>
					}
					<Col className='float-end text-end'>
						<ExerciseFilterDropdown/>
					</Col>
				</Row>
			</>
		)
	}

	const renderChildFilters = () => {
		return (
			<>
				<Row xs="auto">
					{childTypes.map((item) => {
						return (
							<Col key={item.type} className={item.type === 'listen' ? 'flex-grow-1' : ''} style={item.type === 'pragmatics' ? { opacity: '0.5' } : {}}>
								<div
									className={
										item.selected
											? 'exercise-filter--select exercise-filter--type-select-selected my-2'
											: item.type === 'pragmatics' ? 'exercise-filter--select my-2' : 'exercise-filter--select exercise-filter--type-select-pointer my-2' // update when we have pragmatics as skill
									}
									data-cy={`type_filter_${item.type}`}
									onClick={() => onSkillChange(item.type)}
								>
									<span className='text-nowrap'>
										{getTypeIcon(item.type, false)}
										{t(`create_exercise.settings.skills.${item.type}`)}
									</span>
								</div>
							</Col>
						)})
					}
					{ activeSearch ?
						<Col className='float-end text-end'>
							<Button variant='link' onClick={() => dispatch(resetSearch())}
								className='exercise-library--filter-button'
								data-cy='exercise_filter_clear_button'>
								{t('search_exercises.search_reset')}
							</Button>
						</Col>
						: <></>
					}
					<Col className='float-end text-end'>
						<ExerciseFilterDropdown/>
					</Col>
				</Row>
			</>
		)
	}

	return adultLibrary ? renderAdultFilters() : renderChildFilters()
}

export default ExerciseLibraryFilter
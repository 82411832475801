import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { getConfidenceBgColor, getConfidenceColor } from 'utils/helpers'

const PlayAnswer = () => {
    const { t } = useTranslation()
    const audioUrl = useSelector(state => state.currentExercise.recordedAudio)
    const confidence = useSelector(state => state.currentExercise.stats.confidence)
    const recordedAudio = new Audio(audioUrl)
    
    return (
        <div className='exercise--feedback-writtenAnswer p-2 text-start'
            style={{backgroundColor: getConfidenceBgColor(confidence), borderColor: getConfidenceColor(confidence)}}
        >
            <Button className='exercise--header-button' variant='outline-primary' onClick={() => recordedAudio.play()}>
                <FontAwesomeIcon icon={faVolumeUp} className='audio--play-icon' />
                <span className='exercise--audio-button-text mx-2'>
                    {t('exercise.play_answer.title')}
                </span>
            </Button>
        </div>
    )
}

export default PlayAnswer
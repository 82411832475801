import './Thinking.css'
import React from 'react'

const Thinking = () => {
	return (
		<div className="typing mx-auto">
			<div className="typing__dot"></div>
			<div className="typing__dot"></div>
			<div className="typing__dot"></div>
		</div>
	)
}

export default Thinking
import axios from 'axios'
import { CHATBOT_BASEURL } from 'utils/config'

const baseUrl = CHATBOT_BASEURL || 'http://localhost:5001'

/**
 * Fetches the scenario list from the server
 * Matching end-point: '/'
 * @returns array of scenarios
 */
export const getScenarios = async () => {
	const res = await axios.get(baseUrl)
	return res.data
}

/**
 * Fetches the first turn of the conversation based on which scenario the user selected
 * Matching end-point: '/scenario'
 * @param {String} id - name of the selected scenario
 * @returns 
 */
export const setScenario = async (id) => {
	const res = await axios.post(`${baseUrl}/scenario`, { id }, { withCredentials: true })
	return res.data
}

/**
 * Matching endpoint: '/create
 * @param {String} description - description of the scenario
 */
export const createScenario = async (description) => {
	const res = await axios.post(`${baseUrl}/create`, { description })
	return res.data
}

/**
 * Fetches the chatbot output (text and audio) based on the user input
 * Matching end-point: '/message'
 * @param {String} message - user input
 * @returns 
 */
export const getResponse = async (message) => {
	const res = await axios.post(`${baseUrl}/message`, { message }, { withCredentials: true })
	return res.data
}

/**
 * Sends audio recording to backend and returns chatbot output (text and audio)
 * Matching end-point: '/audio'
 * @param {*} files - object { audioBits, blob{}, encoding, type }
 * @returns 
 */
export const sendAudio = async (files) => {
	const res = await axios.post(`${baseUrl}/audio`, files, { withCredentials: true })
	return res.data
}
import { EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY } from 'utils/config'

export const getLanguageCode = code => {
	if (code === 'sv' || code === 'sv-SE') {
		return 'sv-SE'
	} else if (code === 'fi' || code === 'fi-FI') {
		return 'fi-FI'
	} else {
		return 'en-US'
	}
}

export const getUnderscoreWord = (len, fill=' _') => {
	return new Array(len + 1).join(fill)
}

// Currently not needed, as we get this from the DB - comment out/remove if certain it's not needed
export const getFillQuestion = (questionData) => {
	return [
		questionData.start === 'undefined' ? '' : `${questionData.start} `,
		`${getUnderscoreWord(questionData.answers[0].length, '_')} `,
		questionData.end === 'undefined' ? '' : questionData.end
	]
}

export const generateHint = answer => {
	if (answer.length === 0) {
		return ''
	} else if (answer.length === 1) {
		return '_'
	} else if (answer.length === 2) {
		return answer.charAt(0) + ' _'
	} else if (answer.length === 3) {
		return answer.substring(0, 2) + ' _'
	} else {
		const len = answer.length - 3
		return answer.substring(0, 3) + getUnderscoreWord(len)
	}
}

/**
 * Check if string contains whitespace
 * @param {String} str - Text to be tested
 * @returns {Boolean} True if input contains white space character(s), False otherwise
*/
export const hasWhiteSpace = str => {
	return /\s/g.test(str)
}

/**
  * Checks whether current tag already exists in the tags array or not
  * @param {Array} arr - current tag list
  * @param {String} t - new tag
  * @returns {Boolean} true if tag is not included in the tags array, false otherwise
*/
export const itemIsUnique = (arr, str) => arr.indexOf(str) === -1

/**
  * Check if item is valid and should be added to the item list
  * Criterion: 
  * 1. Item is not empty string
  * 2. Item is not yet included in the item list
  * @param {Array} arr - current list of items
  * @param {String} t - new item
  * @returns {Boolean} true if item passes every criterion, false otherwise
*/
export const itemIsValid = (arr, str) => {
	if (str.length > 0) {
		if (itemIsUnique(arr, str)) {
			return true
		}
	}
	return false
}

/**
 * 
 * @param {String} str - input string
 * @returns {String} input string trimmed and in lower case
 */
export const parseString = str => str.toLowerCase().trim().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')

/**
  * Delete item from the item list
  * @param {Array} arr - current list of items
  * @param {String} t - new item
  * @returns {Array} item list after removing item
*/
export const deleteItem = (arr, str) => {
	return arr.filter(item => item.answer.text !== str)
}

/**
  * Allows creating an arbitrary length pause in a process
  * @param {Number} m - milliseconds we want to wait
  * @returns a promise that takes m milliseconds to fulfill
  */
export const sleep = m => new Promise(r => setTimeout(r, m))

/**
 * Checks if user's answer matches any of the accepted answers
 * @param {String} answer - answer given by user
 * @param {Array} correctAnswers - list of acceptable answers
 * @returns {Boolean} if user's answer matches acceptable answers return true, otherwise false
 */
export const checkWrittenAnswer = (answer, correctAnswers) => {
	const formattedAnswer = answer.toLowerCase().trim()
	return correctAnswers.includes(formattedAnswer) || correctAnswers.some(c => formattedAnswer.includes(c))
}

/**
 * Genereates an object with multiple objects containing keys for the default exercise questions and answers
 * This object is used as the initial state for the newExercise hook, which is used to manage the
 * content of the controlled form for creating an exercise
 * @returns {Object} Object with nested objects with keys 'question' and 'answers'
 */
export const generateQuestionObj = (n = 25, type = 'obj') => {
	const questionObj = {}
	for (let i = 1; i <= n; i++) {
		if (type === 'obj') {
			questionObj[i] = {
				question: { text: '' },
				answers: [],
				edited: true
			}
		} else if (type === 'str') {
			questionObj[i] = ''
		}
	}
	return questionObj
}

/**
 * Genereates an object with multiple objects containing keys for the fill exercise questions and answers
 * This object is used as the initial state for the newExercise hook, which is used to manage the
 * content of the controlled form for creating an exercise
 * @returns {Object} Object with nested objects with keys 'start', 'answers' and 'end'
 */
export const generateFillQuestionObj = (n = 25) => {
	const questionObj = {}
	for (let i = 1; i <= n; i++) {
		questionObj[i] = {
			start: '',
			end: '',
			answers: [],
			edited: true
		}
	}
	return questionObj
}

// Like generateQuestionObj but populates form with test data
export const populateQuestionObj = n => {
	const questionObj = {}
	for (let i = 1; i <= n; i++) {
		questionObj[i] = { question: `populated question ${i}`, answers: `populated answers ${i}` }
	}
	return questionObj
}

// Like generateActionQuestionObj but populates form with test data
export const populateActionQuestionObj = n => {
	const questionObj = {}
	for (let i = 1; i <= n; i++) {
		questionObj[i] = { start: 'A boy', answer: 'throws', end: 'a ball' }
	}
	return questionObj
}

/**
 * Checks if logged in user is admin
 * @param {Object} user - user object
 * @returns {Boolean} True if user type is admin, false otherwise
 */
export const userIsAdmin = (user) => {
	return user.type === 'admin'
}
/**
 * Checks if logged in user is slp
 * @param {Object} user - user object
 * @returns {Boolean} True if user type is admin, false otherwise
 */
export const userIsSlp = (user) => {
	return user.type === 'slp'
}

/**
 * Checks if logged in user is admin or SLP
 * @param {Object} user - user object
 * @returns {Boolean} True if user type is admin or slp, false otherwise
 */
export const userIsAdminOrSlp = (user) => {
	return user.type === 'admin' || user.type === 'slp'
}

/**
 * Checks if logged in user has assignedExercises setting on
 * @param {Object} user - user object
 * @returns {Boolean} True if user and has assingnedExercises setting on
 */
export const userWithAssignedExercises = (user) => {
	return user.type === 'user' && user.settings.assignedExercises
}

const phonologyFilter = (e, search) => {
	const top = search.subtype.filter(s => EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY[0].includes(s))
	const middle = search.subtype.filter(s => EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY[1].includes(s))
	const bottom = search.subtype.filter(s => EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY[2].includes(s))
	return (top.some(selectedSubtype => e.subtype.includes(selectedSubtype)) || top.length === 0)
	&& (middle.some(selectedSubtype => e.subtype.includes(selectedSubtype)) || middle.length === 0)
	&& (bottom.some(selectedSubtype => e.subtype.includes(selectedSubtype)) || bottom.length === 0)
}

/**
 * Fitlers and sorts the input array
 * @param {Array} exs - array of exercises
 * @returns {Array}
 */
export const filterExercises = (exs, search, adultLibrary, sort='alphabetical') => {
	let filteredExercises, sortedExercises
	if (!adultLibrary) {
		filteredExercises = exs.filter(e =>
			e.title.text.toLowerCase().includes(search.term.toLowerCase())
				&& ('skill' in e ? (e.skill.includes(search.skill) || search.skill === 'all') : true)
				&& (search.skill === 'phonology'
					? (phonologyFilter(e, search) || search.subtype.includes('all'))
					: (search.subtype.some(selectedSubtype => e.subtype.includes(selectedSubtype))
							|| search.subtype.includes('all'))
				)
				&& (search.category.includes(e.category) || search.category.includes('all'))
				&& (search.difficulty.includes(e.difficulty.toString()) || search.difficulty.includes('all'))
		)
	} else {
		filteredExercises = exs.filter(e =>
			e.title.text.toLowerCase().includes(search.term.toLowerCase())
				/* Remove searching free text for tags and description
				|| e.tags.some(t => t.includes(search.term.toLowerCase()))
				|| e.description.slp.text.toLowerCase().includes(search.term.toLowerCase())) */
				&& (search.type.some(selectedType => e.type.includes(selectedType)) || search.type.includes('all'))
				&& (search.subtype.some(selectedSubtype => e.subtype.includes(selectedSubtype))
					|| search.subtype.includes('all')
				)
				&& (search.template.includes(e.template) || search.template.includes('all'))
				&& (search.category.includes(e.category) || search.category.includes('all'))
				&& (search.difficulty.includes(e.difficulty.toString()) || search.difficulty.includes('all'))
		)
	}

	sortedExercises = [...filteredExercises]

	if (sort === 'newest_first') {
		return sortedExercises.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1)
	} else if (sort === 'oldest_first') {
		return sortedExercises.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1)
	} else if (sort === 'alphabetical') {
		return sortedExercises.sort((a, b) => a.title.text.toUpperCase().localeCompare(b.title.text.toUpperCase(), 'sv'))
	} else if (sort === 'alphabetical_reverse') {
		return sortedExercises.sort((a, b) => b.title.text.toUpperCase().localeCompare(a.title.text.toUpperCase(), 'sv'))
	} else {
		return sortedExercises
	}
}

/**
 * Returns the answer format of the exercise
 * @returns { string } answerFormat -- returns the answerFormat of the exercise
 */
export const getAnswerFormat = (exercise, renderType) => {
	if (exercise.template === 'comprehension') {
		return exercise.answerFormat
	} else {
		return renderType
	}
}

/**
 * Returns formatted audio data for an exercise
 * @param {string} id - exercise id
 * @param {Array} audioData - audio data objects for an exercise
 */
export const parseExerciseAudio = (id, audioData) => {
	const title = {audio: audioData[0], slowAudio: audioData[1]}
	const slpDescription = {audio: audioData[2], slowAudio: audioData[3]}
	const patientDescription = {audio: audioData[4], slowAudio: audioData[5]}
	const audio = { title: title, description: { slp: slpDescription, patient: patientDescription }}
	const exerciseAudioObject = {id, audio}
	return exerciseAudioObject
}

/**
 * Returns formatted object for requesting audio data for an exercise
 * @param {DBExercise} exercise - exercise object
 * @param {Object} audioReqObject - Sting array of audio Ids to be requested
 */
export const getExerciseAudioReqObject = (exercise) => {
	const audioReqObject = {}
	audioReqObject[exercise.id] = [
		exercise.title.audio,
		exercise.title.slowAudio,
		exercise.description.slp.audio,
		exercise.description.slp.slowAudio,
		exercise.description.patient.audio,
		exercise.description.patient.slowAudio
	]
	return audioReqObject
}

/**
 * Determines css- class for text- alternatives given length of string
 * @param {String} alternative - string representing an alternative
 * @returns {Stirng} - css class based on length of input string
 */
export const getAlternativeLengthClass = (alternative) => {
	if (alternative.length > 50) {
		return 'xlong-length'
	} if (alternative.length > 30) {
		return 'long-length'
	} if (alternative.length > 15) {
		return 'medium-length'
	} else return 'short-length'
}

/**
 * Determines css- class for questions given length of string
 * @param {String} question - string representing a question
 * @returns {Stirng} - css class based on length of input string
 */
export const getQuestionLengthClass = (alternative) => {
	if (alternative.length > 200) {
		return 'xlong-length'
	} if (alternative.length > 100) {
		return 'long-length'
	} if (alternative.length > 30) {
		return 'medium-length'
	} else return 'short-length'
}


// Icon colors for the exercise icons on the cards
export const filterIconColor = {
	speak: '#E765B1',
	write: '#0CA766',
	read: '#17A3AB',
	listen: '#7F56D9'
}

// Icon colors for the skills
export const skillIconColor = {
	phonology: '#E765B1',
	grammar: '#7F56D9',
	understanding: '#c96ede',
	vocabulary: '#17A3AB',
	pragmatics: '#d1d1d1'
}



// Given the render type, return the correct exercise select attribute
export const getExerciseSelectAttribute = {
	speak: 'useSpeaking',
	write: 'useWriting',
	read: 'useReading',
	listen: 'useListening'
}

/**
 * Determines color for the patient user profile icon
 * @param {String} username 
 * @returns {Stirng} - Color for the user profile icon
 */
export const getPatientUserIconColor = (username) => {
	if (username !== undefined){
		if (username.charAt(0).toUpperCase() > 'E') {
			return '#00838F'
		} if (username.charAt(0).toUpperCase() > 'J') {
			return '#B537D2'
		} if (username.charAt(0).toUpperCase() > 'O') {
			return '#097B4C'
		} if (username.charAt(0).toUpperCase() > 'T') {
			return '#53389E'
		} if (username.charAt(0).toUpperCase() > 'Ö') {
			return '#0072C3'
		} else return '#0F5132'
	}
}

// Icon colors for the exercise icons on the cards
export const tagColor = {
	vocabulary: {
		color: '#0D6D72',
		bgColor: 'rgba(23, 163, 171, 0.2)',
		borderColor: '#17A3AB',
		selected: {
			color: '#ffffff',
			bgColor: '#00838F',
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(23, 163, 171, 0.2)',
			borderColor: '#17A3AB'
		}
	},
	repetition: {
		color: '#7D418B',
		bgColor: 'rgba(226, 100, 255, 0.2)',
		borderColor: '#C96EDE',
		selected: {
			color: '#ffffff',
			bgColor: '#B537D2'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(226, 100, 255, 0.2)',
			borderColor: '#C96EDE'
		}
	},
	automatic: {
		color: '#035935',
		bgColor: 'rgba(12, 167, 102, 0.2)',
		borderColor: '#0CA766',
		selected: {
			color: '#ffffff',
			bgColor: '#097B4C'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(12, 167, 102, 0.2)',
			borderColor: '#0CA766'
		}
	},
	grammar: {
		color: '#31157C',
		bgColor: 'rgba(83, 56, 158, 0.2)',
		borderColor: '#53389E',
		selected: {
			color: '#ffffff',
			bgColor: '#53389E'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(83, 56, 158, 0.2)',
			borderColor: '#31157C'
		}
	},
	other: {
		color: '#723CE7',
		bgColor: 'rgba(114, 60, 231, 0.1)',
		borderColor: '#723CE7',
		selected: {
			color: '#ffffff',
			bgColor: '#723CE7'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(114, 60, 231, 0.1)',
			borderColor: '#723CE7'
		}
	},
	verbs: {
		color: '#1131B0',
		bgColor: 'rgba(88, 113, 217, 0.2)',
		borderColor: '#5871D9',
		selected: {
			color: '#ffffff',
			bgColor: '#2D4CC8'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(88, 113, 217, 0.2)',
			borderColor: '#5871D9'
		}
	},
	nouns: {
		color: '#975607',
		bgColor: 'rgba(225, 194, 85, 0.2)',
		borderColor: '#E78A1D',
		selected: {
			color: '#ffffff',
			bgColor: '#AF6612'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(225, 194, 85, 0.2)',
			borderColor: '#E78A1D'
		}
	},
	matching: {
		color: '#B82E00',
		bgColor: 'rgba(222, 85, 42, 0.2)',
		borderColor: '#DE552A',
		selected: {
			color: '#ffffff',
			bgColor: '#C7461F'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(222, 85, 42, 0.2)',
			borderColor: '#DE552A'
		}
	},
	semantics: {
		color: '#3F5C00',
		bgColor: 'rgba(115, 167, 4, 0.1)',
		borderColor: '#73A704',
		selected: {
			color: '#ffffff',
			bgColor: '#598104'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(115, 167, 4, 0.1)',
			borderColor: '#73A704'
		}
	},
	control: {
		color: '#323B3E',
		bgColor: 'rgba(108, 117, 125, 0.1)',
		borderColor: '#6C757D',
		selected: {
			color: '#ffffff',
			bgColor: '#6C757D'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(108, 117, 125, 0.1)',
			borderColor: '#6C757D'
		}
	},
	categorization: {
		color: '#AD0028',
		bgColor: 'rgba(236, 78, 113, 0.2)',
		borderColor: '#EC4E71',
		selected: {
			color: '#ffffff',
			bgColor: '#D32C33'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(236, 78, 113, 0.2)',
			borderColor: '#EC4E71'
		}
	},
	puzzles: {
		color: '#005270',
		bgColor: 'rgba(0, 143, 188, 0.2)',
		borderColor: '#008FBC',
		selected: {
			color: '#ffffff',
			bgColor: '#0072C3'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(0, 143, 188, 0.2)',
			borderColor: '#008FBC'
		}
	},
	food_drink: {
		color: '#B125A3',
		bgColor: 'rgba(195, 42, 180, 0.15)',
		borderColor: '#B125A3',
		selected: {
			color: '#ffffff',
			bgColor: '#B125A3'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(195, 42, 180, 0.15)',
			borderColor: '#B125A3'
		}
	},
	nature: {
		color: '#0F5132',
		bgColor: 'rgba(15, 81, 50, 0.1)',
		borderColor: '#0F5132',
		selected: {
			color: '#ffffff',
			bgColor: '#0F5132'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(15, 81, 50, 0.1)',
			borderColor: '#0F5132'
		}
	},
	entertainment: {
		color: '#B41873',
		bgColor: 'rgba(231, 101, 177, 0.2)',
		borderColor: '#E765B1',
		selected: {
			color: '#ffffff',
			bgColor: '#D52089'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(231, 101, 177, 0.2)',
			borderColor: '#E765B1'
		}
	},
	phonological_awareness: {
		color: '#975607',
		bgColor: 'rgba(225, 194, 85, 0.2)',
		borderColor: '#E78A1D',
		selected: {
			color: '#ffffff',
			bgColor: '#AF6612'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(225, 194, 85, 0.2)',
			borderColor: '#E78A1D'
		}
	},
	language_awareness: {
		color: '#1131B0',
		bgColor: 'rgba(88, 113, 217, 0.2)',
		borderColor: '#5871D9',
		selected: {
			color: '#ffffff',
			bgColor: '#2D4CC8'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(88, 113, 217, 0.2)',
			borderColor: '#5871D9'
		}
	},
	f_sound: {
		color: '#0D6D72',
		bgColor: 'rgba(23, 163, 171, 0.2)',
		borderColor: '#17A3AB',
		selected: {
			color: '#ffffff',
			bgColor: '#00838F',
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(23, 163, 171, 0.2)',
			borderColor: '#17A3AB'
		}
	},
	g_sound: {
		color: '#7D418B',
		bgColor: 'rgba(226, 100, 255, 0.2)',
		borderColor: '#C96EDE',
		selected: {
			color: '#ffffff',
			bgColor: '#B537D2'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(226, 100, 255, 0.2)',
			borderColor: '#C96EDE'
		}
	},
	k_sound: {
		color: '#723CE7',
		bgColor: 'rgba(114, 60, 231, 0.1)',
		borderColor: '#723CE7',
		selected: {
			color: '#ffffff',
			bgColor: '#723CE7'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(114, 60, 231, 0.1)',
			borderColor: '#723CE7'
		}
	},
	r_sound: {
		color: '#0F5132',
		bgColor: 'rgba(15, 81, 50, 0.1)',
		borderColor: '#0F5132',
		selected: {
			color: '#ffffff',
			bgColor: '#0F5132'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(15, 81, 50, 0.1)',
			borderColor: '#0F5132'
		}
	},
	s_sound: {
		color: '#31157C',
		bgColor: 'rgba(83, 56, 158, 0.2)',
		borderColor: '#53389E',
		selected: {
			color: '#ffffff',
			bgColor: '#53389E'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(83, 56, 158, 0.2)',
			borderColor: '#31157C'
		}
	},
	beginning: {
		color: '#035935',
		bgColor: 'rgba(12, 167, 102, 0.2)',
		borderColor: '#0CA766',
		selected: {
			color: '#ffffff',
			bgColor: '#097B4C'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(12, 167, 102, 0.2)',
			borderColor: '#0CA766'
		}
	},
	middle: {
		color: '#AD0028',
		bgColor: 'rgba(236, 78, 113, 0.2)',
		borderColor: '#EC4E71',
		selected: {
			color: '#ffffff',
			bgColor: '#D32C33'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(236, 78, 113, 0.2)',
			borderColor: '#EC4E71'
		}
	},
	end: {
		color: '#323B3E',
		bgColor: 'rgba(108, 117, 125, 0.1)',
		borderColor: '#6C757D',
		selected: {
			color: '#ffffff',
			bgColor: '#6C757D'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(108, 117, 125, 0.1)',
			borderColor: '#6C757D'
		}
	},
	combinations: {
		color: '#B125A3',
		bgColor: 'rgba(195, 42, 180, 0.15)',
		borderColor: '#B125A3',
		selected: {
			color: '#ffffff',
			bgColor: '#B125A3'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(195, 42, 180, 0.15)',
			borderColor: '#B125A3'
		}
	},
	genitive: {
		color: '#3F5C00',
		bgColor: 'rgba(115, 167, 4, 0.1)',
		borderColor: '#73A704',
		selected: {
			color: '#ffffff',
			bgColor: '#598104'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(115, 167, 4, 0.1)',
			borderColor: '#73A704'
		}
	},
	plural: {
		color: '#0D6D72',
		bgColor: 'rgba(23, 163, 171, 0.2)',
		borderColor: '#17A3AB',
		selected: {
			color: '#ffffff',
			bgColor: '#00838F',
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(23, 163, 171, 0.2)',
			borderColor: '#17A3AB'
		}
	},
	comparative: {
		color: '#B82E00',
		bgColor: 'rgba(222, 85, 42, 0.2)',
		borderColor: '#DE552A',
		selected: {
			color: '#ffffff',
			bgColor: '#C7461F'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(222, 85, 42, 0.2)',
			borderColor: '#DE552A'
		}
	},
	syntax: {
		color: '#035935',
		bgColor: 'rgba(12, 167, 102, 0.2)',
		borderColor: '#0CA766',
		selected: {
			color: '#ffffff',
			bgColor: '#097B4C'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(12, 167, 102, 0.2)',
			borderColor: '#0CA766'
		}
	},
	negations: {
		color: '#AD0028',
		bgColor: 'rgba(236, 78, 113, 0.2)',
		borderColor: '#EC4E71',
		selected: {
			color: '#ffffff',
			bgColor: '#D32C33'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(236, 78, 113, 0.2)',
			borderColor: '#EC4E71'
		}
	},
	prepositions: {
		color: '#323B3E',
		bgColor: 'rgba(108, 117, 125, 0.1)',
		borderColor: '#6C757D',
		selected: {
			color: '#ffffff',
			bgColor: '#6C757D'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(108, 117, 125, 0.1)',
			borderColor: '#6C757D'
		}
	},
	pronouns: {
		color: '#975607',
		bgColor: 'rgba(225, 194, 85, 0.2)',
		borderColor: '#E78A1D',
		selected: {
			color: '#ffffff',
			bgColor: '#AF6612'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(225, 194, 85, 0.2)',
			borderColor: '#E78A1D'
		}
	},
	tempus: {
		color: '#723CE7',
		bgColor: 'rgba(114, 60, 231, 0.1)',
		borderColor: '#723CE7',
		selected: {
			color: '#ffffff',
			bgColor: '#723CE7'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(114, 60, 231, 0.1)',
			borderColor: '#723CE7'
		}
	},
	categories: {
		color: '#1131B0',
		bgColor: 'rgba(88, 113, 217, 0.2)',
		borderColor: '#5871D9',
		selected: {
			color: '#ffffff',
			bgColor: '#2D4CC8'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(88, 113, 217, 0.2)',
			borderColor: '#5871D9'
		}
	},
	emotions: {
		color: '#B125A3',
		bgColor: 'rgba(195, 42, 180, 0.15)',
		borderColor: '#B125A3',
		selected: {
			color: '#ffffff',
			bgColor: '#B125A3'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(195, 42, 180, 0.15)',
			borderColor: '#B125A3'
		}
	},
	questions: {
		color: '#005270',
		bgColor: 'rgba(0, 143, 188, 0.2)',
		borderColor: '#008FBC',
		selected: {
			color: '#ffffff',
			bgColor: '#0072C3'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(0, 143, 188, 0.2)',
			borderColor: '#008FBC'
		}
	},
	antonyms: {
		color: '#B82E00',
		bgColor: 'rgba(222, 85, 42, 0.2)',
		borderColor: '#DE552A',
		selected: {
			color: '#ffffff',
			bgColor: '#C7461F'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(222, 85, 42, 0.2)',
			borderColor: '#DE552A'
		}
	},
	synonyms: {
		color: '#035935',
		bgColor: 'rgba(12, 167, 102, 0.2)',
		borderColor: '#0CA766',
		selected: {
			color: '#ffffff',
			bgColor: '#097B4C'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(12, 167, 102, 0.2)',
			borderColor: '#0CA766'
		}
	},
	turn_taking: {
		color: '#005270',
		bgColor: 'rgba(0, 143, 188, 0.2)',
		borderColor: '#008FBC',
		selected: {
			color: '#ffffff',
			bgColor: '#0072C3'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(0, 143, 188, 0.2)',
			borderColor: '#008FBC'
		}
	},
	storytelling: {
		color: '#B125A3',
		bgColor: 'rgba(195, 42, 180, 0.15)',
		borderColor: '#B125A3',
		selected: {
			color: '#ffffff',
			bgColor: '#B125A3'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(195, 42, 180, 0.15)',
			borderColor: '#B125A3'
		}
	},
	interaction: {
		color: '#723CE7',
		bgColor: 'rgba(114, 60, 231, 0.1)',
		borderColor: '#723CE7',
		selected: {
			color: '#ffffff',
			bgColor: '#723CE7'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(114, 60, 231, 0.1)',
			borderColor: '#723CE7'
		}
	},
	social_problem_solving: {
		color: '#31157C',
		bgColor: 'rgba(83, 56, 158, 0.2)',
		borderColor: '#53389E',
		selected: {
			color: '#ffffff',
			bgColor: '#53389E'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(83, 56, 158, 0.2)',
			borderColor: '#31157C'
		}
	},
	default: { // Fallback if tag does not exist
		color: '#723CE7',
		bgColor: 'rgba(114, 60, 231, 0.1)',
		borderColor: '#723CE7',
		selected: {
			color: '#ffffff',
			bgColor: '#723CE7'
		},
		hover: {
			color: '#212529',
			bgColor: 'rgba(114, 60, 231, 0.1)',
			borderColor: '#723CE7'
		}
	},
}

/**
 * Determines color given a confidence score
 * @param {number} confidence - number 0 -> 1 
 * @returns {String} - color code
 */
export const getConfidenceColor = (confidence) => {
	if (confidence > 0.9) {
		return confidenceColors.green
	} else if (confidence > 0.75) {
		return confidenceColors.orange
	} else if (confidence == 0) {
		return confidenceColors.red
	} else {
		return confidenceColors.yellow
	}
}

export const confidenceColors = {
	green: '#12B76A',
	orange: '#F79009',
	yellow: '#FEC84B',
	red: '#F04438',
	background: '#F5F1FD'
}

/**
 * Determines background color given a confidence score
 * @param {number} confidence - number 0 -> 1 
 * @returns {String} - color code
 */
export const getConfidenceBgColor = (confidence) => {
	if (confidence > 0.9) {
		return confidenceBgColors.green
	} else if (confidence > 0.75) {
		return confidenceBgColors.orange
	} else if (confidence == 0) {
		return confidenceBgColors.red
	} else {
		return confidenceBgColors.yellow
	}
}

export const confidenceBgColors = {
	green: '#D1E7DD',
	orange: '#FFF3CD', // TODO: update to orange shade? this is yellow
	yellow: '#FFF3CD',
	red: '#F8D7DA',
}